import { useState } from 'react'
import { StyleSheet, Text, View, TextInput, Pressable, Platform } from 'react-native';
import {
  HexGrid,
  HexLayout,
  Tile, HexText,
  HexPattern,
  Hex,
  HexUtils} from '../HexGrid';
import Board from '../models/Board';
import { playSound } from '../utils/soundUtils.js';

const Button = (props) => {
  // Props
  // - title
  // - titleColor
  // - color
  // - onPress
  const [active, setActive] = useState(false);

  let titleEl = null;
  if (props.title) {
    let finalTitleStyle = [];
    if (props.titleStyle) finalTitleStyle.push(props.titleStyle);
    if (props.children) finalTitleStyle.paddingLeft = 8;
    titleEl = <Text style={[styles.text, finalTitleStyle]}>{ props.title }</Text>
  }

  let style = { backgroundColor: '#D4D4D4' };
  if (props.color) style.backgroundColor = props.color;

  if (active) {
    style.opacity = .8;
    style.transform = [{ translateY: 1 }];
  }
  if (props.disabled) {
    style.opacity = .5;
  }

  if (props.style) {
    style = Object.assign(style, props.style);
  }

  return (
    <Pressable
      disabled={props.disabled}
      onPressIn={() => setActive(true) }
      onPressOut={() => setActive(false) }
      onPress={() => {
        playSound('click');
        if (props.onPress) props.onPress();
      }}
    >
      <View style={[styles.container, style]}>
        { props.children }
        { titleEl }
      </View>
    </Pressable>
  );
}

export default Button;

let containerStyle = {
  height: 40,
  paddingHorizontal: 16,
  borderRadius: 40,
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'row',
}
let textStyle = {
  fontFamily: 'Nunito-Regular',
  fontSize: 16,
  // marginBottom: 2 // Visual centering
}
if (Platform.OS === 'web') {
  containerStyle.cursor = 'pointer';
  textStyle.userSelect = 'none';
}

const styles = StyleSheet.create({
  container: containerStyle,
  text: textStyle
});
