import React from 'react';
import { Animated } from 'react-native';
import utils from '../utils/utils.js';
import boardUtils from '../utils/boardUtils.js';
import Daily from '../staticData/dailyData.js';
import Analytics from '../analytics/analytics.tsx';

export const SaveContext = React.createContext({});
export class SaveContextProvider extends React.Component {

  constructor(props) {
    super();

    /*
      levelProgress: {
        packID: levelIndex, // OLD
        packID: { [levelIndex]: true }
        ...
      }

      streakProgress: {
        dayID: bool,
        ...
      }

      tierProgress: {
        tierID: true
      }
    */

    this.state = {
      levelProgress: null,
      streakProgress: null,
      tierProgress: { 'tier_1': true },
      hasLoaded: false,
    }

    // Keep track in case day changes
    this.dayIndex = Daily.getDailyIndex();

    utils.getLocalItem('@honeycomb:levelProgress').then((levelProgress) => {
      let newLevelProgress = JSON.parse(JSON.stringify(levelProgress)) || null;

      utils.getLocalItem('@honeycomb:streakProgress').then((streakProgress) => {
        let newStreakProgress = JSON.parse(JSON.stringify(streakProgress));
        let shouldResetDaily = false;
        if (streakProgress) {
          if (!streakProgress.hasOwnProperty(this.dayIndex)) {
            newStreakProgress[this.dayIndex] = false;
            shouldResetDaily = true;
          }
        } else {
          newStreakProgress = { [this.dayIndex]: false }
          shouldResetDaily = true;
        }

        // If haven't seen new daily, reset progress
        if (shouldResetDaily) {
          utils.setLocalItem('@honeycomb:streakProgress', newStreakProgress);
          if (newLevelProgress && newLevelProgress.hasOwnProperty('daily')) {
            delete newLevelProgress.daily;
            utils.setLocalItem('@honeycomb:levelProgress', newLevelProgress);
          }
        }

        this.setState({
          streakProgress: newStreakProgress,
          levelProgress: newLevelProgress,
          hasLoaded: true,
        });
      });
    });

    utils.getLocalItem('@honeycomb:tierProgress').then((tierProgress) => {
      let newTierProgress = JSON.parse(JSON.stringify(tierProgress)) || { 'tier_1': true };
      this.setState({ tierProgress: newTierProgress });
    });

  }

  setLevelProgress(key, levelIndex, day) {
    const { levelProgress } = this.state;
    let newLevelProgress = {};

    // Streak
    if (key === 'daily' && levelIndex === 3) {
      this.setStreakProgress(this.dayIndex, true);
    }
    if (levelProgress) {
      newLevelProgress = JSON.parse(JSON.stringify(levelProgress));

      if (newLevelProgress.hasOwnProperty(key)) {
        // Convert old levelProgress
        if (typeof newLevelProgress[key] === 'number') {
          let oldProgress = newLevelProgress[key];
          newLevelProgress[key] = {};
          for (var i = 0; i < oldProgress; i++) {
            newLevelProgress[key][i] = true;
          }
        }
        newLevelProgress[key][levelIndex] = true;
      } else {
        newLevelProgress[key] = { [levelIndex]: true };
      }

    } else {
      newLevelProgress = {
        [key]: { [levelIndex]: true }
      }
    }
    this.setState({ levelProgress: newLevelProgress})
    utils.setLocalItem('@honeycomb:levelProgress', newLevelProgress);
  }

  setStreakProgress(day, bool) {
    const { streakProgress } = this.state;
    let newStreakProgress = {};
    if (streakProgress) {
      newStreakProgress = JSON.parse(JSON.stringify(streakProgress));
      newStreakProgress[day] = bool;

    } else {
      newStreakProgress = {
        [day]: bool
      }
    }
    this.setState({ streakProgress: newStreakProgress})
    utils.setLocalItem('@honeycomb:streakProgress', newStreakProgress);
  }

  setTierProgress(key) {
    const { tierProgress } = this.state;
    let newTierProgress = {};

    if (tierProgress) {
      newTierProgress = JSON.parse(JSON.stringify(tierProgress));
      newTierProgress[key] = true;
    } else {
      newTierProgress = { [key]: true };
    }
    this.setState({ tierProgress: newTierProgress})
    utils.setLocalItem('@honeycomb:tierProgress', newTierProgress);
    Analytics.setUserTierProgress(key);
  }

  render () {
    return (
      <SaveContext.Provider
        value={{
          ...this.state,
          setLevelProgress: this.setLevelProgress.bind(this),
          setTierProgress: this.setTierProgress.bind(this),
        }}
      >
        {this.props.children}
      </SaveContext.Provider>
    )
  }
}

// create the consumer as higher order component
export const withSaveContext = ChildComponent => props => (
  <SaveContext.Consumer>
    {
      context => <ChildComponent {...props} save={context}  />
    }
  </SaveContext.Consumer>
);