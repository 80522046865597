import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Orientation from './models/Orientation';
import Point from './models/Point';
import Hex from './models/Hex';
import Board from '../models/Board';
import HexUtils from './HexUtils';
import utils from '../utils/utils.js';
import { playSound } from '../utils/soundUtils.js';
import Svg, { G } from 'react-native-svg';
import { withBoardContext } from '../contexts/BoardContext';

class HexLayout extends Component {
  static LAYOUT_FLAT = new Orientation(3.0 / 2.0, 0.0, Math.sqrt(3.0) / 2.0, Math.sqrt(3.0),2.0 / 3.0, 0.0, -1.0 / 3.0, Math.sqrt(3.0) / 3.0, 0.0);
  static LAYOUT_POINTY = new Orientation(Math.sqrt(3.0), Math.sqrt(3.0) / 2.0, 0.0, 3.0 / 2.0, Math.sqrt(3.0) / 3.0, -1.0 / 3.0, 0.0, 2.0 / 3.0, 0.5);

  static propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    flat: PropTypes.bool,
    origin: PropTypes.object,
    size: PropTypes.object,
    spacing: PropTypes.number
  };

  static defaultProps = {
    size: { x: 10, y: 10 },
    flat: true,
    spacing: 1.0,
    origin: { x: 0, y: 0 }
  }

  static childContextTypes = {
    layout: PropTypes.object, // TODO Shape
    points: PropTypes.string
  };

  getChildContext() {
    const { children, flat, className, ...rest } = this.props;
    const orientation = (flat) ? HexLayout.LAYOUT_FLAT : HexLayout.LAYOUT_POINTY;
    const cornerCoords = this.calculateCoordinates(orientation);
    const distance = Math.sqrt( Math.pow((cornerCoords[0].x-cornerCoords[1].x), 2) + Math.pow((cornerCoords[0].y-cornerCoords[1].y), 2) );
    const points = cornerCoords.map(point => `${point.x},${point.y}`).join(' ');
    const childLayout = Object.assign({}, rest, { orientation, distance });
    this.layout = childLayout

    return {
      layout: childLayout,
      points
    };
  }

  getPointOffset(corner, orientation, size) {
    let angle = 2.0 * Math.PI * (corner + orientation.startAngle) / 6;
    return new Point(size.x * Math.cos(angle), size.y * Math.sin(angle));
  }

  // TODO Refactor
  calculateCoordinates(orientation) {
    const corners = [];
    const center = new Point(0, 0);
    const { size } = this.props;

    Array.from(new Array(6), (x, i) => {
      const offset = this.getPointOffset(i, orientation, size);
      const point = new Point(center.x + offset.x, center.y + offset.y);
      corners.push(point);
    });

    return corners;
  }

  onPointerUp() {
    const { setIsPointerDown } = this.props.board;
    setIsPointerDown(false);
  }

  _getHexFromTouchEvent(e) {
    let touches = utils.getPropertyByKeys(e, ['touches']);
    let touch = touches && touches.length && touches[0];
    let x = touch && touch.pageX;
    let y = touch && touch.pageY;
    if (this.centerRect && x) x = x - (this.centerRect.x + (this.centerRect.width / 2));
    if (this.centerRect && y) y = y - (this.centerRect.y + (this.centerRect.height / 2));

    let size = (this.centerRect.width / 2 * 1.2)
    // var q = ( 2./3 * x) / size
    // var r = (-1./3 * x  +  Math.sqrt(3)/3 * y) / size;

    const q = (Math.sqrt(3)/3 * x  -  1./3 * y) / size
    const r = (2./3 * y) / size

    let hex = new Hex(q,r,-q-r);
    console.log('hex', hex);
    hex = HexUtils.round(hex);
    return hex;
  }

  onClick(hex) {
    const { selectTile } = this.props.board;
    const { isGame } = this.props;
    const playNote = (i) => {
      if (i === 1) playSound('note2');
      if (i === 2) playSound('note1');
      if (i === 3) playSound('note2');
      if (i === 4) playSound('note3');
      if (i === 5) playSound('note4');
      if (i === 6) playSound('note5');
      if (i === 7) playSound('note4');
      if (i === 8) playSound('note5');
      if (i === 9) playSound('note6');
      if (i === 10) playSound('note7');
      if (i === 11) playSound('note8');
      if (i === 12) playSound('note6');
      if (i === 13) playSound('note7');
      if (i >= 14) {
        let odd = i % 2 === 0;
        if (odd) {
          playSound('note8');
        } else {
          playSound('note8alt');
        }
      }
    }

    if (isGame) {
      let shouldPlayNote = !utils.getPropertyByKeys(this.props.board, [`word${hex.wordIndex}`, 'color']);
      selectTile(hex);
      let letterIndex = (utils.getPropertyByKeys(this.props.board, [`word${hex.wordIndex}selected`]) || []).length;
      if (shouldPlayNote) {
        playNote(letterIndex);
      }
    }
  }

  render() {
    const { selectTile } = this.props.board;
    const { children, className, isGame } = this.props;
    let touchEvents = {};
    if (utils.isTouchDevice() && isGame) {
      touchEvents = {
        onTouchStart: (e) => {
          e.stopPropagation();
          e.preventDefault();
          let target = e.target;
          if (target.tagName === 'polygon') {
            target = target.parentElement;
          }
          if (target.tagName === 'g') {
            this.rect = target.getBoundingClientRect();
            let centerHex = target.querySelector('polygon[data-key="0,0,0"]');
            this.centerRect = centerHex && centerHex.getBoundingClientRect();
          }

          this.hoveredHex = this._getHexFromTouchEvent(e);
          let key = Board.positionToString(this.hoveredHex);
          this.onClick(this.props.board[key]);
        },
        onTouchMove: (e) => {
          e.stopPropagation();
          e.preventDefault();
          let newHex = this._getHexFromTouchEvent(e);
          if (!HexUtils.equals(newHex, this.hoveredHex)) {
            this.hoveredHex = newHex;
            let key = Board.positionToString(newHex);
            if (this.props.board[key]) {
              this.onClick(this.props.board[key]);
            }
          }
        },
        onTouchEnd: this.onPointerUp.bind(this)
      }
    }

    return (
      <G
        {...touchEvents}
      >
        {children}
      </G>
    );
  }
}

export default withBoardContext(HexLayout);
