import Animated, {
  cancelAnimation,
  useAnimatedGestureHandler,
  useSharedValue,
  useAnimatedStyle,
  useDerivedValue,
  withDelay,
  withTiming,
  withRepeat,
  withSequence,
  getRelativeCoords,
  runOnJS,
  measure,
  Easing,
} from 'react-native-reanimated';
import React, { Component, useState, useEffect } from 'react';
import { StyleSheet, View, Image, TouchableOpacity, ScrollView, Platform, Linking, Pressable } from 'react-native';
import ThemedText from './ThemedText';
import theme from '../styles/theme';
import BeePlatform from '../assets/BeePlatform';
import KeyIcon from '../assets/icons/KeyIcon';
import Button from '../components/Button';
import { withBoardContext } from '../contexts/BoardContext';
import { withSaveContext } from '../contexts/SaveContext';
import { withThemeContext } from '../contexts/ThemeContext';
import { playSound } from '../utils/soundUtils';
import Daily from '../staticData/dailyData';
import utils from '../utils/utils.js';
import Analytics from '../analytics/analytics';

const BuyNowModal = (props) => {
  const {
    close,
    packID,
    goToPacks,
    packColor
  } = props;
  const { levelIndex } = props.board;
  const { levelProgress } = props.save;
  const { themeStyle, isDarkMode } = props.theme;

  // Animation props
  const translateY = useSharedValue(-200);
  const opacity = useSharedValue(0);
  const animatedStyle = useAnimatedStyle(() => {
    return {
      transform: [{ translateY: translateY.value }],
      opacity: opacity.value,
    };
  });
  const beeTranslateY = useSharedValue(-100);
  const beeAnimatedStyle = useAnimatedStyle(() => {
    return {
      transform: [{ translateY: beeTranslateY.value }],
    };
  });
  const overlayOpacity = useSharedValue(0);
  const overlayAnimatedStyle = useAnimatedStyle(() => {
    return {
      opacity: overlayOpacity.value
    };
  });

  // ComponentDidMount
  useEffect(() => {
    let duration = 500;

    translateY.value = withTiming(0, { duration: duration, easing: Easing.bezier(0.34, 1.56, 0.64, 1) });
    opacity.value = withTiming(1, { duration: duration });
    beeTranslateY.value = withDelay(100, withTiming(0, { duration: duration , easing: Easing.bezier(0.34, 1.56, 0.64, 1) }));
    overlayOpacity.value = withTiming(.5, { duration: duration / 2 });
  }, []);

  // Styles
  let modalWidth = Math.min(350, theme.SCREEN_WIDTH - 50);
  let finalModalStyle = [styles.modalStyle, {
    backgroundColor: themeStyle.CARD_BG,
    borderTopRightRadius: modalWidth / 2 - 50,
    borderTopLeftRadius: modalWidth / 2 - 50,
    width: modalWidth,
  }];
  let finalMainTextStyle = [styles.completionModalMainText]
  if (Platform.OS === 'web') finalModalStyle.push({ transform: [{ translateY: '-50%' }] });

  // Elements
  let androidButtonEl = (
    <Button
      style={{ backgroundColor: 'transparent' }}
      onPress={() => {
        Linking.openURL("https://play.google.com/store/apps/details?id=com.spiralburst.honeycomb");
        Analytics.logLinkClicked('android-from-buy-modal');
      }}
    >
      <Image style={{ width: 133, height: 40, marginBottom: 5 }} source={require('../assets/google-play-badge.png')} />
    </Button>
  );

  let iosBadgeSource = require('../assets/app-store-badge.png');
  if (isDarkMode) iosBadgeSource = require('../assets/app-store-badge-white.png')

  let iosButtonEl = (
    <Button
      style={{ backgroundColor: 'transparent' }}
      onPress={() => {
        Linking.openURL("https://apps.apple.com/us/app/honeycomb-word-puzzle/id6446267827");
        Analytics.logLinkClicked('ios-from-buy-modal');
      }}
    >
      <Image style={{ width: 120, height: 40, marginBottom: 5 }} source={iosBadgeSource} />
    </Button>
  );

  let beeEl = (
    <Animated.View style={[{ ...theme.flexCenter, marginTop: 15}, beeAnimatedStyle]}>
      <BeePlatform />
      <View style={{ position: 'absolute', bottom: 5, }}>
        <Image style={{ width: 135, height: 135, marginBottom: 5 }} source={require('../assets/images/honeycomb-bee.png')}/>
      </View>
    </Animated.View>
  );

  let closeBtn = (
    <Pressable
      style={[styles.closeContainer, { backgroundColor: themeStyle.TEXT_COLOR }]}
      onPress={() => {
        overlayOpacity.value = withTiming(0, { duration: 200 });
        opacity.value = withTiming(0, { duration: 200 }, () => {
          runOnJS(close)();
        });
      }}
    >
      <ThemedText style={{ fontSize: 36, marginTop: -6, color: themeStyle.BG }}>x</ThemedText>
    </Pressable>
  )

  let continueBtnEl = null;
  let mainText = "Thanks for playing Honeycomb!"
  let subText = "Purchase the Honeycomb app to unlock more than 500+ handmade levels and the third level of every daily challenge."

  // For daily only, show different text and show Continue button functionality
  if (packID && packID === 'daily' && goToPacks && packColor) {
    subText = "Purchase the Honeycomb app to unlock the third level of every Daily Challenge and 500+ handmade puzzles.";

    let packIsComplete = utils.getPropertyByKeys(levelProgress, [packID, 0]) && utils.getPropertyByKeys(levelProgress, [packID, 1]);

    if (packIsComplete) {
      mainText = `Daily ${parseInt(Daily.getDailyIndex()) + 1} Complete!`;

      // Show continue button only for level 2 in daily when complete
      if (levelIndex === 1) {
        finalModalStyle.height = 360;
        continueBtnEl = (
          <View style={[styles.nextBtnContainer, { backgroundColor: packColor }]}>
            <Button
              title={'Continue'}
              titleStyle={{ color: 'white', fontSize: 24, fontFamily: 'Nunito-SemiBold' }}
              style={{ width: modalWidth - 60, height: 48, borderRadius: 24, ...theme.flexCenter }}
              color={theme.BLACK_ALMOST}
              onPress={() => goToPacks()}
            />
          </View>
        );
      }
    }
  }

  return (
    <View style={{ zIndex: 2, ...theme.fullAbsolute, ...theme.flexCenter }}>
      <Animated.View style={[theme.overlay, overlayAnimatedStyle]}/>
      <Animated.View style={[finalModalStyle, animatedStyle]}>
        { beeEl }
        <ThemedText style={finalMainTextStyle}>{ mainText }</ThemedText>
        <ThemedText style={{ paddingHorizontal: 32 }}>{ subText }</ThemedText>
        <View style={ styles.nextBtnContainer }>
          { androidButtonEl }
          { iosButtonEl }
        </View>
        { continueBtnEl }
        { closeBtn }
      </Animated.View>
    </View>
  );
}
export default withSaveContext(withBoardContext(withThemeContext(BuyNowModal)));

const styles = StyleSheet.create({
  modalStyle: {
    alignItems: 'center',
    height: 350,
    borderBottomLeftRadius: 20,
    borderBottomRightRadius: 20,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 6,
    },
    shadowOpacity: 0.3,
    shadowRadius: 20,
    justifyContent: 'space-between'
  },
  completionModalMainText: {
    fontSize: 32,
    fontFamily: 'Nunito-SemiBold',
    textAlign: 'center'
  },
  closeContainer: {
    position: 'absolute',
    top: 10,
    right: 5,
    height: 50,
    width: 50,
    ...theme.row,
    ...theme.flexCenter,
    borderRadius: 50,
  },
  nextBtnContainer: {
    height: 86,
    ...theme.row,
    ...theme.flexCenter,
    width: '100%',
    borderBottomLeftRadius: 20,
    borderBottomRightRadius: 20,
  },
});
