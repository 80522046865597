import React from 'react';
import utils  from '../utils/utils.js';
import theme from '../styles/theme';

export const ThemeContext = React.createContext({});

export const TILESETS = {
  DEFAULT: {
    BLUE_LIGHT: '#A0E8E6',
    BLUE_DARK: '#01C2BB',
    RED_LIGHT: '#FFB2B2',
    RED_DARK: '#FF7171',
  },
  40: { // ROYAL
    BLUE_LIGHT: '#D7AEEB',
    BLUE_DARK: '#A768C5',
    RED_LIGHT: '#FEE4A2',
    RED_DARK: '#FFC636',
  },
  41: { // PEAS_CARROTS
    BLUE_LIGHT: '#A2EBAA',
    BLUE_DARK: '#33D165',
    RED_LIGHT: '#FFC49A',
    RED_DARK: '#FF8660',
  },
  42: { // ROSY_MAPLE
    BLUE_LIGHT: '#FFC2DF',
    BLUE_DARK: '#FF6FB3',
    RED_LIGHT: '#F2E5A2',
    RED_DARK: '#FAD633',
  },
  43: { // NEON_LIGHTS
    BLUE_LIGHT: '#A5ECEB',
    BLUE_DARK: '#00DDDA',
    RED_LIGHT: '#F8B9E3',
    RED_DARK: '#FF73D0',
  },
  44: { // SEAFOAM
    BLUE_LIGHT: '#CFC7FF',
    BLUE_DARK: '#9785FF',
    RED_LIGHT: '#B0E9D9',
    RED_DARK: '#28EBB4',
  },
  45: { // KETCHUP_MUSTARD
    BLUE_LIGHT: '#FAE89D',
    BLUE_DARK: '#FAD023',
    RED_LIGHT: '#FFABAB',
    RED_DARK: '#FA5F5F',
  },
  46: { // EVERGREEN
    BLUE_LIGHT: '#A8E3D1',
    BLUE_DARK: '#40BA95',
    RED_LIGHT: '#E9CBA8',
    RED_DARK: '#C6804B',
  },
  47: { // BLUE_ORANGE
    BLUE_LIGHT: '#AFE9ED',
    BLUE_DARK: '#00D3E0',
    RED_LIGHT: '#FFB9A3',
    RED_DARK: '#FF7C53',
  },
  48: { // BURNT_PERIWINKLE
    BLUE_LIGHT: '#B9C9F2',
    BLUE_DARK: '#5B7ED9',
    RED_LIGHT: '#EBB4AC',
    RED_DARK: '#E17667',
  },
  49: { // PURPLE_GREEN
    BLUE_LIGHT: '#B9C9F2',
    BLUE_DARK: '#5B7ED9',
    RED_LIGHT: '#CCF381',
    RED_DARK: '#A1DD2E',
  },
  50: { // CORAL_REEF
    BLUE_LIGHT: '#A4EDE0',
    BLUE_DARK: '#44D6BB',
    RED_LIGHT: '#FFCED1',
    RED_DARK: '#FF837A',
  },
  51: { // PURPLE_PINK
    BLUE_LIGHT: '#C3CAE0',
    BLUE_DARK: '#8996BE',
    RED_LIGHT: '#F6CAD5',
    RED_DARK: '#FF7495',
  },
  52: { // MUTED_SUNSHINE
    BLUE_LIGHT: '#F4EABA',
    BLUE_DARK: '#FDD475',
    RED_LIGHT: '#C1E1DC',
    RED_DARK: '#7DC6BA',
  },
}

export class ThemeContextProvider extends React.Component {

  constructor(props) {
    super();

    this.state = {
      tilesetID: 'DEFAULT',
      tilesetTheme: TILESETS.DEFAULT,
      isDarkMode: false,
      isTextureMode: false,
      isColorBlindMode: false,
      isMarkStartTilesMode: true,
      themeStyle: theme.LIGHT_THEME,
    }
  }

  componentDidMount() {
    utils.getLocalItem('@honeycomb:isDarkMode').then((isDarkMode) => {
      if (isDarkMode) {
        this.setState({
          isDarkMode,
          themeStyle: theme.DARK_THEME,
        });
      }
    });
    utils.getLocalItem('@honeycomb:isMarkStartTilesMode').then((isMarkStartTilesMode) => {
      if (isMarkStartTilesMode) {
        this.setState({
          isMarkStartTilesMode
        });
      }
    });
    utils.getLocalItem('@honeycomb:isTextureMode').then((isTextureMode) => {
      if (isTextureMode) {
        this.setState({
          isTextureMode
        });
      }
    });
    utils.getLocalItem('@honeycomb:isColorBlindMode').then((isColorBlindMode) => {
      if (isColorBlindMode) {
        this.setState({
          isColorBlindMode
        });
      }
    });
  }

  toggleMarkStartTilesMode() {
    const { isMarkStartTilesMode } = this.state;
    let newIsMarkStartTilesMode = !isMarkStartTilesMode;
    this.setState({
      isMarkStartTilesMode: newIsMarkStartTilesMode,
    });
    utils.setLocalItem('@honeycomb:isMarkStartTilesMode', newIsMarkStartTilesMode);
  }

  toggleDarkMode() {
    const { isDarkMode } = this.state;
    let newIsDarkMode = !isDarkMode;
    this.setState({
      isDarkMode: newIsDarkMode,
      themeStyle: newIsDarkMode ? theme.DARK_THEME : theme.LIGHT_THEME
    });
    utils.setLocalItem('@honeycomb:isDarkMode', newIsDarkMode);
  }

  toggleTextureMode() {
    const { isTextureMode } = this.state;
    let newIsTextureMode = !isTextureMode;
    this.setState({
      isTextureMode: newIsTextureMode,
    })
    utils.setLocalItem('@honeycomb:isTextureMode', newIsTextureMode);
  }

  toggleColorBlindMode() {
    const { isColorBlindMode } = this.state;
    let newIsColorBlindMode = !isColorBlindMode;
    this.setState({
      isColorBlindMode: newIsColorBlindMode,
    });
    utils.setLocalItem('@honeycomb:isColorBlindMode', newIsColorBlindMode);
  }

  getColorBlindColor(index) {
    const { isDarkMode } = this.state;
    let color = null;
    if (isDarkMode) {
      if (index === 1) color = "#5BC8AF";
      if (index === 2) color = "#C1A4FF";
      if (index === 3) color = "#FFEF62";
      if (index === 4) color = "#FFB9EB";
      if (index === 5) color = "#80DED9";
      if (index === 6) color = "#FF9052";
    } else {
      if (index === 1) color = "#0042C9";
      if (index === 2) color = "#A177EE";
      if (index === 3) color = "#A51653";
      if (index === 4) color = "#F45800";
      if (index === 5) color = "#009D99";
      if (index === 6) color = "#00803D";
    }
    return color;
  }

  render () {
    return (
      <ThemeContext.Provider
        value={{
          ...this.state,
          toggleDarkMode: this.toggleDarkMode.bind(this),
          toggleMarkStartTilesMode: this.toggleMarkStartTilesMode.bind(this),
          toggleTextureMode: this.toggleTextureMode.bind(this),
          toggleColorBlindMode: this.toggleColorBlindMode.bind(this),
          getColorBlindColor: this.getColorBlindColor.bind(this),
        }}
      >
        {this.props.children}
      </ThemeContext.Provider>
    )
  }
}

// create the consumer as higher order component
export const withThemeContext = ChildComponent => props => (
  <ThemeContext.Consumer>
    {
      context => <ChildComponent {...props} theme={context}  />
    }
  </ThemeContext.Consumer>
);