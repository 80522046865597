import { useState } from 'react'
import { StyleSheet, Text, View, TextInput, Button, TouchableOpacity } from 'react-native';
import {
  HexGrid,
  HexLayout,
  Tile, HexText,
  HexPattern,
  Hex,
  HexUtils} from '../HexGrid';
import Board from '../models/Board';
import Word from '../components/Word';
import utils from '../utils/utils';
import { withThemeContext } from '../contexts/ThemeContext';
import theme from '../styles/theme';
// import { withBoardContext } from '../contexts/BoardContext';

export const colors = [
  '#FF8587',
  '#FFAEBC',
  '#FFA384',
  '#FFCC2B',
  '#ECF87F',
  '#81B622',
  '#39981A',
  '#B4F8C8',
  '#0DC2BB',
  '#30E7E5',
  '#189AB4',
  '#6586DA',
  '#949CF4',
  '#BD94F4',
  '#E07DE7',
  '#9C6137',
  '#B37A4C',
  '#CC9966',
  '#FBE7C6',
];

const ColorPicker = (props) => {
  const { isDarkMode, themeStyle } = props.theme;
  let colorSwatchArray = [];
  for (let i = 0; i < colors.length; i++) {
    let color = colors[i];
    if (props.allColors.indexOf(color) >= 0) {
      colorSwatchArray.push(
        <View
          key={`color-${i}`}
          style={[styles.swatch, {
            backgroundColor: color,
            borderWidth: 2,
            borderStyle: color === props.selectedColor ? 'solid' : 'dotted',
            borderColor: isDarkMode ? "#FFF" : "#000"
          }]}
        />
      );
    } else {
      colorSwatchArray.push(
        <TouchableOpacity
          key={`color-${i}`}
          style={[styles.swatch, {
            backgroundColor: color
          }]}
          onPress={() => { props.setColor(color) }}
        />
      );
    }
  }


  let pickerStyle = {
    position: 'absolute',
    left: 60,
    top: 0,
    width: 200,
    flexDirection: 'row',
    flexWrap: 'wrap',
    backgroundColor: themeStyle.CARD_BG,
    padding: 10,
    borderRadius: 10,
  }

  return (
    <View style={pickerStyle}>
      { colorSwatchArray }
    </View>
  );
}
export default withThemeContext(ColorPicker);

const styles = StyleSheet.create({
  swatch: {
    height: 30,
    width: 30,
    borderRadius: 3,
    margin: 2,
  },
});
