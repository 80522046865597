import React from 'react';
import Svg, { Path, G, Circle, Defs, Rect, ClipPath } from 'react-native-svg';

const InfoIcon = (props) => {
  const { iconColor, markColor, size } = props;
  let finalIconColor = iconColor || 'black';
  let finalMarkColor = markColor || 'white';
  let finalSize = size || 40;

  return (
    <Svg width={finalSize} height={finalSize} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <G clipPath="url(#clip0_2873_70580)">
        <Circle cx="20" cy="20" r="13" fill={finalIconColor} stroke={finalIconColor} strokeWidth="2"/>
        <Path d="M20.3187 23.2039C19.9774 23.2039 19.706 23.0952 19.5044 22.8777C19.3183 22.6447 19.2252 22.334 19.2252 21.9456C19.2252 21.4952 19.2795 21.0757 19.3881 20.6874C19.4966 20.2835 19.6672 19.8796 19.8999 19.4757C20.1481 19.0719 20.4738 18.6602 20.8771 18.2408C21.1562 17.9146 21.3811 17.635 21.5518 17.4019C21.7379 17.1534 21.862 16.9204 21.924 16.7029C22.0016 16.4699 22.0403 16.2369 22.0403 16.0039C22.0403 15.5689 21.8775 15.2272 21.5518 14.9786C21.2416 14.7301 20.7917 14.6058 20.2024 14.6058C19.644 14.6058 19.1244 14.6757 18.6435 14.8155C18.1782 14.9553 17.7207 15.1573 17.2709 15.4214C16.8676 15.6388 16.5031 15.7243 16.1774 15.6777C15.8672 15.6155 15.619 15.4757 15.4329 15.2583C15.2468 15.0252 15.1382 14.7534 15.1072 14.4427C15.0761 14.132 15.1459 13.8291 15.3165 13.534C15.4872 13.2233 15.7741 12.9515 16.1774 12.7185C16.8133 12.3456 17.519 12.066 18.2946 11.8796C19.0701 11.6932 19.7913 11.6 20.4583 11.6C21.482 11.6 22.3816 11.7709 23.1571 12.1126C23.9326 12.4544 24.5298 12.9204 24.9486 13.5107C25.3829 14.101 25.6 14.7922 25.6 15.5845C25.6 16.066 25.5302 16.5165 25.3906 16.9359C25.251 17.3553 25.0261 17.7748 24.7159 18.1942C24.4057 18.5981 23.9792 19.0252 23.4363 19.4757C22.9555 19.8796 22.5755 20.2447 22.2963 20.5709C22.0171 20.8816 21.8154 21.1845 21.6914 21.4796C21.5673 21.7592 21.482 22.0621 21.4354 22.3884C21.3889 22.6214 21.2726 22.8155 21.0864 22.9709C20.9158 23.1262 20.6599 23.2039 20.3187 23.2039ZM20.2721 28.4C19.6517 28.4 19.1554 28.2136 18.7831 27.8408C18.4264 27.468 18.248 26.9864 18.248 26.3961C18.248 25.8214 18.4264 25.3553 18.7831 24.9981C19.1554 24.6252 19.6517 24.4388 20.2721 24.4388C20.8926 24.4388 21.3734 24.6252 21.7146 24.9981C22.0714 25.3553 22.2497 25.8214 22.2497 26.3961C22.2497 26.9864 22.0714 27.468 21.7146 27.8408C21.3734 28.2136 20.8926 28.4 20.2721 28.4Z" fill={finalMarkColor}/>
      </G>
      <Defs>
        <ClipPath id="clip0_2873_70580">
          <Rect width="28" height="28" fill="white" transform="translate(6 6)"/>
        </ClipPath>
      </Defs>
    </Svg>
  );
}

export default InfoIcon;