import React, { Component } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import PropTypes from 'prop-types';
import Svg, { Text as SvgText } from 'react-native-svg';
import NunitoSvgLetter from '../../assets/svgletters/NunitoSvgLetter.js';

// TODO Text is a separate component so that it could wrap the given text inside the surrounding hexagon
class HexText extends Component {
  static propTypes = {
    children: PropTypes.string,
    x: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]),
    y: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]),
    className: PropTypes.string
  };

  render() {
    const { children, x, y, color, textColor, className, boardSize, isStart, isSelected, isTextured, isWord, layoutSizeX, layoutSizeDefault } = this.props;

    return (
      <NunitoSvgLetter x={x || 0} y={y} color={color} textColor={textColor} letter={children} boardSize={boardSize} isStart={isStart} isSelected={isSelected} isTextured={isTextured} isWord={isWord} layoutSizeX={layoutSizeX} layoutSizeDefault={layoutSizeDefault}/>
    );
  }
}

export default HexText;
