import Animated, {
  cancelAnimation,
  useAnimatedGestureHandler,
  useSharedValue,
  useAnimatedStyle,
  useDerivedValue,
  withDelay,
  withTiming,
  withRepeat,
  withSequence,
  getRelativeCoords,
  runOnJS,
  measure,
  Easing,
} from 'react-native-reanimated';
import React, { Component, useState, useEffect } from 'react';
import { StyleSheet, View, Image, TouchableOpacity, ScrollView, Platform, Linking } from 'react-native';
import ThemedText from './ThemedText';
import theme from '../styles/theme';
import BeePlatform from '../assets/BeePlatform';
import KeyIcon from '../assets/icons/KeyIcon';
import Button from '../components/Button';
import { withBoardContext } from '../contexts/BoardContext';
import { withSaveContext } from '../contexts/SaveContext';
import { withThemeContext } from '../contexts/ThemeContext';
import { playSound } from '../utils/soundUtils';
import Daily from '../staticData/dailyData';
import utils from '../utils/utils.js';
import CommunityPackAuthors from '../staticData/communityPackAuthors';
import Analytics from '../analytics/analytics';

const CompletionModal = (props) => {
  const { levelIndex } = props.board;
  const { levelProgress } = props.save;
  const { themeStyle, isDarkMode } = props.theme;
  const { levels, surveyTapped, linkSharedMode, packID, showCompletionMode, packColor, routeName, advanceLevel, goToPacks, goToEditor, minimize } = props;

  if (!showCompletionMode) return null;

  // Animation props
  const translateY = useSharedValue(-200);
  const opacity = useSharedValue(0);
  const animatedStyle = useAnimatedStyle(() => {
    return {
      transform: [{ translateY: translateY.value }],
      opacity: opacity.value,
    };
  });
  const beeTranslateY = useSharedValue(-100);
  const beeAnimatedStyle = useAnimatedStyle(() => {
    return {
      transform: [{ translateY: beeTranslateY.value }],
    };
  });
  const overlayOpacity = useSharedValue(0);
  const overlayAnimatedStyle = useAnimatedStyle(() => {
    return {
      opacity: overlayOpacity.value
    };
  });

  // ComponentDidMount
  useEffect(() => {
    let duration = 500;

    translateY.value = withDelay(50, withTiming(0, { duration: duration, easing: Easing.bezier(0.34, 1.56, 0.64, 1) }));
    opacity.value = withDelay(50, withTiming(1, { duration: duration }));
    beeTranslateY.value = withDelay(150, withTiming(0, { duration: duration, easing: Easing.bezier(0.34, 1.56, 0.64, 1) }));
    overlayOpacity.value = withDelay(50, withTiming(.5, { duration: duration / 2 }));
  }, []);

  // Styles
  let modalWidth = Math.min(350, theme.SCREEN_WIDTH - 50);
  let finalModalStyle = [styles.modalStyle, {
    backgroundColor: themeStyle.CARD_BG,
    borderTopRightRadius: modalWidth / 2 - 50,
    borderTopLeftRadius: modalWidth / 2 - 50,
    width: modalWidth,
  }];
  let finalMainTextStyle = [styles.completionModalMainText]
  if (Platform.OS === 'web') finalModalStyle.push({ transform: [{ translateY: '-50%' }] });

  // Style modifications
  // if (secondaryBtnEl) finalModalStyle.push({ height: 400 });

  // Supporting variables
  let packProgress = utils.getPropertyByKeys(levelProgress, [packID]) || 0;
  let numCompleted = utils.calcPackLevelsCompleted(packProgress)
  let packIsCompleted = numCompleted >= levels.length;

  // Element inputs
  let mainText = `Puzzle ${levelIndex + 1} solved!`;
  if (packID.indexOf('community') >= 0) {
    const authors = utils.getPropertyByKeys(CommunityPackAuthors, [packID]);
    let authorName = authors && authors[levelIndex];
    mainText = `${authorName}'s puzzle solved!` // Show author's name
    finalMainTextStyle.push({ fontSize: 28 })
  }

  let advanceText = "Next Puzzle";
  let advanceOnPress = () => advanceLevel();

  let subText = null;
  let subTextEl = null;
  let secondaryBtnEl = null;

  if (packIsCompleted) {
    // Main text modifications
    if (packID === 'daily') {
      mainText = `Daily ${parseInt(Daily.getDailyIndex()) + 1} Complete!`;
    } else {
      mainText = `${utils.parsePackKey(packID)} Complete!`;
      finalMainTextStyle.push({ textTransform: 'capitalize', fontSize: 28 });
    }

    let iosBadgeSource = require('../assets/app-store-badge.png');
    if (isDarkMode) iosBadgeSource = require('../assets/app-store-badge-white.png');

    if (packID === 'starter' ||
        packID === 'animals' ||
        packID === 'themed_1' ||
        packID === 'community_1' ||
        packID === 'unthemed_1' ||
        packID === 'alphabet_soup') {
      finalModalStyle.push({ height: 400 });
      subText = 'This is the free demo of Honeycomb. Check out the app!';
      subTextEl = (
        <View style={{ marginBottom: 10 }}>
          <ThemedText style={{ fontSize: 16, paddingHorizontal: 25, fontFamily: 'Nunito-Regular', textAlign: 'center' }}>{ subText }</ThemedText>
          <View style={[theme.row, theme.flexCenter, { marginTop: 10 }]}>
            <Button
              style={{ backgroundColor: 'transparent' }}
              onPress={() => {
                Linking.openURL("https://play.google.com/store/apps/details?id=com.spiralburst.honeycomb");
                Analytics.logLinkClicked(`android-from-completed-${packID}`);
              }}
            >
              <Image style={{ width: 133, height: 40, marginBottom: 5 }} source={require('../assets/google-play-badge.png')} />
            </Button>
            <Button
              style={{ backgroundColor: 'transparent' }}
              onPress={() => {
                Linking.openURL("https://apps.apple.com/us/app/honeycomb-word-puzzle/id6446267827");
                Analytics.logLinkClicked(`ios-from-completed-${packID}`);
              }}
            >
              <Image style={{ width: 120, height: 40, marginBottom: 5 }} source={iosBadgeSource} />
            </Button>
          </View>
        </View>
      )
    }



    // Advance modifications
    advanceText = "Continue";
    advanceOnPress = () => goToPacks();
  }

  // Element input modifications
  if (routeName === 'CustomGame') {
    mainText = 'Puzzle Solved!'; // Change to [author]'s puzzle solved!
    subText = 'You can make your own puzzle, or go back to the puzzle list.';
    subTextEl = <ThemedText style={{ fontSize: 16, paddingHorizontal: 25, fontFamily: 'Nunito-Regular', textAlign: 'center' }}>{ subText }</ThemedText>

    advanceText = 'Done'
    advanceOnPress = () => goToPacks();

    secondaryBtnEl = (
      <Button
        title={'Make Puzzle'}
        titleStyle={{ color: 'white', fontSize: 22, fontFamily: 'Nunito-SemiBold' }}
        style={{ width: 170, height: 48, borderRadius: 24, ...theme.flexCenter }}
        color={theme.BLACK_ALMOST}
        onPress={() => goToEditor()}
      />
    )
  }

  // Elements
  let advanceBtnEl = (
    <Button
      title={advanceText}
      titleStyle={{ color: 'white', fontSize: 24, fontFamily: 'Nunito-SemiBold' }}
      style={{ width: secondaryBtnEl ? 110 : modalWidth - 60, height: 48, borderRadius: 24, ...theme.flexCenter }}
      color={theme.BLACK_ALMOST}
      onPress={() => {
        overlayOpacity.value = withTiming(0, { duration: 200 });
        opacity.value = withTiming(0, { duration: 200 }, () => {
          runOnJS(advanceOnPress)();
        });
      }}
    />
  );

  let beeEl = (
    <Animated.View style={[{ ...theme.flexCenter, marginTop: 15}, beeAnimatedStyle]}>
      <BeePlatform color={packColor}/>
      <View style={{ position: 'absolute', bottom: 5, }}>
        <Image style={{ width: 135, height: 135, marginBottom: 5 }} source={require('../assets/images/honeycomb-bee.png')}/>
      </View>
    </Animated.View>
  );

  let minimizeBtn = (
    <Button
      style={{ backgroundColor: theme.GRAY_1, paddingHorizontal: 25 }}
      title={"View Finished Puzzle"}
      titleStyle={{ color: 'black', fontFamily: 'Nunito-SemiBold', fontSize: 18 }}
      onPress={() => {
        overlayOpacity.value = withTiming(0, { duration: 200 });
        opacity.value = withTiming(0, { duration: 200 }, () => {
          runOnJS(minimize)();
        });
      }}
    />
  );

  let keyEl = null
  if (packID !== 'daily' && routeName !== 'CustomGame') {
    keyEl = (
      <View style={[styles.completionKeyContainer, { backgroundColor: themeStyle.TEXT_COLOR }]}>
        <ThemedText style={{ color: themeStyle.BG, fontFamily: 'Nunito-Bold', fontSize: 24, marginBottom: 2 }}>+1</ThemedText>
        <View style={{ marginBottom: 4, marginLeft: Platform.OS === 'web' ? 3 : -3, marginRight: 3 }}>
          <KeyIcon color={themeStyle.BG} size={24}/>
        </View>
      </View>
    )
  }

  let btnContainerStyle = [styles.nextBtnContainer, { backgroundColor: packColor }];
  if (secondaryBtnEl) {
    btnContainerStyle.push({
      justifyContent: 'space-between',
      flexDirection: 'row',
      paddingHorizontal: 20,
    })
  }

  return (
    <View style={{ zIndex: 2, ...theme.fullAbsolute, ...theme.flexCenter }}>
      <Animated.View style={[theme.overlay, overlayAnimatedStyle]}/>
      <Animated.View style={[finalModalStyle, animatedStyle]}>
        { beeEl }
        <ThemedText style={finalMainTextStyle}>{ mainText }</ThemedText>
        { subTextEl }
        { minimizeBtn }
        <View style={btnContainerStyle}>
          { secondaryBtnEl }
          { advanceBtnEl }
        </View>
        { keyEl }
      </Animated.View>
    </View>
  );
}
export default withSaveContext(withBoardContext(withThemeContext(CompletionModal)));

const styles = StyleSheet.create({
  modalStyle: {
    alignItems: 'center',
    height: 350,
    borderBottomLeftRadius: 20,
    borderBottomRightRadius: 20,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 6,
    },
    shadowOpacity: 0.3,
    shadowRadius: 20,
    justifyContent: 'space-between'
  },
  completionModalMainText: {
    fontSize: 40,
    fontFamily: 'Nunito-SemiBold',
    textAlign: 'center'
  },
  completionKeyContainer: {
    position: 'absolute',
    top: 10,
    right: 5,
    height: 50,
    ...theme.row,
    paddingLeft: 4,
    paddingRight: 6,
    paddingTop: 2,
    borderRadius: 12,
  },
  nextBtnContainer: {
    height: 96,
    ...theme.flexCenter,
    width: '100%',
    borderBottomLeftRadius: 20,
    borderBottomRightRadius: 20,
  },
  modalCloseButton: {
    backgroundColor: theme.GRAY_1
  },
  buttonText: {
    fontSize: 18,
    color: '#353535',
    fontFamily: 'Nunito-SemiBold'
  },
  levelText: {
    fontSize: 16,
    color: '#353535',
    fontFamily: 'Nunito-SemiBold'
  },
});
