export default class CommunityPackAuthors {

  static community_1 = [
    'dianyx',
    'Sheikhspeare',
    'Lyle Broughton', // jackofallspades98
    'Sheikhspeare',
    'Ricky Cruz',

    'dianyx',
    'Sheikhspeare',
    'dianyx',
    'Brian Thomas',
    'ada nicole',

    'Frisco17',
    'Ricky Cruz',
    'dianyx',
    'dianyx',
    'dianyx',
  ];

  static community_2 = [
    'Adam Aaronson',
    'dianyx',
    'Lyle Broughton', // jackofallspades98
    'Sheikhspeare',
    'dianyx',

    'Sheikhspeare',
    'dianyx',
    'dianyx',
    'Christian',
    'butternoodle',

    'Sheikhspeare',
    'Frisco17',
    'dianyx',
    'Sheikhspeare',
    'dianyx'
  ];

  static community_3 = [
    'spunky',
    'dianyx',
    'Zimodo',
    'noneuclidean',
    'Christian',

    'noneuclidean',
    'dianyx',
    'spunky',
    'spunky',
    'Zimodo',

    'Christian',
    'spunky',
    'Sheikhspeare',
    'dianyx',
    'Sheikhspeare',
  ];

  community_4 = [
    'dianyx',
    'Sheikhspeare',
    'Katelyn',
    'SeptaCube',
    'Katelyn',

    'spunky',
    'Christian',
    'Sheikhspeare',
    'Christian',
    'noneuclidean',

    'noneuclidean',
    'noneuclidean',
    'spunky',
    'dianyx',
    'dianyx',
  ];

  community_5 = [
    'noneuclidean',
    'dianyx',
    'Christian',
    'dianyx',
    'Sheikhspeare',

    'Zimodo',
    'dianyx',
    'Christian',
    'Sheikhspeare',
    'Christian',

    'dianyx',
    'spunky',
    'dianyx',
    'spunky',
    'dianyx',
  ];

  community_6 = [
    'spunky',
    'noneuclidean',
    'dianyx',
    'spunky',
    'Christian',

    'noneuclidean',
    'spunky',
    'dianyx',
    'Christian',
    'dianyx',

    'Sheikhspeare',
    'Christian',
    'Sheikhspeare',
    'Sheikhspeare',
    'Christian',
  ];
}