import React, { Component } from 'react';
import { View } from 'react-native';

import Svg, { Path, Mask } from 'react-native-svg';

const Reset = (props) => {
  let color = props.color || '#232323'
  return (
    <Svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <Path fillRule="evenodd" clipRule="evenodd" d="M6.04808 12.0001C6.04808 12.1102 6.13852 12.1977 6.24859 12.1977H7.9239C8.30132 12.1977 8.54266 12.5999 8.36513 12.9329L5.04134 19.1682C4.85313 19.5212 4.34709 19.5212 4.15889 19.1682L0.835099 12.9329C0.657563 12.5999 0.898911 12.1977 1.27633 12.1977H2.96373C3.07228 12.1977 3.15947 12.1085 3.15947 12V12C3.15947 6.69806 7.46972 2.39999 12.7867 2.39999C18.1036 2.39999 22.4139 6.69806 22.4139 12C22.4139 17.13 18.3786 21.3202 13.3006 21.5865C13.0246 21.601 12.8001 21.3757 12.8001 21.0993V19.2191C12.8001 18.9434 13.0241 18.7216 13.2992 18.701C16.7818 18.4401 19.5262 15.5397 19.5262 12.0001C19.5262 8.28873 16.509 5.28009 12.7871 5.28009C9.06526 5.28009 6.04808 8.28873 6.04808 12.0001V12.0001Z" fill={color}/>
    </Svg>
  );
}
export default Reset;