import Animated, {
  useSharedValue,
  useAnimatedStyle,
  useAnimatedProps,
  useDerivedValue,
  SlideInRight,
  SlideOutDown,
  Layout,
  measure,
  interpolate,
  withTiming,
  withDelay,
  withSequence,
  Easing,
  runOnJS,
} from 'react-native-reanimated';
import { useState, useEffect, useMemo } from 'react'
import { StyleSheet, Text, View, Platform, PanResponder } from 'react-native';
import {
  HexGrid,
  HexLayout,
  Tile, HexText,
  HexPattern,
  Hex,
  HexUtils} from '../HexGrid';
import Point from '../HexGrid/models/Point';
import Orientation from '../HexGrid/models/Orientation';
import Board from '../models/Board';
import GameBoardTransition from './GameBoardTransition';
import theme from '../styles/theme';
import utils from '../utils/utils.js';
import boardUtils from '../utils/boardUtils.js';
import { withBoardContext } from '../contexts/BoardContext';
import { withThemeContext } from '../contexts/ThemeContext';

// Drag Vars
let startX = 0;
let startY = 0;
let startTranslateX = 0;
let startTranslateY = 0;
let diffX = 0;
let diffY = 0;
let isDoubleTap = false;

const GameBoard = (props) => {
  const [selectedHex, setCurrHex] = useState('');
  const [timestamp, setTimestamp] = useState(0);
  const [showTransition, setShowTransition] = useState(false);
  const { getTileKeys, getBoardData, setTile, boardSize, levelCode, wordIndex, numWords, findMaxUsedRadius } = props.board;
  const { addTile, removeTiles, addSwapTiles, setDraggingTile, setCurrentTile, changeTile, isGame, isSolved } = props; // Functions
  const { board, triggerTransition, endTransition, currentTile, isChallenge, playerCurrent, playerOne, lastTile, isCompleted, shouldAnimate, permClearTiles, suppressEndgameView, timerEnded } = props;
  const { isMarkStartTilesMode } = props.theme;
  const maxScale = 1.5;
  const translateX = useSharedValue(0);
  const translateY = useSharedValue(0);
  const scale = useSharedValue(1);
  const isZoomed = scale.value === maxScale;

  let tileKeys = getTileKeys();

  const finalWidth = props.width || theme.smallScreenWidth;
  const finalHeight = finalWidth * 5 / 4;

  // Grid Vars (sync with GameBoardTransition)
  let layoutSize = { x: 6.5, y: 6.5 };
  let layoutOrigin = { x: 0, y: 0 }
  if (Platform.OS !== 'web') {
    layoutSize = { x: 6.5, y: 6.5 };
    layoutOrigin = { x: 0, y: -12 }
  }
  let layoutObj = {
    size: layoutSize,
    origin: layoutOrigin,
    orientation: new Orientation(Math.sqrt(3.0), Math.sqrt(3.0) / 2.0, 0.0, 3.0 / 2.0, Math.sqrt(3.0) / 3.0, -1.0 / 3.0, 0.0, 2.0 / 3.0, 0.5)
  }

  if (showTransition &&
      triggerTransition) {
    let decodedStr = utils.atob(triggerTransition);
    let arr1 = decodedStr.split('$');
    let str = boardUtils._decompressEmpties(arr1[0]);
    let nextBoardData = boardUtils._decompress(str);
    let words = arr1[1].split(',');
    for (let i = 0; i < words.length; i++) {
      let word = words[i];
      if (word.length) {
        let arr2 = word.split('&');
        let startHex = boardUtils._decompressHex(arr2[0])
        let positionKey = Board.positionToString(startHex);
        nextBoardData[positionKey].wordIndex = i+1;
        nextBoardData[positionKey].color = arr2[1];
      }
    }
    // console.log("NEXT BOARD", nextBoardData)
    return <GameBoardTransition
             isGame={isGame}
             width={finalWidth}
             prevBoard={getBoardData()}
             nextBoard={nextBoardData}
             endTransition={endTransition}
            />
  }

  // Get selected tile
  let lastHex = utils.getPropertyByKeys(props.board, [`word${wordIndex}selected`]);
  if (lastHex) {
    // Make sure selected past initial
    if (lastHex.length > 0) lastHex = lastHex[lastHex.length - 1];
    else lastHex = null;
  }

  // For mobile touch positioning
  let phantomCenterHex = null;
  if (utils.isTouchDevice()) {
    phantomCenterHex = <Tile
      pointerEvents="none"
      key="phantom-center-hex"
      q={0}
      r={0}
      s={0}
      board={board}
      boardSize={6.5}
      isHidden={true}
      disabled={true}
      ratioX={10}
      ratioY={10}
    />
  }

  let startHexes = [];
  for (let i = 1; i <= numWords; i++) {
    let startHex = utils.getPropertyByKeys(props.board, [`word${i}`, 'startHex']);
    if (startHex) startHexes.push(startHex);
  }
  // console.log('startHexes', startHexes)


  // HexGrid width and height set the viewport size for the GameBoard. This DOES impact scaling of HexLayout
  return (
    <HexGrid
      width={finalWidth} height={finalHeight}
      isGame={isGame}
      isZoomed={isZoomed}
      layout={layoutObj}
      triggerTransition={triggerTransition}
      setShowTransition={setShowTransition}
      lastTileHex={board[lastTile]}
    >
      <HexLayout
        width={finalWidth} height={finalHeight}
        isGame={isGame}
        isZoomed={isZoomed}
        size={layoutSize} // Most direct control of hex size
        spacing={1.1} // Does change hex centerpoints
        origin={layoutOrigin} // Don't use this for positioning
        flat={false}
      >
        { phantomCenterHex }
        { tileKeys.map((hex, i) => {
            let currHex = props.board[hex];

            // Check if start tile
            let isStart = false;
            if (startHexes.length > 0 && (!isGame || isMarkStartTilesMode)) {
              for (let i = 0; i < numWords; i++) {
                if (startHexes[i] && HexUtils.equals(currHex, startHexes[i])) {
                  isStart = true;
                  break;
                }
              }
            }
            if (!currHex) return;

            let isSelected = lastHex && lastHex.equals(currHex);
            //let isSelected = selectedHex === hex;
            return (
              <Tile
                key={hex}
                isGame={props.isGame}

                q={currHex.q}
                r={currHex.r}
                s={currHex.s}
                letter={currHex.letter}
                isSelected={isSelected}
                isPerm={currHex.isPerm}
                wordIndex={currHex.wordIndex}

                board={board}
                boardSize={6.5}
                lastTileHex={board[lastTile]}
                playerCurrent={playerCurrent}
                timerEnded={timerEnded}

                addTile={addTile}
                removeTiles={removeTiles}
                addSwapTiles={addSwapTiles}
                setDraggingTile={setDraggingTile}
                setCurrentTile={setCurrentTile}
                setTile={setTile}
                isSolved={isSolved}
                isStart={isStart}

                shouldAnimate={shouldAnimate}
                disabled={false}
                ratioX={10}
                ratioY={10}

                isZoomed={isZoomed}
                width={finalWidth}
              />
            )
          })
        }
      </HexLayout>
    </HexGrid>
  );
}

export default withThemeContext(withBoardContext(GameBoard));

const styles = StyleSheet.create({

});
