import { useState, useEffect, useMemo } from 'react'
import { StyleSheet, Text, View, TextInput, Button, Dimensions } from 'react-native';
import Confetto from '../components/Confetto';

const Confetti = (props) => {
  const [delta, setDelta] = useState(16);
  const [timestamp, setTimestamp] = useState(0);
  const [start, setStart] = useState(0);
  let confettiObj = {};
  for (let i = 1; i <= 20; i++) {
    confettiObj[i] = true;
  }
  const [confetti, setConfetti] = useState(confettiObj);

  const removeConfetto = (index) => {

    let newConfetti = JSON.parse(JSON.stringify(confetti));
    newConfetti[index] = false;
    setConfetti(newConfetti);
    // console.log("REMOVE CONFETTI", index, confetti)

    // End confetti when done
    let shouldEnd = true;
    for (let key in newConfetti) {
      if (newConfetti[key]) {
        shouldEnd = false;
      }
    }
    if (shouldEnd && props.endConfetti) props.endConfetti();
  }

  useEffect(() => {
    let frame = undefined;
    var prev = undefined;
    const loop = (timestamp) => {
      var delta = prev ? timestamp - prev : 0;
      prev = timestamp;
      if (start === 0) {
        setStart(timestamp);
      } else {
        setTimestamp(timestamp - start);
      }

      // Cleanup
      frame = undefined;
    }
    requestAnimationFrame(loop);
    return
  });


  let width = 300;

  let confettiEls = [];
  for (let key in confetti) {
    if (confetti[key]) {
      confettiEls.push(
        <Confetto
          key={`confetto-${key}`}
          index={key}
          frame={timestamp}
          delta={delta}
          containerWidth={width}
          removeConfetto={removeConfetto}
        />
      )
    }
  }

  return (
    <View style={[styles.container, { width }]} pointerEvents="none">
      { confettiEls }
    </View>
  );
}

export default Confetti;

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    top: 0,
    left: '50%',
    marginLeft: -150,
    height: '100%',
    overflow: 'visible',
    zIndex: 9999,
  },
});
