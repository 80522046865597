import React, { Component } from 'react';
import Svg, { Path, G, Defs, Rect, ClipPath } from 'react-native-svg';

const KeyIcon = (props) => {
  const { size, color } = props;

  let finalSize = size || 32;
  let finalColor = color || '#232323';

  return (
    <Svg width={finalSize} height={finalSize} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <G clipPath="url(#clip0_2873_62907)">
        <Path fillRule="evenodd" clipRule="evenodd" d="M12.9075 20.3564L8.94895 20.3564L6.96967 23.7846L8.94895 27.2128L12.9075 27.2128L14.8868 23.7846L12.9075 20.3564ZM18.9282 23.7846L14.9282 16.8564H6.92822L2.92822 23.7846L6.92822 30.7128L14.9282 30.7128L18.9282 23.7846Z" fill={finalColor}/>
        <Path fillRule="evenodd" clipRule="evenodd" d="M23.8032 1.4845C24.6402 1.96775 24.927 3.03803 24.4437 3.87504L15.4437 19.4635C14.9605 20.3005 13.8902 20.5873 13.0532 20.104C12.2162 19.6208 11.9294 18.5505 12.4127 17.7135L21.4127 2.12504C21.8959 1.28803 22.9662 1.00125 23.8032 1.4845Z" fill={finalColor}/>
        <Path fillRule="evenodd" clipRule="evenodd" d="M19.4127 5.58915C19.8959 4.75214 20.9662 4.46536 21.8032 4.94861L28.7314 8.94861C29.5684 9.43186 29.8552 10.5021 29.372 11.3392C28.8887 12.1762 27.8184 12.4629 26.9814 11.9797L20.0532 7.9797C19.2162 7.49645 18.9294 6.42616 19.4127 5.58915Z" fill={finalColor}/>
        <Path fillRule="evenodd" clipRule="evenodd" d="M16.4127 10.7853C16.8959 9.94831 17.9662 9.66153 18.8032 10.1448L23.9994 13.1448C24.8364 13.628 25.1231 14.6983 24.6399 15.5353C24.1567 16.3723 23.0864 16.6591 22.2494 16.1759L17.0532 13.1759C16.2162 12.6926 15.9294 11.6223 16.4127 10.7853Z" fill={finalColor}/>
      </G>
      <Defs>
        <ClipPath id="clip0_2873_62907">
          <Rect width={32} height={32} fill="white"/>
        </ClipPath>
      </Defs>
    </Svg>
  );
}

export default KeyIcon;