import React from 'react';

import Svg, { Path } from 'react-native-svg';

const SettingsGear = (props) => {
  const { size, color } = props;

  let finalColor = color || 'black';
  let finalSize = size || 40;

  return (
    <Svg width={finalSize} height={finalSize} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <Path fillRule="evenodd" clipRule="evenodd" d="M21.0253 6.28291C20.3938 5.90586 19.6062 5.90586 18.9747 6.28291L8.97473 12.2534C8.37021 12.6143 8 13.2666 8 13.9706V26.0294C8 26.7334 8.37021 27.3856 8.97474 27.7466L18.9747 33.7171C19.6062 34.0941 20.3938 34.0941 21.0253 33.7171L31.0253 27.7466C31.6298 27.3856 32 26.7334 32 26.0294V13.9706C32 13.2666 31.6298 12.6143 31.0253 12.2534L21.0253 6.28291ZM20 24C22.2091 24 24 22.2091 24 20C24 17.7909 22.2091 16 20 16C17.7909 16 16 17.7909 16 20C16 22.2091 17.7909 24 20 24Z" fill={finalColor}/>
    </Svg>
  );
}

export default SettingsGear;



