import { useState } from 'react'
import { StyleSheet, Text, View, TextInput, Pressable, Platform } from 'react-native';
import {
  HexGrid,
  HexLayout,
  Tile, HexText,
  HexPattern,
  Hex,
  HexUtils} from '../HexGrid';
import Board from '../models/Board';
import { withThemeContext } from '../contexts/ThemeContext';

const ThemedText = (props) => {
  // Props
  // - style
  // - onPress
  const { themeStyle } = props.theme;

  let style = {
    fontFamily: 'Nunito-Regular',
    fontSize: 16,
    color: themeStyle.TEXT_COLOR,
  };
  if (Platform.OS === 'web') {
    style.userSelect = 'none';
    style.whiteSpace = 'normal';
  }
  let onPress = props.onPress || null;

  if (props.style) {
    if (props.style.length) {
      style = [style].concat(props.style);
    } else {
      style = Object.assign(style, props.style);
    }
  }

  return (
    <Text style={style} onPress={onPress}> { props.children } </Text>
  );
}

export default withThemeContext(ThemedText);
