import { StyleSheet, Text, Linking, View, TextInput, Image, TouchableOpacity, ScrollView, Switch, Platform, Pressable } from 'react-native';
import { useEffect, useState } from 'react';
import theme from '../styles/theme';
import Analytics from '../analytics/analytics';
import Utils from '../utils/utils';
import Tag from './Tag';
import Button from './Button';
import BackIcon from '../assets/icons/BackIcon';
import { withThemeContext } from '../contexts/ThemeContext';
import { playSound } from '../utils/soundUtils';

const CrossPromotion = (props) => {
  const { isDarkMode, themeStyle } = props.theme;
  const [promotionData, setPromotionData] = useState([]);

  // Set this product's ID: Honeycomb is 1
  const currentProductID = 1;

  const dataLookup = () => {
    // Fetch data from static file on Spiralburst Site
    fetch('https://spiralburst.com/staticData/crossPromotionData.json', { cache: "no-store" })
      .then(response => response.json())
      .then(data => {
        // Perform client-side check to verify data
        let verifiedData = [];

        let numEntries = Object.keys(data).length;
        if (numEntries > 0) {
          // use the index of current game to get order.
          let currentProductEntry = data[currentProductID];
          let order = currentProductEntry.order;

          const updateVerifiedData = (entry, i) => {
            // Ensure that all necessary values exist. We link to either a website OR split Android/iOS store links
            if (entry.title &&
              entry.imageSource &&
              entry.status &&
              (entry.webUrl || entry.iosUrl && entry.androidUrl)) {
              verifiedData[i] = {
                title: entry.title,
                imageSource: entry.imageSource,
                status: entry.status,
                webUrl: entry.webUrl || null,
                iosUrl: entry.iosUrl || null,
                androidUrl: entry.androidUrl || null,
              }
            }
          }

          if (order && order.length === numEntries - 1) {
            // If order exists and is valid, show in that order.
            for (let i = 0; i < order.length; i++) {
              let entryKey = order[i];
              let entry = data[entryKey];

              updateVerifiedData(entry, i);
            }
          } else {
            // If order does not exist or is invalid, default to showing ALL entries including this product
            for (let i = 0; i < numEntries; i++) {
              let entryKey = Object.keys(data)[i];
              let entry = data[entryKey];

              updateVerifiedData(entry, i);
            }
          }

        }
        // Set state variable to trigger re-render when complete
        setPromotionData(verifiedData);
      })
      .catch(error => {
        console.log(error);

        const fallbackPromotionData = [
          {
            "title": "Hexicon",
            "imageSource": require('../assets/images/crossPromotion/hexicon.png'),
            "status": "live",
            "webUrl": "https://hexiconapp.com",
            "iosUrl": "https://apps.apple.com/us/app/id1462065373",
            "androidUrl": "https://play.google.com/store/apps/details?id=com.hexicon",
          },
          // {
          //   "title": "Honeycomb",
          //   "imageSource": "https://spiralburst.com/img/honeycomb.png",
          //   "status": "beta",
          //   "webUrl": "https://honeycomb.goodgameshelf.com",
          //   "iosUrl": null,
          //   "androidUrl": null,
          // },
          {
            "title": "Crop Squares",
            "imageSource": require('../assets/images/crossPromotion/crop-squares.png'),
            "status": "alpha",
            "webUrl": "https://spiralburst-studio.itch.io/crop-squares",
            "iosUrl": null,
            "androidUrl": null,
          },
          {
            "title": "Maracaibo Digital",
            "imageSource": require('../assets/images/crossPromotion/maracaibo.png'),
            "status": "live",
            "webUrl": "https://maracaiboapp.com",
            "iosUrl": "https://apps.apple.com/us/app/maracaibo-digital/id1518834089",
            "androidUrl": "https://play.google.com/store/apps/details?id=com.scatterbrainstudios.maracaibo",
          },
          {
            "title": "Good Game Shelf",
            "imageSource": require('../assets/images/crossPromotion/goodgameshelf.png'),
            "status": "live",
            "webUrl": "https://goodgameshelf.com",
            "iosUrl": null,
            "androidUrl": null,
          }
        ];

        setPromotionData(fallbackPromotionData);
      });
  }

  useEffect(() => {
    // Run lookup on mount.
    dataLookup();

    // TODO - Show a badge somewhere if game(s) are updated.
  }, [])

  // Render each product
  let renderCard = (item) => {
    // Dynamically set image source based on live data (URLs) or fallback data (requires)
    let imageSource = (typeof item.imageSource === 'string') ? { uri: item.imageSource } : item.imageSource;

    // Dynamically set url path based on OS, default to website.
    let url = 'https://spiralburst.com';
    if (Platform.OS === 'ios' && item.iosUrl) url = item.iosUrl;
    else if (Platform.OS === 'android' && item.androidUrl) url = item.androidUrl;
    else if (item.webUrl) url = item.webUrl;

    // Empty array for platform tags
    let tags = [];

    // Show the platform tags, in relevant order based on device platform
    if (Platform.OS === 'android' || Platform.OS === 'web') {
      if (item.androidUrl) tags.push(<Tag key={'Android'} text={'Android'} />);
      if (item.iosUrl) tags.push(<Tag key={'iOS'} text={'iOS'} />);
    } else if (Platform.OS === 'ios') {
      if (item.iosUrl) tags.push(<Tag key={'iOS'} text={'iOS'} />);
      if (item.androidUrl) tags.push(<Tag key={'Android'} text={'Android'} />);
    }

    // Web tag always at end of list
    if (!(item.iosUrl || item.androidUrl) && item.webUrl) tags.push(<Tag key={'Web'} text={'Web'}/>);

    return (
      <TouchableOpacity
        key={item.title}
        onPress={() => {
          Linking.openURL(url);
          Analytics.logLinkClicked(url);
        }}
      >
        <View style={styles.card}>
          <Image style={styles.cardImage} source={imageSource}/>
          <View style={styles.tagsContainer}>
            { tags }
          </View>
        </View>
      </TouchableOpacity>
    )
  }

  let renderSocials = () => {
    return (
      <>
        <TouchableOpacity
          style={{ marginTop: 5, marginHorizontal: 10 }}
          onPress={() => {
            Linking.openURL('https://discord.gg/3y8GnH6BPp')
            Analytics.logLinkClicked('Discord');
          }}
        >
            <Image style={{ width: 44.4, height: 50 }} source={require('../assets/icons/discord-logo.png')}/>
        </TouchableOpacity>
        <TouchableOpacity
          style={{ marginHorizontal: 10 }}
          onPress={() => {
            Linking.openURL('https://twitter.com/SpiralburstDev')
            Analytics.logLinkClicked('Twitter');
          }}
          >
          <Image style={{ width: 40, height: 40 }} source={require('../assets/icons/twitter-logo.png')}/>
        </TouchableOpacity>
      </>
    )
  }

  let renderHeader = () => {
    let left = 10;
    if(Platform.OS === 'web' && theme.isLargeScreen) left = ((theme.SCREEN_WIDTH - theme.smallScreenWidth) / 2) + 10;

    let headerStyle = [styles.header, {
      backgroundColor: themeStyle.BG
    }]

    return (
      <View style={headerStyle}>
        <View style={{position: 'absolute', left: left}}>
          <TouchableOpacity
            onPress={() => {
              playSound('click');
              props.exit();
            }}
          >
            <BackIcon color={themeStyle.TEXT_COLOR} size={40}/>
          </TouchableOpacity>
        </View>
        <View style={{ position: 'absolute', right: 10, flexDirection: 'row', ...theme.flexCenter }}>
          { renderSocials() }
        </View>
      </View>
    )
  }

  // Create grid of product cards
  let cards = [];
  let numItems = promotionData.length;
  for (let i = 0; i < numItems; i++) {
    let item = promotionData[i];
    cards.push(renderCard(item));
  }

  let containerStyle = [styles.container, {
    backgroundColor: themeStyle.BG
  }];

  // TODO - Make tablet friendly?
  return (
    <View style={containerStyle}>
      { renderHeader() }
      <ScrollView contentContainerStyle={styles.cardContainer}>
        <TouchableOpacity
          style={{ width: '100%', ...theme.flexCenter }}
          onPress={() => {
            Linking.openURL('https://spiralburst.com')
            Analytics.logLinkClicked('spiralburst-site');
          }}
        >
          <View style={styles.spiralburstCard}>
            <Image style={{ width: 300, height: 57, resizeMode: 'contain' }} source={require('../assets/images/crossPromotion/spiralburst-logo.png')}/>
            <View style={styles.websiteTextContainer}>
              <Text style={styles.websiteText}>Official Website</Text>
            </View>
          </View>
        </TouchableOpacity>
        { cards }
      </ScrollView>
    </View>
  );
}

export default withThemeContext(CrossPromotion);

const styles = StyleSheet.create({
  container: {
    ...theme.fullAbsolute,
  },
  header: {
    height: 70,
    ...theme.column,
    justifyContent: 'center',
    marginBottom: 20,
  },
  cardContainer: {
    flex: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-evenly'
  },
  card: {
    ...theme.column,
    alignItems: 'center',
    width: theme.isSmallScreen ? theme.SCREEN_WIDTH / 2 - 30 : Math.min(theme.SCREEN_WIDTH / 2 - 30, 300),
    height: theme.isSmallScreen ? theme.SCREEN_WIDTH / 2 - 30 : Math.min(theme.SCREEN_WIDTH / 2 - 30, 300),
    minWidth: 150,
    minHeight: 150,
    marginBottom: 75,
  },
  spiralburstCard: {
    ...theme.column,
    alignItems: 'center',
    justifyContent: 'center',
    width: 340,
    height: 80,
    marginBottom: 30,
    backgroundColor: '#9876F9',
    borderRadius: 12,
  },
  websiteTextContainer: {
    position: 'absolute',
    bottom: 5,
    right: 40,
  },
  websiteText: {
    fontSize: 16,
    fontFamily: 'Nunito-Regular'
  },
  descriptionContainer: {
    ...theme.column,
  },
  titleText: {
    fontSize: 20,
  },
  descriptionText: {
    fontSize: 16
  },
  cardImage: {
    width: '100%',
    height: '100%',
    resizeMode: 'stretch',
    borderRadius: 6,
  },
  tagsContainer: {
    // position: 'absolute',
    // bottom: -18,
    marginTop: 10,
    flexDirection: 'row',
    alignItems: 'flex-end',
  }
});