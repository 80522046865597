import Animated, {
  useSharedValue,
  useAnimatedStyle,
  useAnimatedProps,
  useDerivedValue,
  SlideInRight,
  SlideOutDown,
  Layout,
  measure,
  interpolate,
  withTiming,
  withDelay,
  withSequence,
  Easing,
  runOnJS,
} from 'react-native-reanimated';
import { useState, useEffect, useMemo } from 'react'
import { StyleSheet, Text, View, Platform, PanResponder } from 'react-native';
import {
  HexLayout,
  HexText,
  HexPattern,
  Hex,
  HexUtils} from '../HexGrid';
import HexGridPack from '../HexGrid/HexGridPack';
import PackTile from '../HexGrid/Hexagon/PackTile';
import LockIcon from '../assets/icons/LockIcon';
import Point from '../HexGrid/models/Point';
import Orientation from '../HexGrid/models/Orientation';
import Board from '../models/Board';
import GameBoardTransition from './GameBoardTransition';
import theme from '../styles/theme';
import utils from '../utils/utils.js';
import boardUtils from '../utils/boardUtils.js';
import { withBoardContext } from '../contexts/BoardContext';
import { withThemeContext } from '../contexts/ThemeContext';

// Drag Vars
let startX = 0;
let startY = 0;
let startTranslateX = 0;
let startTranslateY = 0;
let diffX = 0;
let diffY = 0;
let isDoubleTap = false;

const PackBoard = (props) => {
  const [selectedHex, setCurrHex] = useState('');
  const [timestamp, setTimestamp] = useState(0);
  const [showTransition, setShowTransition] = useState(false);
  const { wordIndex, numWords } = props.board;
  const { goToPack, goToDaily, isGame, isSolved, isDaily, packKey, packProgress, color } = props; // Functions
  const { levels } = props;
  const { isMarkStartTilesMode, themeStyle } = props.theme;
  const maxScale = 1.5;
  const translateX = useSharedValue(0);
  const translateY = useSharedValue(0);
  const scale = useSharedValue(1);
  const isZoomed = scale.value === maxScale;


  const finalWidth = props.width || theme.smallScreenWidth;
  const finalHeight = finalWidth * 5 / 4;

  let lockEl = null;

  // Grid Vars
  let layoutSizeDefault = 7.4;
  let layoutSize = { x: layoutSizeDefault, y: layoutSizeDefault };
  let layoutOrigin = { x: 10.5, y: 12 } // web
  if (Platform.OS !== 'web') {
    layoutOrigin = { x: 10.5, y: -1 }
  }
  if (packKey === 'daily') {
    layoutSize = { x: 10.8, y: 10.8 } // web
    layoutOrigin = { x: 33.3, y: 45 } // web

    if (Platform.OS !== 'web') {
      layoutOrigin = { x: 33, y: 33 }
    } else {
      let lockRight = 72;
      let lockBottom = 20;
      let lockSize = 32;
      if (!theme.isLargeScreen) {
        lockRight = 65;
        lockSize = 30;
      }
      lockEl = (
        <View pointerEvents={'none'} style={{ position: 'absolute', bottom: lockBottom, right: lockRight }}>
          <LockIcon size={lockSize} color={themeStyle.TEXT_COLOR}/>
        </View>
      );
    }
  }
  let layoutObj = {
    size: layoutSize,
    origin: layoutOrigin,
    orientation: new Orientation(Math.sqrt(3.0), Math.sqrt(3.0) / 2.0, 0.0, 3.0 / 2.0, Math.sqrt(3.0) / 3.0, -1.0 / 3.0, 0.0, 2.0 / 3.0, 0.5)
  }


  // Get selected tile
  let lastHex = utils.getPropertyByKeys(props.board, [`word${wordIndex}selected`]);
  if (lastHex) {
    // Make sure selected past initial
    if (lastHex.length > 0) lastHex = lastHex[lastHex.length - 1];
    else lastHex = null;
  }

  let startHexes = [];
  for (let i = 1; i <= numWords; i++) {
    let startHex = utils.getPropertyByKeys(props.board, [`word${i}`, 'startHex']);
    if (startHex) startHexes.push(startHex);
  }
  // console.log('startHexes', startHexes)

  let rows = Math.ceil(levels.length / 5);
  let outerHeight = 56 * rows + 34;
  if (packKey === 'daily') outerHeight = 120;
  outerHeight = (finalWidth / theme.smallScreenWidth) * outerHeight;
  // HexGrid width and height set the viewport size for the GameBoard. This DOES impact scaling of HexLayout
  return (
    <View style={{ height: outerHeight, width: '100%', overflow: 'hidden' }}>
      <HexGridPack
        width={finalWidth} height={finalHeight}
        isGame={isGame}
        isZoomed={isZoomed}
        layout={layoutObj}
      >
        <HexLayout
          isGame={isGame}
          size={layoutSize} // Most direct control of hex size
          spacing={1.18} // Does change hex centerpoints
          origin={layoutOrigin} // Don't use this for positioning
          flat={false}
        >
          { levels.map((level, i) => {
              let currHex = HexUtils.getPackHexFromIndex(i);
              let isCompleted = utils.getPropertyByKeys(packProgress, [i]);
              return (
                <PackTile
                  key={level}
                  packKey={packKey}
                  isGame={props.isGame}
                  isSelected={!isCompleted}

                  q={currHex.q}
                  r={currHex.r}
                  s={currHex.s}
                  index={i+1}
                  letter={(i+1).toString()}

                  disabled={false}
                  ratioX={10}
                  ratioY={10}

                  goToPack={goToPack}
                  goToDaily={goToDaily}

                  isZoomed={isZoomed}
                  layoutSizeDefault={layoutSizeDefault}
                  layoutSizeX={layoutSize.x}
                  color={color}
                />
              )
            })
          }
        </HexLayout>
      </HexGridPack>
      { lockEl }
    </View>
  );
}

export default withThemeContext(withBoardContext(PackBoard));

const styles = StyleSheet.create({

});
