import Daily from './dailyData';

export class Tiers {
  // static tier_0 = {
  //   threshold: 0,
  //   packs: [
  //     'dev'
  //   ],
  //   color: '#000000'
  // }

  static tier_1 = {
    threshold: 0,
    packs: [
      'starter', // 10
    ],
    color: "#FFDE39"
  }

  static tier_2 = {
    threshold: 6,
    packs: [
      'animals', // 15
      'themed_1', // 15
    ],
    color: "#B0E049"
  }

  static tier_3 = {
    threshold: 24,
    packs: [
      'community_1', // 16
      'unthemed_1', // 10
    ],
    color: "#66AC2D"
  }

  static tier_4 = {
    threshold: 40,
    packs: [
      'books', // 5
      'alphabet_soup_1', // 26 - 13
    ],
    color: "#007007"
  }

  // WEB DOES NOT INCLUDE BELOW PACKS
  static tier_5 = {
    threshold: 58,
    packs: [
      'food_1', // 15
      'unthemed_2', // 10
    ],
    color: "#26AB59"
  }

  static tier_6 = {
    threshold: 73,
    packs: [
      'community_2', // 15
      'land', // 10
    ],
    color: "#55DF9F",
  }

  static tier_7 = {
    threshold: 88,
    packs: [
      'condiments', // 15
      'aquatic_nature', // 10
    ],
    color: "#6CE5D1",
  }

  static tier_8 = {
    threshold: 103,
    packs: [
      'community_3', // 30
      'themed_2', // 10
    ],
    color: "#1ACADC",
  }

  static tier_9 = {
    threshold: 127,
    packs: [
      'unthemed_3', // 15
      'ancient_civilizations', // 5,
    ],
    color: "#00A5EE",
  }

  static tier_10 = {
    threshold: 140,
    packs: [
      'food_2', // 17
      'community_4', // 15
    ],
    color: "#006CE1",
  }

  static tier_11 = {
    threshold: 159,
    packs: [
      'similar_spelling', // 10
      'baking', // 20
    ],
    color: "#5A4AE8",
  }

  static tier_12 = {
    threshold: 177,
    packs: [
      'unthemed_4', // 15
      'community_5', // 15
    ],
    color: "#6800BD",
  }

  static tier_13 = {
    threshold: 194,
    packs: [
      'grammar_and_vocab', // 10
      'community_6', // 15
    ],
    color: "#9144BF",
  }

  static tier_14 = {
    threshold: 209,
    packs: [
      'construction', // 10
      'unthemed_5', // 15
    ],
    color: "#B66FEC",
  }

  static tier_15 = {
    threshold: 224,
    packs: [
      'video_games', // 10
      'unthemed_6', // 15
    ],
    color: "#F07AFF",
  }

  static tier_16 = {
    threshold: 239,
    packs: [
      'unthemed_7', // 15
      'unthemed_8', // 15
    ],
    color: "#B72CA7",
  }

  static tier_17 = {
    threshold: 257,
    packs: [
      'unthemed_9', // 15
      'unthemed_10', // 15
    ],
    color: "#97005F",
  }

  static tier_18 = {
    threshold: 275,
    packs: [
      'unthemed_11', // 15
      'unthemed_12', // 15
    ],
    color: "#CE3957",
  }

  static tier_19 = {
    threshold: 293,
    packs: [
      'unthemed_13', // 30
    ],
    color: "#FF5252",
  }

  static tier_20 = {
    threshold: 311,
    packs: [
      'unthemed_14', // 30
    ],
    color: "#FF772B",
  }
}

export default class Packs {

  static daily = Daily.getDailyLevel(Daily.getDailyIndex());

  static dev = [
    "RFJNTk1ERU9FVElMTk9FVFJDVlJNQVNJWU9CSVlMQklOT05OSUxUTk9DTlROMUNOVFJFVk9SVFJUMU9DWUJJVFJFVk9SNUlZTEJJVFJFVk81TkkkZTQxJiNCRDk0RjQsNWNiJiMzOTk4MUEsYzIxJiNGRjg1ODcsMTJjJiNGRkNDMkIsYjNhJiM5NDlDRjQsMGExJiM2NTg2REEs",
  ]

  // 10 Levels - Easy - WEB OK
  static starter = [
    // S - Christian
    "QTJSMlQ0VDVTNzMkYjAyJiMzMEU3RTUs",

    // T - Felicia
    "UktDSTFUWTg0JDBhMSYjRUVBMTQ0LA==",

    // OT (Revised) - Christian
    "MVdPMUVPVDdONzYkMGExJiNGRkEzODQsYTAxJiM3NUU2REEs",

    // HB - Felicia
    "RTFFTk9IQjJZRTgwJDBhMSYjRkU3MTcxLGEwMSYjMTg5QUI0LA==",

    // WP - Felicia
    "UFJEMVVXTzdaWkxFNzMkMGExJiNGRkEzODQsMDAwJiNCNEY4Qzgs",

    // NTW - Felicia
    "REVTTk9SUjFFMldPMVdUSDc0JDEwYSYjRUNGODdGLDBiMiYjQkQ5NEY0LDFiMSYjRkU3MTcxLA==",

    // CMD - Felicia
    "T0dPVFVNNERBUzEyQ0U2NCQxMWImI0ZGQUVCQywzMGMmIzE4OUFCNCwwYTEmI0YxRkZDNCw=",

    // MYC - Felicia
    "U0VUSEVFM1JDS0xJTThVR09ZNjQkMWIxJiNDMDM4QjIzNCwzYWImI0I0RjhDOCwxMWImI0JEOTRGNCw=",

    // TBO - Felicia
    "VFJFVVRVTEMzQjJUT0NJUDEzUkhJUFUyMUQzMiQxYjEmI0ZGQUVCQywwYjImIzc1RTZEQSwyMGImI0VFQTE0NCw=",

    // TFPH - Christian (Survey Prompt 1)
    "U0tOSExBSFlDQkFGVFBJWU9ORTFPTTRPUkdONjEkMmFhJiNGRkFFQkMsMjBiJiM3NUU2REEsMmIwJiM5NDlDRjQsYTAxJiNGRkNDMkIs"
  ]

  // WEB OK
  // 15 Levels - Easy
  static themed_1 = [
    // FB
    "QjJMTDZMMUExMlVGTjIwSzQxJDAwMCYjRkZBMzg0LDNiYSYjOTQ5Q0Y0LA==",

    // AC
    "RUFUSTFSQzVPTlRBTjEwTk9JQU1JNTgkYTAxJiNGRkNDMkIsMGIyJiMwREMyQkIs",

    // SB
    "UzFHTkhUNUlJQU4xMU5CSTE4UkwyNUlMMTUkM2JhJiNCRDk0RjQsMDAwJiMxODlBQjQs",

    // CIR
    "Q0lETklZUjFHTzRBTkVENzMkMWEwJiNFMDdERTcsMDAwJiM3NUU2REEsYTAxJiNGRjg1ODcs",

    // PRG
    "VEdPVEFSTzZUT09QT1IxNFc1NyRhYTImI0ZGODU4NywwYTEmI0ZGQTM4NCxhMTAmIzc1RTZEQSw=",

    // WME
    "VzJNVEVJNEFTUlJPUzEwVDFFRU1EMThQWDM4JDEwYSYjRkZBMzg0LDFjMiYjNzRCRENCLDAwMCYjRkY4NTg3LA==",

    // SCP
    "VDJBRVNPNENLU0VPUFQ4SElDRU4xVEExNks0MCQwYTEmIzc0QkRDQiwyMGImI0ZGQUVCQyxhYTImI0ZCRTdDNiw=",

    // TCR
    "UlVBQ05SVDJSVDFJQUFUVTZSTzFQR0FCNjEkYTAxJiNGRjg1ODcsMTBhJiNGRkEzODQsMGExJiM3NUU2REEs",

    // FPBF
    "RU1GQlJMVTFSVUlUUklQMTJZR0Y2MSQyYzEmIzE4OUFCNCwxYjEmI0JEOTRGNCwxMGEmI0UwN0RFNywwMWEmI0ZGQUVCQyw=",

    // NSEW
    "Tk9TVE9XVDNIMVJOUkVIRVUxMUVUU1RTQTU1JDAwMCYjOTQ5Q0Y0LGIwMiYjNzVFNkRBLDAxYSYjODFCNjIyLDBhMSYjRkZBRUJDLA==",

    // SPPO
    "UExTUFBQRU9BS1JBUkU0VEVBTkFJUDExUjFHTzUxJDAxYSYjQjRGOEM4LDAwMCYjOTQ5Q0Y0LDEwYSYjRkY4NTg3LGIyMCYjODFCNjIyLA==",

    // LCAP
    "UFRPSEFFTEFDQU1FUlNOQVJHT0VSVFVSRTRIUDVQNTQkYTJhJiM3NUU2REEsYTAxJiNFQ0Y4N0YsMDAwJiM5NDlDRjQsYjIwJiNGRkEzODQs",

    // PNCM
    "VU9UQ05TQ09ORElNTEVVTUExSDRSSUFDTFNQUzE1RUwyTEEzOSQxYTAmIzBEQzJCQiwzYWImI0ZGQTM4NCwyMGImI0ZGQ0MyQiwyYzEmI0EwRTdFNSw=",

    // NGSWH - moved from starter
    "R1JIU0lERUFEWU9BV05OUk9FTzFMM0wxUlVDTEVBVEgxUk01NCQyYjAmIzk0OUNGNCwxMGEmI0ZGQ0MyQiwyYWEmIzc1RTZEQSwwMWEmIzBEQzJCQiwwMDAmI0ZGODU4Nyw=",

    // AABRH - moved from starter
    "TklFT0FJQ1JMTlZCSUFUSEVSVEVBSTFJM1ZBVUFWSUIyUDJOMTBRMU9SRTM2JDIwYiYjRkZDQzJCLDJiMCYjMTg5QUI0LDNjMCYjNzVFNkRBLDBiMiYjODFCNjIyLGIyMCYjRUNGODdGLA=="
  ]

  // 10 Levels - Moderate
  static themed_2 = [
    // TFPH - Christian (Survey Prompt 1)
    "U0tOSExBSFlDQkFGVFBJWU9ORTFPTTRPUkdONjEkMmFhJiNGRkFFQkMsMjBiJiM3NUU2REEsMmIwJiM5NDlDRjQsYTAxJiNGRkNDMkIs"
  ]

  // WEB OK
  // 10 Levels - Expert
  static unthemed_1 = [
    // I - Christian
    "SVROQ0VTTjVBTkRDRTc0JDAwMCYjRkZBMzg0LA==",

    // AU - Christian
    "Tk1ERUFNVTFBQ0NFVFNZMVBUTzFUSURFVDY2JDFhMCYjRkZBMzg0LGEwMSYjNzRCRENCLA==",

    // GACID - Christian
    "QUNMSU5UR0FET0xFR09SWUVSRUVFUzFMQTZURU4xTVY1RVQ0NyQwMDAmI0MwMzhCMjM0LDEwYSYjQTBFN0U1LGExMCYjRkZDQzJCLGEwMSYjODFCNjIyLGEyYSYjRkJFN0M2LA==",

    // OOOOO - Sam
    "T0ZPQlRPRlRFT1MxTE9OT09FRU5HTDVPR1BPT1BVTVJJMTNZUzJTUzM1JDAwMCYjRkU3MTcxLGFhMiYjOTQ5Q0Y0LDBhMSYjODFCNjIyLDAxYSYjMTg5QUI0LDBiMiYjQkQ5NEY0LA==",

    // TROPN - Christian
    "T09OUklSVFRBSVJPSVVPSVJST0lTMlBTVE9TMlNPU0lEMVMxN0EzNiQwMWEmIzc0QkRDQiwxMGEmI0ZGODU4NywxMmMmIzc1RTZEQSxiMjAmI0ZCRTdDNiwwYzMmIzgxQjYyMiw=",

    // PCMCF - Christian
    "UkNSQ09FRkVFQU5PUkFNU09NTFJBTDFURTJUUEFTMkVOVEwxM0VHN1kzMSQzYzAmI0ZGQTM4NCwxMGEmIzk0OUNGNCwxYjEmI0I0RjhDOCxhMTAmIzc0QkRDQixhMDEmI0ZGODU4Nyw=",

    // SSSSSS - Christian - Revised
    "RU5NTFNTRVRTRUVTQUVURU5VSUxFUk5DUkVOQ1JFU0lUUUVRQVNUMlNPSVQxT0lUNEVTRVMyVUUyMU5UNyRjYTQmIzk0OUNGNCwyMGImI0ZGQTM4NCxhYzQmI0I0RjhDOCwxYzImI0JEOTRGNCwxYTAmI0EwRTdFNSxhMmEmIzBEQzJCQiw=",

    // BPSOIB - Christian
    "QUlOVE5NTkRSR0RMQklFWVlBUDFPSVNFSVRBU1BIQVJZUlRMRTNCTEVPSTVUSUJUQU1JMVVOOE45U0VFMTAkMmFhJiNGRkEzODQsYjExJiM3NEJEQ0IsMDNjJiNGRkNDMkIsYjNhJiNGRjg1ODcsMmIwJiM5NDlDRjQsMWQzJiNGRkFFQkMs",

    // MIIMAH - Christian
    "VFJOVElITk1PRUlJQ0RBQVJJQUlNVUNORVRBTjFWU1NTREdORUNHUk9FUVVOMUlBTDFFUkFSQUVMM05PTjFOUzE2SUw3JGMzMCYjRkZBMzg0LDExYiYjNzRCRENCLGIyMCYjRkZDQzJCLDFiMSYjRkY4NTg3LGIzYSYjOTQ5Q0Y0LDBhMSYjRkZBRUJDLA==",

    // AHPSIR - Christian - Ridiculous difficulty
    "Q1BSTk9TSVJPSFRJQ05FQ1RERVJFVkVFTk9USU5NR0VHQUJJVERBTk1PRzRBVDFFUEFMT0VMMkRFMVlDWU4xM09QMUdZNVVOJDFkMyYjMTg5QUI0LDNiYSYjRkZDQzJCLDBhMSYjRkY4NTg3LDAyYiYjODFCNjIyLGExMCYjRkZBMzg0LGMzMCYjQkQ5NEY0LA=="
  ]

  static unthemed_2 = [
    // TFPH - Christian (Survey Prompt 1)
    "U0tOSExBSFlDQkFGVFBJWU9ORTFPTTRPUkdONjEkMmFhJiNGRkFFQkMsMjBiJiM3NUU2REEsMmIwJiM5NDlDRjQsYTAxJiNGRkNDMkIs"
  ]

  // WEB OK
  // 26 Levels - Expert
  static alphabet_soup_1 = [

    // A
    "QUFSVFNEVlVFTktSREVBUkFFQUVHTVRFREdPVUlBQTFMSU5BVDdZMU9MVDFUUDM5JDJjMSYjRkY4NTg3LDFjMiYjOTQ5Q0Y0LGFhMiYjRkZBRUJDLDAwMCYjRkJFN0M2LGIxMSYjODFCNjIyLA==",

    // B
    "U0NJTEFCVEVJRkFSRVNCQUlPQjRFTkVCVDFJTklCRU5UUzE3T00yRUQzMSQwYTEmI0ZGODU4NywxYjEmIzk0OUNGNCxiMTEmI0ZGQUVCQywzYWImI0ZCRTdDNixhYjMmIzgxQjYyMiw=",

    // C
    "VEVDT0FPUkFHT1JZTkNDUFJFQ1JUQTVURU9SUkFZU0FMT1RTMTBNUE81RUhQMzAkMWIxJiNGRjg1ODcsYjExJiNGRkEzODQsMmIwJiM4MUI2MjIsMDFhJiM3NEJEQ0Is",

    // D
    "RERJSUVUQUVTVFBBTFJHTkVERFBESTRFTEdOQURBR0xFUDFPVFQyM1VFMjUkMDAwJiNGQkU3QzYsYjExJiMwREMyQkIsYjNhJiNCNEY4QzgsMGMzJiNFQ0Y4N0Ys",

    // E
    "WUdFUkdHSUlSUFJFVEVFT09FUzhOVE9MRVNVMTRFWU1HR0FOVDIzUEwxMCQyYjAmI0EwRTdFNSwwYzMmIzBEQzJCQiw0Y2EmIzk0OUNGNCwxYjEmIzE4OUFCNCw=",

    // F
    "T0ZMVVVSRUlSVU9MRk9OUkVTUzRGRUxGTFRERVNUREMxSDlJQ0kxQVRJT05ORTM0JDJhYSYjQkQ5NEY0LDNhYiYjQTBFN0U1LGExMCYjRkZBMzg0LDEyYyYjRUNGODdGLA==",

    // G
    "Uk5BR0dHTkxVTjFMQUFSR0FURVlMVDFBTDZOQVVPRzFFU1MxOE9ETjMwJDFhMCYjRkZBRUJDLDBhMSYjNzVFNkRBLGMxMiYjRTA3REU3LDEwYSYjRkZDQzJCLA==",

    // H
    "WUhFTEFITVJTU0lPSFJFUkVOSENJVDRZQVNIRUFTTEFFQTE0WVJSWU9IMjVPTjckY2E0JiM4MUI2MjIsYjExJiNGRkFFQkMsMmMxJiNBMEU3RTUsMGExJiNFQ0Y4N0YsMmFhJiNGRkEzODQsYTEwJiM5NDlDRjQs",

    // I
    "SUlSSU5SSUVUQUROVUlSUklJRDNOT0lUQUdBSURSTVUxM0VUM0EzOCQwMDAmI0ZCRTdDNiwxMGEmIzBEQzJCQixhMDEmI0I0RjhDOCxhYTImI0VDRjg3Riw=",

    // J
    "QVlKQk1CQVJBTEFKTUFKT0pURTFMQUtDQTRFRVJFUzU3JDFiMSYjQTBFN0U1LDIwYiYjRkY4NTg3LDAxYSYjOTQ5Q0Y0LGFhMiYjRkZDQzJCLA==",

    // K
    "TktORVRJS0RFM1JSRUtLSUU3QUcyRE5JTkRSRDU0JGFhMiYjRkY4NTg3LGEwMSYjRkZDQzJCLDBiMiYjQTBFN0U1LA==",

    // L
    "TE9OQURFT0VMQ1RJT1VTVVExTTFMSTFFTjFVT0lDQTE2UzQzJGEyYSYjRUNGODdGLDAwMCYjRkZBMzg0LGIzYSYjRkY4NTg3LA==",

    // M
    "RUlETURNTU9PTEVJSU1FTllUQ09NRFJFTjFDRVJDRUFSTklSRTFST0FNQTE1TUVTMzAkYTAxJiNFQ0Y4N0YsMGExJiM3NUU2REEsMmIwJiM5NDlDRjQsMTBhJiNFMDdERTcsZDEzJiNCRDk0RjQsYjNhJiNGRkEzODQs",
  ]

  static food_1 = [
    // TFPH - Christian (Survey Prompt 1)
    "U0tOSExBSFlDQkFGVFBJWU9ORTFPTTRPUkdONjEkMmFhJiNGRkFFQkMsMjBiJiM3NUU2REEsMmIwJiM5NDlDRjQsYTAxJiNGRkNDMkIs"
  ];

  // 17 Puzzles
  static food_2 = [
    // TFPH - Christian (Survey Prompt 1)
    "U0tOSExBSFlDQkFGVFBJWU9ORTFPTTRPUkdONjEkMmFhJiNGRkFFQkMsMjBiJiM3NUU2REEsMmIwJiM5NDlDRjQsYTAxJiNGRkNDMkIs"
  ]

  // WEB OK
  // Community Made Levels - 30 puzzles mostly from Crosscord, Spiralburst discord
  static community_1 = [
    // Dianyx - heads shoulders
    "SFVPSFNFTDJUT0VTREFLUkVEMTBTMU5TUzIwRUUzNSQwMDAmIzgxQjYyMiwxYTAmIzc1RTZEQSwwYjImIzk0OUNGNCwwMmImI0JEOTRGNCw=",

    // Gas elements - Sheikhspeare
    "R0hFTllFTkRSTk5JWE9HM1kxT0dFRVJUT0wxNE5HTzFIQTQzJDNhYiYjQjRGOEM4LDEwYSYjMERDMkJCLDRhYyYjQkQ5NEY0LGExMCYjRkZBMzg0LA==",

    // Jackofallspades98 - temple
    "U09SRVRRTkhVQ01MRUVVU1lBTUdDSDJQMTBHTzFVRTUxJDBiMiYjRkZDQzJCLGIxMSYjQkQ5NEY0LGIzYSYjRkY4NTg3LDFhMCYjNzVFNkRBLA==",

    // Plurality - Sheikhspeare
    "RTFOQVVEUjNUVU1RVUFONllUSUFVU1FOVU0xM0xSTFBNRVQzOCQzYzAmI0EwRTdFNSwyYjAmIzE4OUFCNCwzZDEmIzc0QkRDQiw0ZDAmIzk0OUNGNCw=",

    // Ricky - so many E, sort of anagram
    "RUtFRUVFRUhFRUVCUEVFRUVFV1JFRU1MRDRSVzJQUkYyREVFMjNSMjYkMjBiJiNCNEY4QzgsYzEyJiM4MUI2MjIsYmEzJiM5NDlDRjQsYzMwJiM3NEJEQ0Is",

    // Dianyx - nanometer, centigrade
    "S0dJTkFBUkRPTE1PTklORU1JTEVSRVRFRUFSRzFURUNERVBMTUlNQVRFQVNTMURFMTFJQzdCMjIkMDAwJiNGRkNDMkIsMTBhJiMwREMyQkIsYjIwJiM3NEJEQ0IsYzIxJiNCNEY4QzgsMGMzJiNGRkEzODQsMjNlJiNGRjg1ODcs",

    // C - catatonic/corporation - Sheikhspeare
    "T09QUlJQUlJURVJPT09UVEVUQU9VQURUU1BDQ05PQUFDQUxJT1JSR0NBTDRDSTEwTkMzMCQzYmEmI0ZGODU4NywzYWImIzc0QkRDQixkMzEmI0UwN0RFNyxhNGMmI0VDRjg3RixhYjMmIzgxQjYyMiw=",

    // Diaynx - cool ring shape, medium hard
    "MUZBVFRTQVRJQU5ORU5JRVBPTkVHRENST0lUU09JVERMT1JSSDFUWTJIVFMySU1MQUFSTzFNTzFQSEk2Q0k3Tk9JVE1MWTdDJDEwYSYjRkZDQzJCLDFhMCYjRkZBRUJDLDBhMSYjRkZBMzg0LDAxYSYjNzVFNkRBLGExMCYjNzRCRENCLGEwMSYjQjRGOEM4LA==",

    // Z overload! - hard - Brian Thomas
    "Wk1aQVpaT0lJWlpaWkxBQVpBWlpQQVJaSUJJQUxFUjJSRFpHMVpBRDNMMlAxMVVCMkRSMjYkMzBjJiMwREMyQkIsZDMxJiM3NEJEQ0IsYzIxJiNGQkU3QzYsYTEwJiM4MUI2MjIsNGJiJiNFQ0Y4N0YsYjNhJiNCNEY4Qzgs",

    // Q overload, med - ada nicole
    "UVJRQVFVVVVVUVVTUk5BSVFWSVRPSVNJRVRFVURTQ1NFVVFFUjVFNFJCMktUN1MzMCQwMDAmI0ZGODU4Nyw0Y2EmI0I0RjhDOCxjMzAmIzk0OUNGNCxhYTImI0ZCRTdDNiwwMmImI0ZGQUVCQyxjMDMmI0EwRTdFNSw=",

    // FRISCOSEVENTEEN(15) - anagram - TOMALBYN
    "VE9PTk9NTjNPTVRPWU9NT1k5Qk9MVEFNVFQxNE9NQk9UVE8xTzE4VFRPT1RBVDckMGU1JiNFQ0Y4N0YsYmI0JiNBMEU3RTUsYWEyJiM5NDlDRjQsMWQzJiM3NUU2REEsMWMyJiNGRkNDMkIsMjBiJiMxODlBQjQs",

    // Ricky: ROTAS columns anagram
    "VEFBVE9PVFNTUzFUT1JSUk9UQTZUT080Uk9UQVMxOFJPMlMzMSQwYjImIzgxQjYyMixhYjMmI0ZGQUVCQywwMDAmI0EwRTdFNSxkMjImIzc0QkRDQiwyYjAmI0VDRjg3RixhMTAmI0JEOTRGNCw=",

    // Dianyx - analog anagram
    "QU1JTEdMQUFBT05PQUxMQUdBTTFUSTFNTk1NT1RPTE5BMTBBRzFBTUlOR0dPMjNHTklHRzEwJGEwMSYjQjRGOEM4LDEyYyYjMTg5QUI0LDFhMCYjRkZDQzJCLDRiYiYjOTQ5Q0Y0LGFiMyYjRkZBMzg0LDFiMSYjRTA3REU3LA==",


    // Diaynx - DEVIL anagram
    "RERFRElWSUVERFZEREVMTExMRDFFSUVESUxFTFZFRVZFMkxJMkw2RUQyRDQwJGIwMiYjOTQ5Q0Y0LDAwMCYjNzVFNkRBLGMxMiYjNzRCRENCLGIzYSYjRkY4NTg3LDIwYiYjRkZBRUJDLGFhMiYjRTA3REU3LA==",

    // Elements - dysprosium - dianyx
    "T0lORVhFTlVCVFlOSUNFTklET09JUkVUNUtMWUlOWURNMVNJVU0xME5PTVRBMVNQUjMwJGMxMiYjMTg5QUI0LGExMCYjNzRCRENCLGNhNCYjRTA3REU3LDIwYiYjRkZDQzJCLDExYiYjNzVFNkRBLDFhMCYjQkQ5NEY0LA==",
  ];

  // 15
  static community_2 = [
    // TFPH - Christian (Survey Prompt 1)
    "U0tOSExBSFlDQkFGVFBJWU9ORTFPTTRPUkdONjEkMmFhJiNGRkFFQkMsMjBiJiM3NUU2REEsMmIwJiM5NDlDRjQsYTAxJiNGRkNDMkIs"
  ]

  static community_3 = [
    // TFPH - Christian (Survey Prompt 1)
    "U0tOSExBSFlDQkFGVFBJWU9ORTFPTTRPUkdONjEkMmFhJiNGRkFFQkMsMjBiJiM3NUU2REEsMmIwJiM5NDlDRjQsYTAxJiNGRkNDMkIs"
  ];

  static community_4 = [
    // TFPH - Christian (Survey Prompt 1)
    "U0tOSExBSFlDQkFGVFBJWU9ORTFPTTRPUkdONjEkMmFhJiNGRkFFQkMsMjBiJiM3NUU2REEsMmIwJiM5NDlDRjQsYTAxJiNGRkNDMkIs"
  ];

  static community_5 = [
    // TFPH - Christian (Survey Prompt 1)
    "U0tOSExBSFlDQkFGVFBJWU9ORTFPTTRPUkdONjEkMmFhJiNGRkFFQkMsMjBiJiM3NUU2REEsMmIwJiM5NDlDRjQsYTAxJiNGRkNDMkIs"
  ];

  static community_6 = [
    // TFPH - Christian (Survey Prompt 1)
    "U0tOSExBSFlDQkFGVFBJWU9ORTFPTTRPUkdONjEkMmFhJiNGRkFFQkMsMjBiJiM3NUU2REEsMmIwJiM5NDlDRjQsYTAxJiNGRkNDMkIs"
  ]

  static similar_spelling = [
    // TFPH - Christian (Survey Prompt 1)
    "U0tOSExBSFlDQkFGVFBJWU9ORTFPTTRPUkdONjEkMmFhJiNGRkFFQkMsMjBiJiM3NUU2REEsMmIwJiM5NDlDRjQsYTAxJiNGRkNDMkIs"
  ];

  static land = [
    // TFPH - Christian (Survey Prompt 1)
    "U0tOSExBSFlDQkFGVFBJWU9ORTFPTTRPUkdONjEkMmFhJiNGRkFFQkMsMjBiJiM3NUU2REEsMmIwJiM5NDlDRjQsYTAxJiNGRkNDMkIs"
  ];

  static aquatic_nature = [
    // TFPH - Christian (Survey Prompt 1)
    "U0tOSExBSFlDQkFGVFBJWU9ORTFPTTRPUkdONjEkMmFhJiNGRkFFQkMsMjBiJiM3NUU2REEsMmIwJiM5NDlDRjQsYTAxJiNGRkNDMkIs"
  ];

  static video_games = [
    // TFPH - Christian (Survey Prompt 1)
    "U0tOSExBSFlDQkFGVFBJWU9ORTFPTTRPUkdONjEkMmFhJiNGRkFFQkMsMjBiJiM3NUU2REEsMmIwJiM5NDlDRjQsYTAxJiNGRkNDMkIs"
  ];

  // WEB OK
  static books = [
    "RFJERFJJVUFFRERERUkyTU1FSFNFUkVFSU5MN0FURVJJSVBTU1QyWTE3QTI1JDAwMCYjRkZBMzg0LDAxYSYjQjM3QTRDLDAyYiYjMzBFN0U1LDExYiYjQ0M5OTY2LDIwYiYjOTQ5Q0Y0LDEwYSYjMzk5ODFBLA==",
    "T1NXUklUUklQRE9TTlZQT0hCTTROQUVDSU9UTk9SQTFFQzE0RVIzOCQyMGImI0JEOTRGNCxhMTAmI0NDOTk2NiwxMWImI0IzN0E0QyxiMDImI0I0RjhDOCwyYjAmI0VDRjg3Riw=",
    "SVJXRFdORVJBMURSQVpJSEdMRjExT0JCSVRGNTUkYTEwJiNGRkEzODQsYTAxJiM5QzYxMzcsMWEwJiNDQzk5NjYsMTBhJiMzMEU3RTUsMGIyJiNFMDdERTcs",
    "UERMRVRBUlNSMUFZTFNNQUdSU1dPNVRTQUNJQkRFSFNXMTRDRTVSRVMzMCQxYjEmI0ZGQ0MyQiwwYzMmIzgxQjYyMixjMTImI0VDRjg3RixhMDEmIzMwRTdFNSwyYzEmI0ZGODU4NyxiMjAmI0JEOTRGNCw=",
    "TklSRElBV0VET0VTVFVOUkJFRk9MV1lUM0lOU0RJU0IxWUcyT003T0RFR1JFVDM2JGEzYiYjQjRGOEM4LGEyYSYjMzBFN0U1LDExYiYjNjU4NkRBLDJiMCYjRkZBRUJDLGEwMSYjQjM3QTRDLA=="
  ];

  static ancient_civilizations = [
    // TFPH - Christian (Survey Prompt 1)
    "U0tOSExBSFlDQkFGVFBJWU9ORTFPTTRPUkdONjEkMmFhJiNGRkFFQkMsMjBiJiM3NUU2REEsMmIwJiM5NDlDRjQsYTAxJiNGRkNDMkIs"
  ];

  // WEB OK
  static animals = [
    "VE9HMUFGUkVHRzREUE9MRTcyJDBhMSYjRkZBMzg0LDAwMCYjODFCNjIyLGIyMCYjQjRGOEM4LA==",
    "SUgxQ0tQQzVFTklDT1cxMkc2MCQwYTEmI0NDOTk2NiwwYjImIzlDNjEzNyxhMDEmI0ZGQ0MyQiw=",
    "VDFBUkVONUxJR0RUMTBCRVRST00xNVJTQUVSTzIyTUhFU1UxMSQ1ZTAmIzgxQjYyMiwyYzEmI0JEOTRGNCwwYzMmI0IzN0E0QywxMGEmI0ZGODU4NywyYjAmIzBEQzJCQiw=",
    "TU1TU0xBUEFUQzJTRUxJVFJFSUJFU04xMVNNMUlITUFJMTZOQTNQMjYkMTNkJiNGRkNDMkIsMDRkJiNGQkU3QzYsYjAyJiNCRDk0RjQsYzIxJiNFQ0Y4N0Ys",
    "SUVDTk9SSExIQUFWUktQMUNDTjFMSTJSREE2VVBJQTU0JDFiMSYjMTg5QUI0LDAyYiYjRkZDQzJCLGIwMiYjODFCNjIyLA==",
    "TVBJSUhPUjJHQkJOQ0VMQTZOT09PUlVNVUdOMTNCQUJOQVQzOSQwYjImI0ZGQ0MyQiw0Y2EmI0NDOTk2NiwwMmImIzgxQjYyMiwyYjAmIzY1ODZEQSwwYTEmI0ZGQTM4NCw=",
    "VFlSQUhPRFBOQVZUVExFTkFFTkNBUkVQSTVTTkFLUkNPMTlBMUJPMzEkMDJiJiMzOTk4MUEsYzEyJiM2NTg2REEsMDFhJiNGRkNDMkIsYjIwJiM5QzYxMzcsMTFiJiNGRkEzODQs",
    "Q0xESEFUT0pFQVI0RUhDVUdBT1A5RVJBR0EyMlJVMzAkYTEwJiNDQzk5NjYsYjIwJiNGRkEzODQsYjAyJiMxODlBQjQsMDAwJiNGRkNDMkIs",
    "T1dDTEFDREdFTkExRkpGS0FMTzFOSTdPWDYwJDJiMCYjQ0M5OTY2LGExMCYjMTg5QUI0LDAxYSYjQjM3QTRDLDFiMSYjRkY4NTg3LGEwMSYjOUM2MTM3LA==",
    "VDNIQkk1QVlOVUIxMFJFTlJBQjU4JDBhMSYjRkY4NTg3LDFjMiYjMzk5ODFBLDFhMCYjODFCNjIyLA==",
    "RVBQTlNOSTNBVFNJRzhFTDFSVFRPMTdVVFIyNU8xNSQ0Y2EmIzM5OTgxQSwzYmEmI0IzN0E0QywyYWEmI0VDRjg3Riw=",
    "TjJBT05SNUxDRElVMTBUVVJOQUw1OCQwYTEmIzY1ODZEQSwxYjEmI0ZGODU4Nyw=",
    "UDFPU09BQTRTT1BLVUw5VU1PU1MxTTU4JDAwMCYjRkY4NTg3LDJhYSYjQ0M5OTY2LDFiMSYjQjRGOEM4LA==",
    "TldZRVJPTkJBUkJBRExJSFMxTzJOREVSUkVMVFQ2MSRhYTImI0ZCRTdDNiwwYjImI0JEOTRGNCwyYjAmI0ZGQTM4NCwxMWImI0IzN0E0QyxiMjAmI0ZGODU4Nyw=",
    "VURES0NEVTFLQ1UxS0NES0MxMEVTVUcxOU9PMzkkMWIxJiNGRkNDMkIsMGExJiNCNEY4QzgsYTEwJiMzOTk4MUEsMDFhJiNGRjg1ODcsMWMyJiNFMDdERTcs"
  ];

  static baking = [
    // TFPH - Christian (Survey Prompt 1)
    "U0tOSExBSFlDQkFGVFBJWU9ORTFPTTRPUkdONjEkMmFhJiNGRkFFQkMsMjBiJiM3NUU2REEsMmIwJiM5NDlDRjQsYTAxJiNGRkNDMkIs"
  ];

  static grammar_and_vocab = [
    // TFPH - Christian (Survey Prompt 1)
    "U0tOSExBSFlDQkFGVFBJWU9ORTFPTTRPUkdONjEkMmFhJiNGRkFFQkMsMjBiJiM3NUU2REEsMmIwJiM5NDlDRjQsYTAxJiNGRkNDMkIs"
  ];

  static condiments = [
    // TFPH - Christian (Survey Prompt 1)
    "U0tOSExBSFlDQkFGVFBJWU9ORTFPTTRPUkdONjEkMmFhJiNGRkFFQkMsMjBiJiM3NUU2REEsMmIwJiM5NDlDRjQsYTAxJiNGRkNDMkIs"
  ];

  static construction = [
    // TFPH - Christian (Survey Prompt 1)
    "U0tOSExBSFlDQkFGVFBJWU9ORTFPTTRPUkdONjEkMmFhJiNGRkFFQkMsMjBiJiM3NUU2REEsMmIwJiM5NDlDRjQsYTAxJiNGRkNDMkIs"
  ];

  // Easy only
  static unthemed_3 = [
    // TFPH - Christian (Survey Prompt 1)
    "U0tOSExBSFlDQkFGVFBJWU9ORTFPTTRPUkdONjEkMmFhJiNGRkFFQkMsMjBiJiM3NUU2REEsMmIwJiM5NDlDRjQsYTAxJiNGRkNDMkIs"
  ]

  // Easy + 1/2 Med
  static unthemed_4 = [
    // TFPH - Christian (Survey Prompt 1)
    "U0tOSExBSFlDQkFGVFBJWU9ORTFPTTRPUkdONjEkMmFhJiNGRkFFQkMsMjBiJiM3NUU2REEsMmIwJiM5NDlDRjQsYTAxJiNGRkNDMkIs"
  ];

  // Med Only
  static unthemed_5 = [
    // TFPH - Christian (Survey Prompt 1)
    "U0tOSExBSFlDQkFGVFBJWU9ORTFPTTRPUkdONjEkMmFhJiNGRkFFQkMsMjBiJiM3NUU2REEsMmIwJiM5NDlDRjQsYTAxJiNGRkNDMkIs"
  ];

  // 1/2 Med+Hard
  static unthemed_6 = [
    // TFPH - Christian (Survey Prompt 1)
    "U0tOSExBSFlDQkFGVFBJWU9ORTFPTTRPUkdONjEkMmFhJiNGRkFFQkMsMjBiJiM3NUU2REEsMmIwJiM5NDlDRjQsYTAxJiNGRkNDMkIs"
  ]

  // 1/2 Med-Hard
  static unthemed_7 = [
    // TFPH - Christian (Survey Prompt 1)
    "U0tOSExBSFlDQkFGVFBJWU9ORTFPTTRPUkdONjEkMmFhJiNGRkFFQkMsMjBiJiM3NUU2REEsMmIwJiM5NDlDRjQsYTAxJiNGRkNDMkIs"
  ]

  // easy + 1/2 med removed
  static unthemed_8 = [
    // TFPH - Christian (Survey Prompt 1)
    "U0tOSExBSFlDQkFGVFBJWU9ORTFPTTRPUkdONjEkMmFhJiNGRkFFQkMsMjBiJiM3NUU2REEsMmIwJiM5NDlDRjQsYTAxJiNGRkNDMkIs"
  ];

  // Easy removed + 5 hard removed
  static unthemed_9 = [
    // TFPH - Christian (Survey Prompt 1)
    "U0tOSExBSFlDQkFGVFBJWU9ORTFPTTRPUkdONjEkMmFhJiNGRkFFQkMsMjBiJiM3NUU2REEsMmIwJiM5NDlDRjQsYTAxJiNGRkNDMkIs"
  ];

  // Easy + Med + Hard
  static unthemed_10 = [
    // TFPH - Christian (Survey Prompt 1)
    "U0tOSExBSFlDQkFGVFBJWU9ORTFPTTRPUkdONjEkMmFhJiNGRkFFQkMsMjBiJiM3NUU2REEsMmIwJiM5NDlDRjQsYTAxJiNGRkNDMkIs"
  ]

  // Medium + Hard
  static unthemed_11 = [
    // TFPH - Christian (Survey Prompt 1)
    "U0tOSExBSFlDQkFGVFBJWU9ORTFPTTRPUkdONjEkMmFhJiNGRkFFQkMsMjBiJiM3NUU2REEsMmIwJiM5NDlDRjQsYTAxJiNGRkNDMkIs"
  ];

  // 15 Hard
  static unthemed_12 = [
    // TFPH - Christian (Survey Prompt 1)
    "U0tOSExBSFlDQkFGVFBJWU9ORTFPTTRPUkdONjEkMmFhJiNGRkFFQkMsMjBiJiM3NUU2REEsMmIwJiM5NDlDRjQsYTAxJiNGRkNDMkIs"
  ]

  // Full Easy + Med + Hard
  static unthemed_13 = [
    // TFPH - Christian (Survey Prompt 1)
    "U0tOSExBSFlDQkFGVFBJWU9ORTFPTTRPUkdONjEkMmFhJiNGRkFFQkMsMjBiJiM3NUU2REEsMmIwJiM5NDlDRjQsYTAxJiNGRkNDMkIs"
  ];

  // Full Easy Med + Hard
  static unthemed_14 = [
    // TFPH - Christian (Survey Prompt 1)
    "U0tOSExBSFlDQkFGVFBJWU9ORTFPTTRPUkdONjEkMmFhJiNGRkFFQkMsMjBiJiM3NUU2REEsMmIwJiM5NDlDRjQsYTAxJiNGRkNDMkIs"
  ];

  // // Katelyn Δ — BSNWFS - story
    // 'Vk9SRU5FU0ZTRU9OUkIzT0UxQUwxV1I0QUJZU0g1NyQyYjAmI0UwN0RFNyxhYjMmI0VDRjg3RiwxYTAmI0ZGODU4NywxMmMmI0JEOTRGNCxiMjAmIzE4OUFCNCxhMmEmI0I0RjhDOCw=',


    // // dianyx — TTNNTT - "What are you, seven?"
    // 'MUkxVFQxRVQzSU5TSTFTVFBUNUVQSUVUM0lJTkk5U0lUVEk1VFRFU0kxMlQxNVQxJGIwMiYjQTBFN0U1LGUzMiYjQkQ5NEY0LDJhYSYjNzRCRENCLGMxMiYjRkZBMzg0LDViYyYjMERDMkJCLDJjMSYjRkJFN0M2LA==',

    // // dianyx — DPOPVS - The immaturity of a teenager
  // 'RExPRU9HUklEUlBTUFNBWUhQT0dPTjFBTlZFTklFU01JTkdBUjdJR0FMQVVYUzM5JGEyYSYjRkZBRUJDLDExYiYjRkZBMzg0LGIxMSYjNzVFNkRBLDJhYSYjRkJFN0M2LDMwYyYjQTBFN0U1LDJkMiYjQjRGOEM4LA==',

  // static portals = [
  //   // TCB - example only, built before mechanic implemented
  // 'TUVCUExUVTFUMU9BVFJFSE9FUzdIQ1VIQzYwJGEyYSYjQjRGOEM4LDAxYSYjRkZDQzJCLDFjMiYjMTg5QUI0LDBhMSYjQTBFN0U1'
  // ]
}


// CONVERSION FROM OLD ORIENTATION
// for (let key in Packs) {
//   if (key !== "daily") {
//     let pack = Packs[key];
//     let newPack = [];
//     for (var i = 0; i < pack.length; i++) {
//       newPack.push(BoardUtils._convertOrientation(pack[i]));
//     }
//     console.log(key, newPack);
//   }
// }
// console.log(BoardUtils._convertOrientation("QkNJUklBTUNTUk9UQU5UTE5JT0NDSUxPVFNDSVJPWUlMQUJNT09TRVkxVE5BTklCTU9DU0MxUk9JVE5FTkNQWDVFVFRJTVJFVE5JNVRBTklCTU8kZTUwJiNDQzk5NjYsM2UyJiMwREMyQkIsYjRiJiNGRkNDMkIsM2QxJiM4MUI2MjIsYzMwJiMzOTk4MUEsYjIwJiNCRDk0RjQs"));