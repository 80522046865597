import React, { Component } from 'react';
import { Platform, StyleSheet, Text, View, PanResponder, Vibration, Animated, Easing } from 'react-native';
// import * as Haptics from 'expo-haptics';
import PropTypes from 'prop-types';
import Hex from '../models/Hex';
import Point from '../models/Point';
import HexText from './HexText';
import HexUtils from '../HexUtils';
import Board from '../../models/Board';
import extractBrush from 'react-native-svg/src/lib/extract/extractBrush';
import Svg, { Path, Polygon, G } from 'react-native-svg';
import utils  from '../../utils/utils.js';
import { playSound } from '../../utils/soundUtils.js';
import theme from '../../styles/theme.js';
const AnimatedPoly = Animated.createAnimatedComponent(Polygon);
const AnimatedG = Animated.createAnimatedComponent(G);
import { withThemeContext } from '../../contexts/ThemeContext';
import { withBoardContext } from '../../contexts/BoardContext';

class PackTile extends Component {
  static propTypes = {
    q: PropTypes.number.isRequired,
    r: PropTypes.number.isRequired,
    s: PropTypes.number.isRequired,
    letter: PropTypes.string,
    fill: PropTypes.string,
    isSelected: PropTypes.bool,
    data: PropTypes.object,
    onClick: PropTypes.func,
    children: PropTypes.node
  };

  static contextTypes = {
    layout: PropTypes.object, // TODO Shape
    points: PropTypes.string
  };

  constructor(props, context) {
    super(props, context);
    const { q, r, s, letter, isSelected, isPerm, wordIndex, boardPanResponder } = props;
    const hex = new Hex(q, r, s, letter, isSelected, isPerm, wordIndex);
    const { layout } = context;
    const pixel = HexUtils.hexToPixel(hex, layout);
    const maxScale = 1.5;


    this.state = {
      hex,
      pixel,
      offsetTop: 0,
      offsetLeft: 0,
      dragTargetTiles: [],
      toSwapTiles: [],
      fillValue: new Animated.Value(0),
      opacityValue: new Animated.Value(1),
      lastLetter: props.letter,
      wasClicked: false,
    };

    this.state.fillValue.addListener(() => {
      const { fillValue } = this.state;
      const color = fillValue.interpolate({
        inputRange: [0, 1],
        outputRange: [this.lastFill, this.currFill],
      });
      if (this.polyRef) {
        this.polyRef.setNativeProps({
          fill: extractBrush(color.__getAnimatedValue()),
        });
      }
    });

    // animation vars
    this.animatingColor = false;
    this.lastFill = utils.hexToRgbA(this.assignTileColor(props.tileOwner, props.isPerm));
    this.currFill = this.lastFill;
    this.transitionDuration = 400;
    this.clickedTimeout = null;

        // Setup pan responder
    this.panResponder = PanResponder.create({
      onStartShouldSetPanResponder: (evt, gestureState) => true,
      onStartShouldSetPanResponderCapture: (evt, gestureState) => true,
      onMoveShouldSetPanResponder: (evt, gestureState) => true,
      onMoveShouldSetPanResponderCapture: (evt, gestureState) => true,
      onPanResponderGrant: () => {
        // this.onClick();
        this.setState({ wasClicked: true })

        if (this.clickedTimeout) clearTimeout(this.clickedTimeout);
        this.clickedTimeout = setTimeout(() => {
          this.setState({ wasClicked: false });
          this.clickedTimeout = null;
        }, 200)
      },
      onPanResponderMove: (evt, gs) => true,
      onPanResponderTerminationRequest: (evt, gestureState) => true,
      onPanResponderRelease: (evt, gs) => {
        if (this.clickedTimeout) clearTimeout(this.clickedTimeout);
        this.setState({ wasClicked: false });
        this.onClick();
      },
      onShouldBlockNativeResponder: (evt, gestureState) => {
        // Returns whether this component should block native components from becoming
        // the JS responder. Returns true by default. Is currently only supported on
        // android.

        // Set to false when dealing with panResponder touch targets contained within a scroll view (likely for SVG)
        return false;
      }
    })

  }

  // Only update when these change
  shouldComponentUpdate(nextProps, nextState) {
    const { hex, wasClicked } = this.state;
    const { letter, isSelected, isPerm, wordIndex, isGame, isSolved } = this.props;
    const { isTextureMode, isColorBlindMode, isDarkMode } = this.props.theme;
    // console.log(letter, nextProps.letter, letter !== nextProps.letter)
    return letter !== nextProps.letter ||
           isSelected !== nextProps.isSelected ||
           isPerm !== nextProps.isPerm ||
           wordIndex !== nextProps.wordIndex ||
           hex.wordIndex !== nextState.hex.wordIndex ||
           wasClicked !== nextState.wasClicked ||
           isGame !== nextProps.isGame ||
           isSolved !== nextProps.isSolved ||
           isTextureMode !== nextProps.theme.isTextureMode ||
           isDarkMode !== nextProps.theme.isDarkMode ||
           isColorBlindMode !== nextProps.theme.isColorBlindMode;
  }

  componentDidUpdate(prevProps, prevState) {
    const { q, r, s, letter, isSelected, isPerm, wordIndex } = this.props;
    if (letter !== prevProps.letter ||
        isSelected !== prevProps.isSelected ||
        isPerm !== prevProps.isPerm ||
        wordIndex !== prevProps.wordIndex) {
      const hex = new Hex(q, r, s, letter, isSelected, isPerm, wordIndex);
      this.setState({ hex });
    }
  }

  onClick() {
    const { chooseStartTileMode, setChooseStartTileMode, setWord, wordIndex, selectTile } = this.props.board;
    const { goToPack, goToDaily, packKey, index } = this.props;
    const { hex } = this.state;

    playSound('click');
    setTimeout(() => {
      if (packKey === 'daily') {
        goToDaily(index);
      } else {
        goToPack(packKey, index);
      }
    }, 0)
  }

  // Web only event handlers
  onPointerDown() {
    this.setState({ wasClicked: true });
  }
  onPointerUp() {
    this.setState({ wasClicked: false });
    this.onClick();
  }
  onPointerLeave() {
    this.setState({ wasClicked: false });
  }

  assignTileColor() {
    const { tilesetTheme, themeStyle, isColorBlindMode, getColorBlindColor } = this.props.theme;
    const { hex } = this.state;
    const { wordIndex, isSelected } = this.props;
    let color = utils.getPropertyByKeys(this.props.board, [`word${wordIndex}`, 'color']);

    if (isSelected) {
      return 'rgba(0,0,0,0)'
    } else {
      return theme.YELLOW_HONEY;
    }
  }

  // Disable certain hexes in editor to constrain all puzzles to be within puzzle game area.
  isOutOfEditorBounds() {
    const { q, r } = this.props;

    // Hardcoded hex positions that are not used in a 91-size hex grid in editor
    let outOfBoundsArray = [
      { q: -3, r: -2 },
      { q: 5, r: -2 },
      { q: -4, r: -1 },
      { q: 5, r: -1 },
      { q: -4, r: 0 },
      { q: -5, r: 0 },
      { q: 4, r: 0 },
      { q: 5, r: 0 },
      { q: -5, r: 1 },
      { q: 4, r: 1 },
      { q: -5, r: 2 },
      { q: 3, r: 2 },
      // { q: 0, r: 5 },
      // { q: -1, r: 5 },
      // { q: 1, r: 4 },
      // { q: -2, r: 5 },
      // { q: 0, r: 4 },
      // { q: 2, r: 3 },
      // { q: 0, r: -5 },
      // { q: -1, r: -4 },
      // { q: 1, r: -5 },
      // { q: -2, r: -3 },
      // { q: 0, r: -4 },
      // { q: 2, r: -5 },
    ];

    for (let i = 0; i < outOfBoundsArray.length; i++) {
      if (q === outOfBoundsArray[i].q &&
          r === outOfBoundsArray[i].r) {
        return true;
      }
    }
    return false;
  }


  // The touch polygon is an invisible polygon that handles all the touch interactions
  renderTouchPolygon() {
    const { setCurrentTile, setTile } = this.props;
    const { disabled, playerCurrent, userID, tileOwner, isPerm, isGame, isSolved } = this.props;
    const { points } = this.context;
    const { hex, pixel, offsetLeft, offsetTop } = this.state;

    // Web tile positioning
    let transform = `translate(${pixel.x + offsetLeft} ${pixel.y + offsetTop})`;

    let touchEvents = {
      onPointerDown: this.onPointerDown.bind(this),
      onPointerUp: this.onPointerUp.bind(this),
      onPointerLeave: this.onPointerLeave.bind(this),
    }
    if (Platform.OS !== 'web') {
      touchEvents = {...this.panResponder.panHandlers};
    }

    let style = {};
    if (Platform.OS === 'web') {
      style.cursor = 'pointer';
    }

    let touchPolygon = (
      <Polygon
        data-key={Board.positionToString(hex)}
        key={`${pixel.x}${pixel.y}-touch`}
        transform={transform}
        {...touchEvents}
        points={points}
        fill={theme.WHITE}
        fillOpacity={0}
        style={style}
      />
    )
    return touchPolygon;
  }

  render() {
    const { board, boardSize, className, isSelected, isHighlighted, isHidden, highlightedTiles, disabled, letter, playerCurrent, tileOwner, isPerm, opacity, shouldAnimate, isGame, colorOverride, isSolved, isStart, color, layoutSizeX, layoutSizeDefault } = this.props;
    const { points, layout } = this.context;
    const { themeStyle, isTextureMode, isDarkMode } = this.props.theme;
    const { hex, pixel, offsetLeft, offsetTop, toSwapTiles, opacityValue, lastLetter } = this.state;
    // const fillId = (fill) ? `url(#${fill})` : null; For pictures?

    if ((isGame && !letter) ||
        (!isGame && this.isOutOfEditorBounds())) {
      return null;
    }

    // Fill Color
    let fill = disabled || !shouldAnimate ? this.assignTileColor(tileOwner, isPerm) : this.currFill;
    if (colorOverride) fill = colorOverride

    let showTexture = isTextureMode && hex.wordIndex;
    let fillOpacity = letter ? 1 : 0.2;
    let textColor = 'black';
    if (!isSelected && color && utils.checkIfBelowLuminanceThreshold(color)) textColor = 'white';
    else if (isDarkMode && isSelected) textColor = 'white';
    let hexText = (
      <HexText
        x={pixel.x + offsetLeft}
        y={pixel.y + offsetTop}
        color={fill}
        textColor={textColor}
        boardSize={boardSize}
        isStart={isStart}
        isSelected={isSelected}
        isTextured={showTexture}
        isWord={!!hex.wordIndex}
        layoutSizeX={layoutSizeX}
        layoutSizeDefault={layoutSizeDefault}
      >
        { letter }
      </HexText>
    );

    if (disabled) {
      hexText = null;
    }

    if (isHidden) {
      fillOpacity = 0;
    }

    let strokeWidth = 0.8;
    if (boardSize) {
      strokeWidth *= 0.1837 * boardSize
    }

    let hasBorder = isSelected || isHighlighted;
    if (!hasBorder || isSolved) strokeWidth = 0;

    let scale = hasBorder ? .96 : 1
    if (this.state.wasClicked) {
      scale = 1.05;
    }
    // Web tile positioning + scaling to keep hex size constant when border is on
    let transform = `translate(${pixel.x + offsetLeft} ${pixel.y + offsetTop}) scale(${scale})`;

    return [
      <AnimatedPoly
        key={`${pixel.x}${pixel.y}-hex`}
        ref={ref => (this.polyRef = ref)}
        points={points}
        fill={isSelected ? `${color}0D` : color}
        fillOpacity={fillOpacity}
        stroke={color}
        strokeWidth={strokeWidth}
        transform={transform}
        style={{ transition: 'fill .3s, fill-opacity .3s' }}
      />,
      <AnimatedG
        key={`${pixel.x}${pixel.y}-wrapper`}
        fillOpacity={opacity !== undefined ? opacity : opacityValue}
      >
        { hexText }
      </AnimatedG>,
      this.renderTouchPolygon(),
    ]
  }
}

export default withThemeContext(withBoardContext(PackTile));

        // onPressIn={disabled ? null : () => this.onClick()}
        // delayPressIn={0}
        // delayPressOut={0}
const styles = StyleSheet.create({

});