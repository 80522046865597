import { useState, useEffect } from 'react'
import { StyleSheet, Text, View, TextInput, Pressable } from 'react-native';
import Button from './Button';
import ThemedText from './ThemedText';
import theme from '../styles/theme';
import { withThemeContext } from '../contexts/ThemeContext';

const Countdown = (props) => {
  const [timeLeft, setTimeLeft] = useState(null);
  const { refreshDaily } = props;
  const { themeStyle, isDarkMode } = props.theme;
  let timer = () => {};
  let startDate = new Date(new Date().setHours(0,0,0,0) + (1000 * 60 * 60 * 24));

  const startTimer = () => {
    timer = setTimeout(() => {
      if (timeLeft !== null) {
        if (timeLeft <= 0) {
          clearTimeout(timer);
          setTimeLeft(0);
          return false;
        }
        setTimeLeft(timeLeft - 1000);
      }
    }, 1000)
   }

  useEffect(() => {
    if (timeLeft === null) {
      start();
    } else {
      startTimer();
      return () => clearTimeout(timer);
    }
  });

  const start = () => {
    let startDate = new Date(new Date().setHours(0,0,0,0) + (1000 * 60 * 60 * 24));
    setTimeLeft(startDate - new Date());
    setTimeout(() => {
      clearTimeout(timer);
      startTimer();
    }, 1000)
  }

  let seconds = timeLeft / 1000;
  let minutes = seconds / 60;
  let hours = minutes / 60;
  let secondsText = Math.floor(seconds) % 60;
  if (secondsText < 10) secondsText = '0' + secondsText;
  let minutesText = Math.floor(minutes) % 60;
  if (minutesText < 10) minutesText = '0' + minutesText;
  let hoursText = Math.floor(hours);
  if (hoursText < 10) hoursText = '0' + hoursText;
  let countdownString = `${hoursText}:${minutesText}:${secondsText}`;

  if (props.completedChallenge) countdownString = 'New puzzles in ' + countdownString;
  else countdownString += ' Remaining';

  if (timeLeft === 0) {
    return (
      <Button
        color={themeStyle.TEXT_COLOR}
        titleStyle={{ color: themeStyle.BG, fontSize: 18, fontFamily: 'Nunito-SemiBold' }}
        title="Load New Daily"
        onPress={refreshDaily}
      />
    )
  }

  return (
    <ThemedText style={styles.countdownText}>{ countdownString }</ThemedText>
  );
}

export default withThemeContext(Countdown);

const styles = StyleSheet.create({
  countdownText: {
    color: theme.GRAY_4,
    fontFamily: 'Nunito-SemiBold',
    fontSize: 20
  }
});
