import { useState, useEffect, useRef } from 'react'
import * as Clipboard from 'expo-clipboard';
import { StyleSheet, Text, View, TextInput, Platform, ScrollView } from 'react-native';
import {
  HexGrid,
  HexLayout,
  Tile, HexText,
  HexPattern,
  Hex,
  HexUtils} from '../HexGrid';
import Board from '../models/Board';
import Reset from '../assets/Reset';
import Button from '../components/Button';
import Word from '../components/Word';
import utils from '../utils/utils';
import Dict from '../Dictionary/Dict';
import { colors } from '../components/ColorPicker';
import { withBoardContext } from '../contexts/BoardContext';
import { withThemeContext } from '../contexts/ThemeContext';
import theme from '../styles/theme';
import Analytics from '../analytics/analytics';

const webLargeScreenWidth = 375;

const Words = (props) => {
  const scrollViewRef = useRef(null);
  const [colorPickerMode, setColorPickerMode] = useState(0);
  const [linkSharedMode, setLinkSharedMode] = useState(false);
  const { themeStyle } = props.theme;
  const {
    wordIndex,
    numWords,
    setWord,
    removeWord,
    chooseStartTileMode,
    checkValidPuzzle,
    exportBoard
  } = props.board;

  // Scroll to active word
  useEffect(() => {
    if (scrollViewRef.current) {
      let wordHeight = theme.isSmallScreen ? 40 : 60;
      scrollViewRef.current.scrollTo({ y: (wordIndex - 1) * wordHeight, animated: true });
    }
  }, [wordIndex])

  // Construct each word element
  let wordEls = [];
  let allColors = [];
  for (let i = 1; i <= numWords; i++) {
    let color = utils.getPropertyByKeys(props.board, [`word${i}`, 'color']);
    allColors.push(color);
  }

  for (let i = 1; i <= numWords; i++) {
    wordEls.push(
      <Word
        key={`word-${i}`}
        numWords={numWords}
        index={i}
        isSolved={props.isSolved}
        isGame={props.isGame}
        allColors={allColors}
        removeWord={removeWord}
        colorPickerMode={colorPickerMode}
        setColorPickerMode={setColorPickerMode}
      />
    );
  }

  // Get a random color from all available colors in color picker
  function getRandomColor() {
    return colors[Math.floor(Math.random() * colors.length)];
  }

  // Get a random color that has not already been assigned
  let nextAvailableColor = getRandomColor();
  while (allColors.indexOf(nextAvailableColor) >= 0) {
    nextAvailableColor = getRandomColor();
  }


  // Editor elements
  let newWordText = numWords === 0 ? 'EXAMPLE' : '';

  let addBtn = (
    <Button
      title="Add Word"
      disabled={!!chooseStartTileMode}
      onPress={() => {
        setWord(null, newWordText, nextAvailableColor, numWords + 1);
      }}
    />
  );
  if (numWords >= 6) addBtn = null;

  // Share button below words for non-large screens only.
  // For large screens, share button will be shown in the header
  let shareBtn = null;
  let alertEl = null;
  if (!props.isGame &&
      numWords > 0 &&
      (theme.SCREEN_WIDTH < theme.largeScreenWidth + 100)) {
    if (!checkValidPuzzle()) {
      alertEl = <Text style={{ width: 180, marginTop: 10, fontFamily: 'Nunito-SemiBold', color:'#FF5567', fontSize: 14}}>Words must be 3+ long, in dictionary, and on board.</Text>
    }

    shareBtn = (
      <View>
        <Button
          disabled={numWords < 1 || !checkValidPuzzle()}
          color={linkSharedMode ? '#9EEBE3' : '#74E2D7'}
          title={linkSharedMode ? 'Link Copied!' : 'Share Puzzle Link'}
          onPress={() => {
            if (checkValidPuzzle()) {
              let code = exportBoard();
              let url = 'https://honeycomb.goodgameshelf.com/game/' + code;
              if (Platform.OS === 'web') url = window.location.origin + '/game/' + code;
              Clipboard.setString(url);
              Analytics.logCustomPuzzleShared(numWords, code, url);
              setLinkSharedMode(true);
              setTimeout(() => {
                setLinkSharedMode(false);
              }, 3000);
            }
          }}
        />
        { alertEl }
      </View>
    );
  }

  let editorBtnsEl = null;
  if (!props.isGame) {
    let editorBtnsContainerStyle = [styles.editorBtnsContainer];
    if (!shareBtn) editorBtnsContainerStyle.push({
      justifyContent: 'center'
    })
    if (!addBtn) editorBtnsContainerStyle.push({
      justifyContent: 'flex-end'
    })
    let editorFeaturesContainerStyle = {};
    if (theme.isLargeScreen && Platform.OS === 'web') {
      editorFeaturesContainerStyle.maxWidth = webLargeScreenWidth;
      editorFeaturesContainerStyle.padding = 10;
    }

    editorBtnsEl = (
      <View style={[ styles.editorFeaturesContainer, editorFeaturesContainerStyle ]}>
        <View style={editorBtnsContainerStyle}>
          { addBtn }
          { shareBtn }
        </View>
      </View>
    );
  }

  // Styles
  let containerStyle = { backgroundColor: themeStyle.WORDS_BG };
  if (theme.isLargeScreen && Platform.OS === 'web') {
    containerStyle = {
      maxWidth: webLargeScreenWidth,
      borderRadius: 20,
      backgroundColor: themeStyle.CARD_BG,
      marginLeft: 15,
      paddingVertical: 10,
    }
  }

  let wordsContainerStyle = [styles.wordsContainer];

  // Use scroll view if:
  // - small screen with 4+ words
  // - editor with 4+ words for any screen size
  if ((!theme.isLargeScreen && numWords > 3) ||
      (numWords > 3 && !props.isGame && Platform.OS !== 'web') ||
      (numWords > 1 && !props.isGame && theme.isSmallScreen)) {
    return (
      <View style={{ flex: 1, backgroundColor: themeStyle.WORDS_BG, width: '100%' }}>
        <ScrollView
          ref={scrollViewRef}
          contentContainerStyle={{
            paddingTop: 5,
            paddingBottom: 15,
          }}>
          <View style={wordsContainerStyle}>
            { wordEls }
            { editorBtnsEl }
          </View>
        </ScrollView>
      </View>
    );
  } else {
    // Wrap words for larger screens for 4+ words. This will be 2 columns due to width limit in Word
    if (theme.isLargeScreen && numWords > 3 ||
        (theme.isLargeScreen && Platform.OS === 'web')) {
      wordsContainerStyle.push({
        flexWrap: 'wrap',
        flexDirection: 'row',
        justifyContent: 'flex-start',
      });
    }

    return (
      <View style={[styles.container, containerStyle]}>
        <View style={wordsContainerStyle}>
          { wordEls }
          { editorBtnsEl }
        </View>
      </View>
    );
  }
}

export default withThemeContext(withBoardContext(Words));

const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    width: '100%',
    flex: 1,
    zIndex: 2,
    // backgroundColor: theme.WHITE,
    ...theme.flexCenter,
  },
  wordsContainer: {
    width: '100%',
    ...theme.flexCenter,
  },
  editorFeaturesContainer: {
    zIndex: 0, // Always below the words and therefore below color picker
    width: theme.SCREEN_WIDTH - 20,
    maxWidth: theme.smallScreenWidth,
    ...theme.flexCenter,
    marginTop: 20
  },
  editorBtnsContainer: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between'
  }
});
