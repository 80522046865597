import Trie from './Trie'
import DecompressTrie from './DecompressTrie'
import All from './dictionary'

class Dict {

  static lengthOkay(word) {
    return word.length >= 3;
  }

  static words = Dict.fromWordLists([All.words], Dict.lengthOkay);

  static isInMainDictionary(word) {
    return Dict.words.containsWord(word);
  }

  static fromWordLists(sources, predicate) {
    let t1 = Date.now();
    let t = new Trie();
    sources.forEach(source => {
      let st = DecompressTrie.decompress(source);
      st.forEach(word => {
        if (predicate(word)) t.insertWord(word);
      });
    });
    let t2 = Date.now();
    // console.log("Finished loading words in " + (t2-t1) + "ms");
    let uniqueWords = t.size();
    // console.log("Number of unique words: " + uniqueWords);
    return t;
  }

}

export default Dict;
