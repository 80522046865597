import { useState } from 'react'
import { StyleSheet, Text, View, TextInput, TouchableWithoutFeedback, TouchableOpacity, Platform } from 'react-native';
import {
  HexGrid,
  HexLayout,
  Tile, HexText,
  HexPattern,
  Hex,
  HexUtils} from '../HexGrid';
import Board from '../models/Board';
import utils from '../utils/utils';
import theme from '../styles/theme';
import PencilIcon from '../assets/PencilIcon';
import Reset from '../assets/Reset';
import Button from '../components/Button';
import ColorPicker from '../components/ColorPicker';
import TrashIcon from '../assets/TrashIcon';
import { withBoardContext } from '../contexts/BoardContext';
import Dict from '../Dictionary/Dict';
import { withThemeContext } from '../contexts/ThemeContext';

const Word = (props) => {
  const { chooseStartTileMode, setChooseStartTileMode, setWord, setTile, unselectWord, wordIndex, removeWord, numWords, boardSize } = props.board;
  const { themeStyle, isDarkMode, isColorBlindMode, getColorBlindColor } = props.theme;

  let [isPlaced, setIsPlaced] = useState(false);

  let wordData = utils.getPropertyByKeys(props.board, [`word${props.index}`]);
  let wordText = utils.getPropertyByKeys(wordData, ['word']);

  let selectedText = utils.getPropertyByKeys(props.board, [`word${props.index}selected`]);
  let selectedLength = selectedText ? selectedText.length : 0;

  let wordColor = utils.getPropertyByKeys(wordData, ['color']);
  if (isColorBlindMode) wordColor = getColorBlindColor(props.index) || wordColor;
  let startHex = utils.getPropertyByKeys(wordData, ['startHex']);
  let hexString = '';
  if (startHex) {
    hexString = Board.positionToString(startHex)
  }

  // Correct for when the previous word was deleted
  if (startHex && !isPlaced) {
    setIsPlaced(true);
  }

  function removeWordFromBoard() {
    for (let q = -boardSize; q <= boardSize; q++) {
      let r1 = Math.max(-boardSize, -q - boardSize);
      let r2 = Math.min(boardSize, -q + boardSize);
      for (let r = r1; r <= r2; r++) {
        let s = -q-r;

        let hex = new Hex(q, r, s);
        let positionKey = Board.positionToString(hex);
        let newHex = JSON.parse(JSON.stringify(props.board[positionKey]));
        if (newHex && newHex.wordIndex === props.index) {
          newHex.letter = '';
          newHex.wordIndex = null;
          setTile(newHex);
        }
      }
    }
    setIsPlaced(false);
    setWord(null, wordText.toUpperCase(), wordColor, props.index);
  }

  let placeText = 'Place on Board';
  let removeText = 'Remove from Board';
  if (!theme.isLargeScreen || (theme.isLargeScreen && Platform.OS === 'web')) {
    placeText = 'Place';
    removeText = 'Remove';
  }

  let btn = null;
  let deleteBtn = null;
  let resetBtn = null;
  if (!chooseStartTileMode) {

    if (isPlaced) {
      deleteBtn = (
        <Button
          disabled={!wordText.length}
          style={{ marginLeft: 8 }}
          title={removeText}
          onPress={() => removeWordFromBoard()}
        />
      );
    } else {
      btn = (
        <Button
          disabled={!wordText.length}
          style={{ marginLeft: 8 }}
          title={placeText}
          onPress={() => {
            setIsPlaced(true);
            setChooseStartTileMode(props.index);
          }}
        />
      );
      deleteBtn = (
        <Button
          style={{ marginLeft: 8 }}
          onPress={() => removeWord(props.index)}
        >
          <TrashIcon size={30}/>
        </Button>
      );
    }
  } else if (chooseStartTileMode.index === props.index) {
    deleteBtn = (
      <Button
        disabled={!wordText.length}
        style={{ marginLeft: 8 }}
        title="Cancel"
        onPress={() => {
          setChooseStartTileMode(false);
          removeWordFromBoard()
        }}
      />
    );
  }

  let colorEl = null;
  if (wordColor) {
    let colorPickerOpen = props.colorPickerMode === props.index;
    let colorIconEl = <PencilIcon />;
    if (colorPickerOpen) colorIconEl = (
      <View style={{ alignItems: 'center', justifyContent: 'center' }}>
        <Text style={{ fontSize: 20, fontFamily: 'Nunito-Regular' }}>X</Text>
      </View>
    );

    let colorElStyle = [styles.patch, {
      backgroundColor: wordColor
    }]
    if (theme.isSmallScreen) colorElStyle.push({
      width: 40,
      height: 40
    })

    if (isColorBlindMode) {
      colorEl = <View style={colorElStyle}/>
    } else {
      colorEl = (
        <TouchableOpacity
          style={colorElStyle}
          onPress={() => {
            let newMode = colorPickerOpen ? 0 : props.index;
            props.setColorPickerMode(newMode);
          }}
        >
          { colorIconEl }
        </TouchableOpacity>
      );
    }
  }


  let letterStyle = {
    height: 36,
    alignItems: 'center',
    justifyContent: 'center',
  };
  if (Platform.OS === 'web') letterStyle.cursor = 'pointer';

  // Width is a separate variable because it depends on word length
  let letterWidth = 32;
  let maxWordLengthBeforeScaling = 10;

  // Styles
  let wordStyle = {
    fontSize: 22,
    fontFamily: 'Nunito-SemiBold',
  };

  let resetBtnStyle = {
    marginLeft: 10,
  };

  let containerStyle = {
    height: 50,
    flexDirection: 'row',
    marginBottom: 10,
    alignItems: 'center',
    width: '100%',
    paddingHorizontal: 10,
    zIndex: (props.numWords - props.index) + 1 // zIndex in decreasing order so color picker always appears on top (picker is top aligned)
  };

  let textInputStyle = {
    color: themeStyle.TEXT_COLOR,
    flex: 1,
    maxWidth: 300,
    height: '80%'
  }

  // Style modifications
  if (theme.isSmallScreen) {
    letterWidth = 28;
    maxWordLengthBeforeScaling = 11; // More room horizontally when scaled
    letterStyle.height = 30;
    wordStyle.fontSize = 18;
    containerStyle.paddingLeft = 6;
    containerStyle.marginBottom = 0;

    // Editor mobile adjustments
    if (props.isGame) {
      containerStyle.height = 40;
    } else {
      containerStyle.marginBottom = 2;
      textInputStyle.maxWidth = 200;
    }
  } else {
    if (props.isGame) {
      containerStyle.width = '75%';
      containerStyle.maxWidth = 375;
    } else {
      containerStyle.justifyContent = 'flex-start';
      containerStyle.maxWidth = 600;
    }

    if (theme.isLargeScreen && Platform.OS === 'web') {
      containerStyle.marginBottom = 0;
      containerStyle.marginVertical = 5;
      maxWordLengthBeforeScaling = 9;
    }
  }

  let alertEl = null;
  if (!Dict.isInMainDictionary(wordText)) {
    alertEl = (
      <View style={{position: 'absolute', top: -8, right: -8, width: 20, height: 20,borderRadius: 10, backgroundColor: '#FF5567', ...theme.flexCenter}}>
        <Text style={{ fontFamily: 'Nunito-SemiBold', fontSize: 14, color: 'white'}}>!</Text>
      </View>
    );
  }

  let textInput = (
    <View style={textInputStyle}>
      <TextInput
        style={[styles.input, textInputStyle ]}
        disabled={isPlaced}
        value={wordText}
        placeholder="Enter your word"
        placeholderTextColor={themeStyle.PLACEHOLDER_TEXT_COLOR}
        autoCapitalize="characters"
        onChangeText={(text) => {
          // Don't allow spaces
          if (text.indexOf(' ') < 0) {
            setWord(startHex, text.toUpperCase(), wordColor, props.index);
          }
        }}
      />
      { alertEl }
    </View>
  )

  if (props.isGame) {
    colorEl = null;
    btn = null;
    deleteBtn = null;
    textInput = null;
  }

  // Scale letters based on the amount of overflow beyond max, to maintain constant total length of word
  let letterWidthScale = 1;
  if (selectedLength > maxWordLengthBeforeScaling) {
    // Ratio is "desired length" / "current length"
    letterWidthScale = maxWordLengthBeforeScaling / selectedLength;
  }

  let letterElsArray = [];
  if (props.isGame) {
    for (let i = 0; i < selectedLength; i++) {
      let color = themeStyle.TEXT_COLOR;
      if (i === 0) {
        color = !isDarkMode && isColorBlindMode ? 'white' : 'black';
      }
      let textStyle = {
        color: color,
      }
      if (Platform.OS === 'web') textStyle.userSelect = 'none';

      let finalLetterStyle = [letterStyle, {
        width: (i === 0) ? letterWidth : letterWidth * letterWidthScale,
        marginRight: (i === 0) ? 1 : null,
        borderWidth: (wordIndex === props.index && i === 0 && !props.isSolved) ? 2 : 0,
        borderBottomLeftRadius: 4,
        borderTopLeftRadius: 4,
        borderColor: themeStyle.TEXT_COLOR,
        backgroundColor: (i === 0) ? wordColor : null,
      }]

      letterElsArray.push(
        <View
          key={`letter-${i}`}
          style={finalLetterStyle}
        >
          <Text style={[wordStyle, textStyle]}>{utils.getPropertyByKeys(selectedText, [i, 'letter'])}</Text>
        </View>
      );
    }

    // Reset btn
    if (selectedLength > 1 && !props.isSolved) {
      resetBtn = (
        <TouchableOpacity style={resetBtnStyle} onPress={() => unselectWord(props.index) }>
          <Reset color={themeStyle.TEXT_COLOR} />
        </TouchableOpacity>
      )
    }
  }

  let colorPickerEl = null;
  if (props.colorPickerMode === props.index) colorPickerEl = (
    <ColorPicker
      allColors={props.allColors}
      selectedColor={wordColor}
      setColor={(color) => {
        setWord(startHex, wordText, color, props.index);
      }}
    />
  );


  let el = (
    <View style={containerStyle}>
      { colorEl }
      { textInput }
      { btn }
      { deleteBtn }
      { letterElsArray }
      { resetBtn }
      { colorPickerEl }
    </View>
  )

  if (props.isGame) {
    el = (
      <TouchableWithoutFeedback onPress={() => {
        const { setWordIndex } = props.board;
        // console.log("CLICK WORD")
        setWordIndex(props.index);
      }}>
        { el }
      </TouchableWithoutFeedback>
    )
  }

  return el;
}

export default withBoardContext(withThemeContext(Word));

const styles = StyleSheet.create({
  patch: {
    width: 50,
    height: 50,
    borderRadius: 2,
    alignItems: 'center',
    justifyContent: 'center',
  },
  input: {
    height: '100%',
    borderWidth: 2,
    borderRadius: 4,
    marginLeft: 8,
    paddingHorizontal: 8,
    borderColor: '#999',
  }
});
