import { Component } from 'react'
import { StyleSheet, View, TouchableOpacity, Image, Linking, } from 'react-native';
import theme from '../styles/theme';
import KeyIcon from '../assets/icons/KeyIcon';
import { withThemeContext } from '../contexts/ThemeContext';

const ProgressBar = (props) => {
  const { percent, color } = props;
  const { themeStyle, isDarkMode } = props.theme;

  let finalPercent = percent > 100 ? 100 : percent;

  let progressBarStyle = {};
  if (finalPercent >= 0) {
    progressBarStyle.width = `${finalPercent}%`;
  }

  let colorOpacityModifier = isDarkMode ? '44' : '22';

  return (
    <View style={[styles.progressBarContainer, { backgroundColor: `${color}${colorOpacityModifier}` }]}>
      <View style={[styles.progressBar, progressBarStyle, { backgroundColor: color }]} />
      <View style={styles.keyContainer}>
        <KeyIcon size={32} color={themeStyle.TEXT_COLOR}/>
      </View>
    </View>
  );
}

export default withThemeContext(ProgressBar);

const styles = StyleSheet.create({
  progressBarContainer: {
    width: '60%',
    maxWidth: 300,
    height: 10,
    borderRadius: 5,
    justifyContent: 'center',
    marginVertical: 15,
  },
  progressBar: {
    width: 0,
    height: 10,
    borderRadius: 5,
    backgroundColor: theme.YELLOW_HONEY,
  },
  keyContainer: {
    position: 'absolute',
    left: -15,
    ...theme.flexCenter,
    // width: 40,
    // height: 40,
    // borderRadius: 20,
    // backgroundColor: 'white',
  }
});