import React, { Component } from 'react';
import Svg, { Path } from 'react-native-svg';

const LockIcon = (props) => {
  const { size, color } = props;

  let finalSize = size || 40;
  let finalColor = color || 'black';

  return (
    <Svg width={finalSize} height={finalSize * 48 / 40} viewBox="0 0 40 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <Path fillRule="evenodd" clipRule="evenodd" d="M20 0C13.0964 0 7.5 5.59643 7.5 12.5V20H2C0.895431 20 0 20.8954 0 22V46C0 47.1046 0.895431 48 2 48H38C39.1046 48 40 47.1046 40 46V22C40 20.8954 39.1046 20 38 20H32.5V12.5C32.5 5.59644 26.9036 0 20 0ZM15 11.4286V20H25V11.4286C25 8.66715 22.7614 6.42857 20 6.42857C17.2386 6.42857 15 8.66715 15 11.4286ZM23.4641 32L20 30L16.5359 32V36L20 38L23.4641 36V32Z" fill={finalColor}/>
    </Svg>
  );
}

export default LockIcon;