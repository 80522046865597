import { StyleSheet, Linking, View, TouchableOpacity, Switch, Platform, ScrollView, Image } from 'react-native';
import { useState } from 'react';
import Button from '../components/Button';
import ThemedText from '../components/ThemedText';
import utils from '../utils/utils';
import CrossPromotion from './CrossPromotion';
import theme from '../styles/theme';
import Analytics from '../analytics/analytics';
import { withThemeContext } from '../contexts/ThemeContext';
import { soundsDisabled, musicDisabled, toggleSounds, toggleMusic, playSound } from '../utils/soundUtils';

const SettingsMenu = (props) => {
  const [soundsOff, setSoundsOff] = useState(!soundsDisabled);
  const [musicOff, setMusicOff] = useState(!musicDisabled);
  const [showCrossPromotionMode, setShowCrossPromotionMode] = useState(false);

  const { setShowSettingsMode, isEditor } = props;
  const { themeStyle, isDarkMode, toggleDarkMode, isMarkStartTilesMode, toggleMarkStartTilesMode, isTextureMode, toggleTextureMode, isColorBlindMode, toggleColorBlindMode } = props.theme;

  const _toggleSounds = () => {
    toggleSounds();
    setSoundsOff(!soundsOff);
  }

  const _toggleMusic = () => {
    toggleMusic();
    setMusicOff(!musicOff);
  }

  let settingsContainerStyle = {
    ...theme.fullAbsolute,
    top: 0,
    backgroundColor: themeStyle.BG,
    alignItems: 'center',
  };

  let closeBtn = (
    <TouchableOpacity
      style={styles.menuCloseButton}
      onPress={() => {
        playSound('click');
        setShowSettingsMode(false)
      }}
    >
      <ThemedText style={{ fontSize: 36, marginTop: -10 }}>x</ThemedText>
    </TouchableOpacity>
  );

  let renderOption = (title, onValueChange, value, subtitle='') => {
    let subtitleEl = null;
    if (subtitle.length > 0) {
      subtitleEl = (
        <View style={{ position: 'absolute', bottom: -25 }}>
          <ThemedText style={[styles.settingDescriptionText]}>{ subtitle }</ThemedText>
        </View>
      );
    }

    let switchStyle = {
      transform: [{ scale: Platform.OS === 'android' ? 1.4 : 1.1 }],
      marginRight: 8,
    }

    let switchEl = (
      <Switch
        style={switchStyle}
        thumbColor={value ? theme.YELLOW_HONEY : theme.GRAY_4}
        trackColor={{ false: theme.GRAY_2, true: '#FFDE39'}}
        ios_backgroundColor={'transparent'}
        onValueChange={() => {
          playSound('click');
          onValueChange();
        }}
        value={value}
      />
    );
    if (Platform.OS === 'web') {
      switchEl = (
        <Switch
        style={switchStyle}
        thumbColor={theme.GRAY_4}
        activeThumbColor={theme.YELLOW_HONEY} // required for web but deprecated outside of web
        trackColor={{ false: theme.GRAY_2, true: '#FFDE39'}}
        ios_backgroundColor={'transparent'}
        onValueChange={() => {
          playSound('click');
          onValueChange();
        }}
        value={value}
      />
      )
    }

    return (
      <View style={{ marginBottom: 50, ...theme.row, justifyContent: 'space-between', height: 30 }}>
        <View style={{ width: 250, flexDirection: 'column' }}>
          <ThemedText style={styles.settingText}>{ title }</ThemedText>
          { subtitleEl }
        </View>
        <View>
          { switchEl }
        </View>
      </View>
    );
  }

  let soundToggle = renderOption('Sound Effects', _toggleSounds, soundsOff);
  let darkModeToggle = renderOption('Dark Mode', toggleDarkMode, isDarkMode);
  let colorBlindToggle = renderOption('High Contrast Colors', toggleColorBlindMode, isColorBlindMode);
  let texturesToggle = renderOption('Textured Tiles', toggleTextureMode, isTextureMode);
  let markStartToggle = renderOption('Notched Start Tiles', toggleMarkStartTilesMode, isMarkStartTilesMode, 'Shows a cutout on start tiles.');

  let editorBtn = (
    <View style={{ alignSelf: 'center', flexDirection: 'column' }}>
      <Button
        color={themeStyle.TEXT_COLOR}
        style={{ width: 250 }}
        title="Open Puzzle Maker"
        titleStyle={{ color: themeStyle.BG, fontSize: 18, fontFamily: 'Nunito-SemiBold' }}
        onPress={() => {
          props.navigate('Editor');
          Analytics.logAppButtonPressed('Open Puzzle Maker');
        }}
      />
    </View>
  );

  let shareFeedbackBtn = (
    <View style={{marginVertical: 30, alignSelf: 'center', flexDirection: 'row' }}>
      <Button
        style={{ width: 250 }}
        color={isDarkMode ? 'black' : theme.GRAY_1}
        titleStyle={{ color: themeStyle.TEXT_COLOR, fontSize: 18, fontFamily: 'Nunito-SemiBold' }}
        title='Share Feedback'
        onPress={() => {
          Linking.openURL(utils.generateMailToString());
          Analytics.logLinkClicked('Share Feedback');
        }}
      />
    </View>
  );

  let crossPromotionBtn = (
    // <View style={{ alignSelf: 'center' }}>
    <Button
      color='#9876F9'
      title='More from Spiralburst'
      titleStyle={{ fontSize: 18, fontFamily: 'Nunito-SemiBold' }}
      onPress={() => {
        setShowCrossPromotionMode(true);
        Analytics.logAppButtonPressed('More from Spiralburst');
      }}
    />
    // </View>
  );

  let crossPromotionEl = null;
  if (showCrossPromotionMode) crossPromotionEl = <CrossPromotion exit={() => setShowCrossPromotionMode(false)}/>;

  // let headerHeight = 50;
  // let footerHeight = 80;

  let iosBadgeSource = require('../assets/app-store-badge.png');
  if (isDarkMode) iosBadgeSource = require('../assets/app-store-badge-white.png')
  let storeBtns = (
    <View style={[theme.row, theme.flexCenter]}>
      <Button
        style={{ backgroundColor: 'transparent' }}
        onPress={() => {
          Linking.openURL("https://play.google.com/store/apps/details?id=com.spiralburst.honeycomb");
          Analytics.logLinkClicked('android-from-settings');
        }}
      >
        <Image style={{ width: 133, height: 40, marginBottom: 5 }} source={require('../assets/google-play-badge.png')} />
      </Button>
      <Button
        style={{ backgroundColor: 'transparent' }}
        onPress={() => {
          Linking.openURL("https://apps.apple.com/us/app/honeycomb-word-puzzle/id6446267827");
          Analytics.logLinkClicked('ios-from-settings');
        }}
      >
        <Image style={{ width: 120, height: 40, marginBottom: 5 }} source={iosBadgeSource} />
      </Button>
    </View>
  )

  return (
    <View style={settingsContainerStyle}>
      <View style={styles.menuHeader}>
        <ThemedText style={[styles.buttonText, { fontSize: 28, fontFamily: 'Nunito-SemiBold' }]}>Settings</ThemedText>
        { closeBtn }
      </View>
      <ScrollView
        style={styles.settingsScroll}
        showsVerticalScrollIndicator={false}
        contentContainerStyle={{ paddingTop: 10 }}
      >
        <View
          style={[styles.togglesContainer, {
            borderColor: themeStyle.BORDER_COLOR,
            paddingTop: Platform.OS === 'android' ? 20 : 0
          }]}
        >
          { soundToggle }
          { darkModeToggle }
          { colorBlindToggle }
          { texturesToggle }
          { markStartToggle }
        </View>
        <View style={styles.mainButtonContainer}>
          { editorBtn }
          { shareFeedbackBtn }
          { storeBtns }
        </View>
        <View style={[styles.crossPromotionBtnContainer, { borderColor: themeStyle.BORDER_COLOR, paddingTop: Platform.OS === 'web' ? 50 : 20 }]}>
          { crossPromotionBtn }
        </View>
      </ScrollView>
      { crossPromotionEl }
    </View>
  );
}
export default withThemeContext(SettingsMenu);

const styles = StyleSheet.create({
  buttonText: {
    fontSize: 18,
    fontFamily: 'Nunito-SemiBold',
    textTransform: 'capitalize'
  },
  menuCloseButton: {
    position: 'absolute',
    right: 0,
    width: 50,
    height: 50,
    ...theme.flexCenter,
  },
  menuHeader: {
    height: 60,
    width: '100%',
    alignSelf: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 40,
    // borderBottomWidth: 2,
    // borderBottomColor: theme.GRAY_1
  },
  togglesContainer: {
    borderBottomWidth: 1,
    // borderColor: theme.GRAY_2,
    paddingHorizontal: 25,
  },
  menuFooter: {
    height: 30,
    position: 'absolute',
    bottom: 10,
    right: 10
  },
  settingText: {
    fontSize: 20,
  },
  settingDescriptionText: {
    fontSize: 16,
    whiteSpace: 'nowrap'
  },
  settingsScroll: {
    width: Math.min(theme.SCREEN_WIDTH, 600),
    flexDirection: 'column',
  },
  mainButtonContainer: {
    marginTop: 40,
    marginBottom: 20,
  },
  crossPromotionBtnContainer: {
    height: 80,
    width: '100%',
    // borderColor: theme.GRAY_2,
    borderTopWidth: 1,
    ...theme.flexCenter,
    paddingBottom: 200
  }
});
