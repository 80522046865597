import { useEffect, useState } from 'react';
import { StyleSheet, Text, View, Button, Image, Platform, LogBox } from 'react-native';
import * as NavigationBar from "expo-navigation-bar";
import { setStatusBarHidden, StatusBar, setStatusBarStyle } from 'expo-status-bar';
import { NavigationContainer } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import GameScreen from './screens/GameScreen';
import EditorScreen from './screens/EditorScreen';
import PacksScreen from './screens/PacksScreen';
import { SafeAreaProvider, SafeAreaView, initialWindowMetrics } from 'react-native-safe-area-context';
import { ThemeContext, ThemeContextProvider, withThemeContext } from './contexts/ThemeContext';
import { BoardContext, BoardContextProvider, withBoardContext } from './contexts/BoardContext';
import { SaveContext, SaveContextProvider, withSaveContext } from './contexts/SaveContext';
import * as SplashScreen from 'expo-splash-screen';
import { useFonts } from 'expo-font';
import * as Linking from 'expo-linking';
import { Asset } from 'expo-asset';
import { initSounds } from './utils/soundUtils'
import Analytics from './analytics/analytics';
import theme from './styles/theme';

// Android status bar settings
if (Platform.OS === 'android') {
  NavigationBar.setPositionAsync("absolute");
  NavigationBar.setVisibilityAsync("hidden");
  NavigationBar.setBehaviorAsync("overlay-swipe");
  NavigationBar.setBackgroundColorAsync("#00000080"); // `rgba(0,0,0,0.5)`
  setStatusBarHidden(true, "none");
}

LogBox.ignoreLogs(['Possible'])

const prefix = Linking.createURL('/');

const config = {
  screens: {
    Game: '',
    Daily: 'daily',
    CustomGame: 'game/:gameID',
    Pack: 'game/pack/:packID/:gameID?',
    Editor: 'editor',
    Packs: 'packs',
  },
};

const linking = {
  prefixes: [
    prefix,
    'honeycomb://'
  ],
  config,
};

const Stack = createNativeStackNavigator();

function cacheImages(images) {
  return images.map(image => {
    if (typeof image === 'string') {
      return Image.prefetch(image);
    } else {
      return Asset.fromModule(image).downloadAsync();
    }
  });
}

let Content = (props) => {
  const { isDarkMode, themeStyle } = props.theme
  // console.log("DARK?", isDarkMode)
  let statusBar = null;
  if (Platform.OS === 'ios') {
    statusBar = <StatusBar style={isDarkMode ? 'light' : 'dark'} />
  }

  return (
    <SafeAreaView edges={['top','left','bottom','right']} style={{ flex: 1, backgroundColor: themeStyle.BG, }}>
      { statusBar }
      <NavigationContainer
        linking={linking}
        documentTitle={{
          formatter: (options, route) =>
            `${options?.title ?? route?.name}`,
        }}
      >
        <Stack.Navigator screenOptions={{ animation: 'none' }}>
          <Stack.Screen name="Game" component={GameScreen} options={{ headerShown: false, title: 'Honeycomb' }} />
          <Stack.Screen name="Daily" component={GameScreen} options={{ headerShown: false, title: 'Honeycomb - Daily Challenge' }} />
          <Stack.Screen name="CustomGame" component={GameScreen} options={{ headerShown: false, title: 'Honeycomb' }} />
          <Stack.Screen name="Pack" component={GameScreen} options={{ headerShown: false, title: 'Honeycomb' }} />
          <Stack.Screen name="Editor" component={EditorScreen} options={{ headerShown: false, title: 'Honeycomb - Puzzle Editor' }} />
          <Stack.Screen name="Packs" component={PacksScreen} options={{ headerShown: false, title: 'Honeycomb - Packs' }} />
        </Stack.Navigator>
      </NavigationContainer>
    </SafeAreaView>
  );
}
Content = withThemeContext(Content);


const App = () => {
  const [appIsReady, setAppIsReady] = useState(false);

  // ComponentDidMount
  useEffect(() => {
    initSounds();
    Analytics.initializeAnalytics();
    Analytics.logAnalyticsInitialized();

    async function loadResourcesAndDataAsync() {
      try {
        SplashScreen.preventAutoHideAsync();

        const imageAssets = cacheImages([
          require('./assets/images/help-drag-to-fill.png'),
          require('./assets/images/help-tap-to-fill.png'),
          require('./assets/images/honeycomb-bee.png'),
          require('./assets/images/rules-puzzle-complete-dark.png'),
          require('./assets/images/rules-puzzle-complete.png'),
          require('./assets/images/rules-puzzle-empty-dark.png'),
          require('./assets/images/rules-puzzle-empty.png'),
          require('./assets/images/rules-puzzle-halfway-dark.png'),
          require('./assets/images/rules-puzzle-halfway.png'),
        ]);

        await Promise.all([...imageAssets]);
      } catch (e) {
        // You might want to provide this error information to an error reporting service
        console.warn(e);
      } finally {
        setAppIsReady(true);
        SplashScreen.hideAsync();
      }
    }

    loadResourcesAndDataAsync();
  }, [])

  const [fontsLoaded] = useFonts({
    'Nunito-Regular': require('./assets/fonts/Nunito-Regular.ttf'),
    'Nunito-SemiBold': require('./assets/fonts/Nunito-SemiBold.ttf'),
    'Nunito-Bold': require('./assets/fonts/Nunito-Bold.ttf'),
    'Nunito-ExtraBold': require('./assets/fonts/Nunito-ExtraBold.ttf'),
  });

  if (!fontsLoaded || !appIsReady) {
    return null;
  }

  // Load into manifest
  let image = <Image source={require('./assets/images/honeycomb-thumbnail.png')}/>;

  return (
    <SafeAreaProvider initialMetrics={initialWindowMetrics}>
      <ThemeContextProvider>
        <BoardContextProvider>
          <SaveContextProvider>
            <Content />
          </SaveContextProvider>
        </BoardContextProvider>
      </ThemeContextProvider>
    </SafeAreaProvider>
  );
}

export default App

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden'
  },
});
