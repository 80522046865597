import React, { Component } from 'react';
import { Platform, StyleSheet, Text, View, PanResponder, Vibration, Animated, Easing } from 'react-native';
// import * as Haptics from 'expo-haptics';
import PropTypes from 'prop-types';
import Hex from '../models/Hex';
import Point from '../models/Point';
import HexText from './HexText';
import HexUtils from '../HexUtils';
import Board from '../../models/Board';
import extractBrush from 'react-native-svg/src/lib/extract/extractBrush';
import Svg, { Path, Polygon, G } from 'react-native-svg';
import utils  from '../../utils/utils.js';
import { playSound } from '../../utils/soundUtils.js';
import theme from '../../styles/theme.js';
const AnimatedPoly = Animated.createAnimatedComponent(Polygon);
const AnimatedG = Animated.createAnimatedComponent(G);
import { withThemeContext } from '../../contexts/ThemeContext';
import { withBoardContext } from '../../contexts/BoardContext';

class Tile extends Component {
  static propTypes = {
    q: PropTypes.number.isRequired,
    r: PropTypes.number.isRequired,
    s: PropTypes.number.isRequired,
    letter: PropTypes.string,
    fill: PropTypes.string,
    isSelected: PropTypes.bool,
    data: PropTypes.object,
    onClick: PropTypes.func,
    children: PropTypes.node
  };

  static contextTypes = {
    layout: PropTypes.object, // TODO Shape
    points: PropTypes.string
  };

  constructor(props, context) {
    super(props, context);
    const { q, r, s, letter, isSelected, isPerm, wordIndex, boardPanResponder } = props;
    const hex = new Hex(q, r, s, letter, isSelected, isPerm, wordIndex);
    const { layout } = context;
    const pixel = HexUtils.hexToPixel(hex, layout);
    const maxScale = 1.5;


    this.state = {
      hex,
      pixel,
      offsetTop: 0,
      offsetLeft: 0,
      dragTargetTiles: [],
      toSwapTiles: [],
      fillValue: new Animated.Value(0),
      opacityValue: new Animated.Value(1),
      lastLetter: props.letter
    };

    this.state.fillValue.addListener(() => {
      const { fillValue } = this.state;
      const color = fillValue.interpolate({
        inputRange: [0, 1],
        outputRange: [this.lastFill, this.currFill],
      });
      if (this.polyRef) {
        this.polyRef.setNativeProps({
          fill: extractBrush(color.__getAnimatedValue()),
        });
      }
    });

    // animation vars
    this.animatingColor = false;
    this.lastFill = utils.hexToRgbA(this.assignTileColor(props.tileOwner, props.isPerm));
    this.currFill = this.lastFill;
    this.transitionDuration = 400;
  }

  // Only update when these change
  shouldComponentUpdate(nextProps, nextState) {
    const { hex } = this.state;
    const { letter, isSelected, isPerm, wordIndex, isGame, isSolved } = this.props;
    const { isTextureMode, isColorBlindMode, isDarkMode } = this.props.theme;

    let color = utils.getPropertyByKeys(this.props.board, [`word${wordIndex}`, 'color']);
    let nextColor = utils.getPropertyByKeys(nextProps.board, [`word${wordIndex}`, 'color']);

    return letter !== nextProps.letter ||
           isSelected !== nextProps.isSelected ||
           isPerm !== nextProps.isPerm ||
           wordIndex !== nextProps.wordIndex ||
           hex.wordIndex !== nextState.hex.wordIndex ||
           isGame !== nextProps.isGame ||
           isSolved !== nextProps.isSolved ||
           isTextureMode !== nextProps.theme.isTextureMode ||
           isDarkMode !== nextProps.theme.isDarkMode ||
           isColorBlindMode !== nextProps.theme.isColorBlindMode ||
           color !== nextColor;
  }

  componentDidUpdate(prevProps, prevState) {
    const { q, r, s, letter, isSelected, isPerm, wordIndex } = this.props;
    if (letter !== prevProps.letter ||
        isSelected !== prevProps.isSelected ||
        isPerm !== prevProps.isPerm ||
        wordIndex !== prevProps.wordIndex) {
      const hex = new Hex(q, r, s, letter, isSelected, isPerm, wordIndex);
      this.setState({ hex });
    }
  }

  onClick() {
    const { chooseStartTileMode, setChooseStartTileMode, setWord, wordIndex, selectTile } = this.props.board;
    const { setCurrentTile, setTile, isGame, letter } = this.props;
    const { hex } = this.state;

    const playNote = (i) => {
      if (i === 1) playSound('note2');
      if (i === 2) playSound('note1');
      if (i === 3) playSound('note2');
      if (i === 4) playSound('note3');
      if (i === 5) playSound('note4');
      if (i === 6) playSound('note5');
      if (i === 7) playSound('note4');
      if (i === 8) playSound('note5');
      if (i === 9) playSound('note6');
      if (i === 10) playSound('note7');
      if (i === 11) playSound('note8');
      if (i === 12) playSound('note6');
      if (i === 13) playSound('note7');
      if (i >= 14) {
        let odd = i % 2 === 0;
        if (odd) {
          playSound('note8');
        } else {
          playSound('note8alt');
        }
      }
    }

    if (isGame) {
      let shouldPlayNote = !utils.getPropertyByKeys(this.props.board, [`word${hex.wordIndex}`, 'color']);
      selectTile(hex);
      let letterIndex = (utils.getPropertyByKeys(this.props.board, [`word${hex.wordIndex}selected`]) || []).length;
      if (shouldPlayNote) {
        playNote(letterIndex);
      }

    } else if (chooseStartTileMode) {
      let { index, word, letterIndex, lastHex } = chooseStartTileMode;
      let wordObj = utils.getPropertyByKeys(this.props.board, [`word${index}`]);
      let newHex = hex;
      if (!newHex.wordIndex &&
          (!lastHex ||
           HexUtils.distance(newHex, lastHex) === 1)) {
        playNote(letterIndex + 2);
        newHex.letter = word[letterIndex];
        newHex.wordIndex = index;
        setTile(newHex);
        if (letterIndex === 0) {
          setWord(newHex, wordObj.word, wordObj.color, index);
        }
        if (letterIndex === word.length - 1) {
          setChooseStartTileMode(false);
        } else {
          setChooseStartTileMode(index, letterIndex + 1, hex);
        }
      }

    }
  }

  onPointerEnter() {
    const { isPointerDown } = this.props.board;
    if (isPointerDown) {
      this.onClick();
    }
  }

  assignTileColor() {
    const { tilesetTheme, themeStyle, isColorBlindMode, getColorBlindColor } = this.props.theme;
    const { hex } = this.state;
    const { wordIndex } = this.props;
    let color = utils.getPropertyByKeys(this.props.board, [`word${wordIndex}`, 'color']);

    if (color) {
      if (isColorBlindMode) {
        color = getColorBlindColor(hex.wordIndex) || color;
      }
      return color;
    }

    return themeStyle.TILE_COLOR;
  }

  assignStrokeColor() {
    const { tilesetTheme, themeStyle } = this.props.theme;
    const { isSelected, isHighlighted } = this.props;

    if (isSelected) {
      return themeStyle.TEXT_COLOR;
    } else if (isHighlighted) {
      return '#707070';
    } else {
      return 'none'
    }
  }


  // Disable certain hexes in editor to constrain all puzzles to be within puzzle game area.
  isOutOfEditorBounds() {
    const { q, r } = this.props;

    // Hardcoded hex positions that are not used in a 91-size hex grid in editor
    let outOfBoundsArray = [
      { q: -3, r: -2 },
      { q: 5, r: -2 },
      { q: -4, r: -1 },
      { q: 5, r: -1 },
      { q: -4, r: 0 },
      { q: -5, r: 0 },
      { q: 4, r: 0 },
      { q: 5, r: 0 },
      { q: -5, r: 1 },
      { q: 4, r: 1 },
      { q: -5, r: 2 },
      { q: 3, r: 2 },
      // { q: 0, r: 5 },
      // { q: -1, r: 5 },
      // { q: 1, r: 4 },
      // { q: -2, r: 5 },
      // { q: 0, r: 4 },
      // { q: 2, r: 3 },
      // { q: 0, r: -5 },
      // { q: -1, r: -4 },
      // { q: 1, r: -5 },
      // { q: -2, r: -3 },
      // { q: 0, r: -4 },
      // { q: 2, r: -5 },
    ];

    for (let i = 0; i < outOfBoundsArray.length; i++) {
      if (q === outOfBoundsArray[i].q &&
          r === outOfBoundsArray[i].r) {
        return true;
      }
    }
    return false;
  }


  // The touch polygon is an invisible polygon that handles all the touch interactions
  renderTouchPolygon() {
    const { setCurrentTile, setTile } = this.props;
    const { disabled, playerCurrent, userID, tileOwner, isPerm, isGame, isSolved } = this.props;
    const { points } = this.context;
    const { hex, pixel, offsetLeft, offsetTop } = this.state;

    // Don't render if no letter
    let letter = utils.getPropertyByKeys(hex, ['letter']);
    if (isSolved ||
        Platform.OS !== 'web') {
      return null;
    }

    // Web tile positioning
    let transform = `translate(${pixel.x + offsetLeft} ${pixel.y + offsetTop})`;

    let touchEvents = {
      onPointerDown: this.onClick.bind(this),
      onPointerEnter: this.onPointerEnter.bind(this)
    }
    if (utils.isTouchDevice() && isGame) {
      touchEvents = {};
    }

    let touchPolygon = (
      <Polygon
        data-key={Board.positionToString(hex)}
        key={`${pixel.x}${pixel.y}-touch`}
        transform={transform}
        {...touchEvents}
        points={points}
        fill={theme.WHITE}
        fillOpacity={0}
      />
    )
    return touchPolygon;
  }

  render() {
    const { board, boardSize, className, isSelected, isHighlighted, isHidden, highlightedTiles, disabled, letter, playerCurrent, tileOwner, isPerm, opacity, shouldAnimate, isGame, colorOverride, isSolved, isStart } = this.props;
    const { points, layout } = this.context;
    const { isTextureMode } = this.props.theme;
    const { hex, pixel, offsetLeft, offsetTop, toSwapTiles, opacityValue, lastLetter } = this.state;
    // const fillId = (fill) ? `url(#${fill})` : null; For pictures?

    if ((isGame && !letter) ||
        (!isGame && this.isOutOfEditorBounds())) {
      return null;
    }

    // Fill Color
    let fill = disabled || !shouldAnimate ? this.assignTileColor(tileOwner, isPerm) : this.currFill;
    if (colorOverride) fill = colorOverride

    let showTexture = isTextureMode && hex.wordIndex;
    let fillOpacity = letter ? 1 : 0.4;
    let hexText = (
      <HexText
        x={pixel.x + offsetLeft}
        y={pixel.y + offsetTop}
        color={fill}
        boardSize={boardSize}
        isStart={isStart}
        isSelected={isSelected}
        isTextured={showTexture}
        isWord={!!hex.wordIndex}
      >
        { letter }
      </HexText>
    );

    if (disabled) {
      hexText = null;
    }

    if (isHidden) {
      fillOpacity = 0;
    }

    let strokeWidth = 0.6;
    if (boardSize) {
      strokeWidth *= 0.1837 * boardSize
    }

    let hasBorder = isSelected || isHighlighted;
    if (!hasBorder || isSolved) strokeWidth = 0;

    // Web tile positioning + scaling to keep hex size constant when border is on
    let transform = `translate(${pixel.x + offsetLeft} ${pixel.y + offsetTop}) scale(${hasBorder ? .96 : 1})`;

    let texturePoly = null;
    if (showTexture) {
      let fillKey = "url(#texture-brick)";
      if (hex.wordIndex === 1) fillKey = "url(#texture-scale)";
      if (hex.wordIndex === 2) fillKey = "url(#texture-chevron)";
      if (hex.wordIndex === 3) fillKey = "url(#texture-stripe)";
      if (hex.wordIndex === 4) fillKey = "url(#texture-hexagon)";
      if (hex.wordIndex === 5) fillKey = "url(#texture-geometric)";
      texturePoly = (
        <Polygon
          key={`${pixel.x}${pixel.y}-hex-texture`}
          points={points}
          fill={fillKey}
          fillOpacity={fillOpacity}
          stroke={this.assignStrokeColor()}
          strokeWidth={strokeWidth}
          transform={transform}
          style={{ transition: 'fill .3s, fill-opacity .3s' }}
        />
      )
    }

    return [
      <AnimatedPoly
        key={`${pixel.x}${pixel.y}-hex`}
        ref={ref => (this.polyRef = ref)}
        points={points}
        fill={fill}
        fillOpacity={fillOpacity}
        stroke={this.assignStrokeColor()}
        strokeWidth={strokeWidth}
        transform={transform}
        style={{ transition: 'fill .3s, fill-opacity .3s' }}
      />,
      texturePoly,
      <AnimatedG
        key={`${pixel.x}${pixel.y}-wrapper`}
        fillOpacity={opacity !== undefined ? opacity : opacityValue}
      >
        { hexText }
      </AnimatedG>,
      this.renderTouchPolygon(),
    ]
  }
}

export default withThemeContext(withBoardContext(Tile));

        // onPressIn={disabled ? null : () => this.onClick()}
        // delayPressIn={0}
        // delayPressOut={0}
const styles = StyleSheet.create({

});
