import Animated, {
  createAnimatedComponent,
  cancelAnimation,
  useAnimatedGestureHandler,
  useSharedValue,
  useAnimatedStyle,
  useDerivedValue,
  withTiming,
  withRepeat,
  getRelativeCoords,
  measure,
  Easing,
} from 'react-native-reanimated';
import React, { Component, useState, useEffect } from 'react';
import Svg, { Path, G } from 'react-native-svg';
import { View, StyleSheet } from 'react-native';
import LockIcon from './LockIcon';
import theme from '../../styles/theme';
import ThemedText from '../../components/ThemedText';
import Utils from '../../utils/utils';
import { withThemeContext } from '../../contexts/ThemeContext';

const AnimatedSvg = Animated.createAnimatedComponent(Svg);

const TierIcon = (props) => {
  const { color, threshold, size, isPulsing, isUnlocking } = props;
  const { themeStyle } = props.theme;

  const scale = useSharedValue(1);
  const opacity = useSharedValue(.8);
  const animatedStyle = useAnimatedStyle(() => {
    return {
      transform: [{ scale: scale.value }],
      opacity: opacity.value,
    };
  });

  const lockOpacity = useSharedValue(1);
  const lockTranslateY = useSharedValue(0);
  const lockAnimatedStyle = useAnimatedStyle(() => {
    return {
      transform: [{ translateY: lockTranslateY.value }],
      opacity: lockOpacity.value,
    };
  });

  let defaultSize = 80;
  let finalSize = size || defaultSize;
  let sizeRatio = finalSize / defaultSize;

  let finalTextColor = 'black';
  if (Utils.checkIfBelowLuminanceThreshold(color)) finalTextColor = 'white';

  useEffect(() => {
    let duration = 1000;

    if (isPulsing) {
      scale.value = withRepeat(withTiming(2, { duration: duration, easing: Easing.bezier(0.24, 0, 0.38, 1) }), -1, false);
      opacity.value = withRepeat(withTiming(0, { duration: duration, easing: Easing.bezier(0.24, 0, 0.38, 1) }), -1, false);
    }

    if (isUnlocking) {
      lockOpacity.value = withTiming(0, { duration: 300, easing: Easing.bezier(0.24, 0, 0.38, 1) });
      lockTranslateY.value = withTiming(-finalSize / 2, { duration: 300, easing: Easing.bezier(0.24, 0, 0.38, 1) });
    }
  }, [isPulsing, isUnlocking]);

  let animatedHex = null;
  if (isPulsing) {
    animatedHex = (
      <Animated.View style={[styles.animatedContainer, animatedStyle]}>
        <Svg width={finalSize} height={finalSize} viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
          <Path d="M40 0L74.641 20L74.641 60L40 80L5.35898 60L5.35898 20L40 0Z" fill={color}/>
        </Svg>
      </Animated.View>
    )
  }

  let lockEl = (
    <View style={styles.lockContainer}>
      <LockIcon size={28 * sizeRatio} color={themeStyle.TEXT_COLOR}/>
    </View>
  );
  if (isUnlocking) {
    lockEl = (
      <Animated.View style={[styles.lockContainer, lockAnimatedStyle]}>
        <LockIcon size={28 * sizeRatio} color={themeStyle.TEXT_COLOR}/>
      </Animated.View>
    )
  }

  return (
    <View style={{ width: finalSize, height: finalSize, ...theme.flexCenter }}>
      <Svg width={finalSize} height={finalSize} viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
        <Path d="M40 0L74.641 20L74.641 60L40 80L5.35898 60L5.35898 20L40 0Z" fill={color}/>
      </Svg>
      { animatedHex }
      <View style={{ position: 'absolute', paddingLeft: 1 * sizeRatio, ...theme.flexCenter }}>
        <ThemedText style={{ fontSize: 30 * sizeRatio, fontFamily: 'Nunito-ExtraBold', color: finalTextColor }}>{ threshold }</ThemedText>
      </View>
      { lockEl }
    </View>
  );
}

export default withThemeContext(TierIcon);

const styles = StyleSheet.create({
  animatedContainer: {
    position: 'absolute',
    zIndex: -1,
    opacity: .5,
  },
  lockContainer: {
    position: 'absolute',
    bottom: -3,
    right: -5
  }
});
