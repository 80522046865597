import { Dimensions, Platform } from 'react-native';
const { width, height } = Platform.OS === 'web' ? Dimensions.get('window') : Dimensions.get('screen');

const smallScreenWidth = 450;
const largeScreenWidth = 750;

const isSmallScreen = width < smallScreenWidth;
const isLargeScreen = width >= largeScreenWidth;

const flexCenter = {
  alignItems: 'center',
  justifyContent: 'center'
};

const shadowHalf = {
  shadowColor: '#000',
  shadowOffset: { width: 0, height: 1 },
  shadowOpacity: 0.25,
  shadowRadius: 1,
  elevation: 2,
}

const YELLOW_HONEY = '#FFB700';
const YELLOW_HONEY_INCOMPLETE = '#FAF7ED';

const WHITE = '#FFFFFF';
const WHITE_ALMOST = '#FAFAFA';
const GRAY_1 = '#F3F3F3';
const GRAY_2 = '#DEDEDE';
const GRAY_3 = '#BCBCBC';
const GRAY_4 = '#7D7D7D';
const GRAY_5 = '#1D1D1D';
const BLACK_ALMOST = '#232323';
const BLACK = '#000000';

export default {
  SCREEN_WIDTH: width,
  SCREEN_HEIGHT: height,
  smallScreenWidth: smallScreenWidth,
  largeScreenWidth: largeScreenWidth,
  isSmallScreen: isSmallScreen,
  isLargeScreen: isLargeScreen,
  isSE: height < 570,

  GRAY_1,
  GRAY_2,
  GRAY_3,
  GRAY_4,

  YELLOW_HONEY,
  YELLOW_HONEY_INCOMPLETE,

  BLACK: '#000',
  BLACK_ALMOST,
  WHITE: '#FFFFFF',
  WHITE_ALMOST,

  FONT_SIZE_H1: 40, //Regular
  FONT_SIZE_H2: 24, //Regular
  FONT_SIZE_H3: 18, //Medium and Regular
  FONT_SIZE_REGULAR: 14, //Medium and Regular
  FONT_SIZE_SMALL: 12, //Medium and Semibold

  FONT_DEFAULT_REGULAR: 'Nunito-Regular',
  FONT_DEFAULT_SEMIBOLD: 'Nunito-SemiBold',

  LIGHT_THEME: {
    BG: WHITE_ALMOST,
    CARD_BG: WHITE,
    TEXT_COLOR: BLACK_ALMOST,
    TILE_COLOR: GRAY_2,
    BORDER_COLOR: GRAY_2,
    WORDS_BG: WHITE,
    PLACEHOLDER_TEXT_COLOR: GRAY_4
  },
  DARK_THEME: {
    BG: BLACK_ALMOST,
    CARD_BG: BLACK,
    TEXT_COLOR: WHITE_ALMOST,
    TILE_COLOR: GRAY_3,
    BORDER_COLOR: GRAY_4,
    WORDS_BG: GRAY_5,
    PLACEHOLDER_TEXT_COLOR: GRAY_3
  },

  flexCenter: flexCenter,
  row: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  column: {
    flexDirection: 'column',
  },
  whiteHeaderText: {
    marginTop: 60,
    color: '#fff',
    fontSize: 24,
    fontWeight: '500',
    lineHeight: 29,
  },
  card: {
    padding: 20,
    marginTop: 20,
    marginHorizontal: 20,
  },
  ExitButtonContainer: {
    position: 'absolute',
    top: 0,
    right: 0
  },
  searchInput: {
    height: 40,
    backgroundColor: '#FFF',
    paddingLeft: 20,
    paddingRight: 20
  },
  Triangle: {
    width: 0,
    height: 0,
    backgroundColor: 'transparent',
    borderStyle: 'solid',
    borderLeftWidth: 10,
    borderRightWidth: 10,
    borderBottomWidth: 10,
    borderLeftColor: 'transparent',
    borderRightColor: 'transparent',
    borderBottomColor: 'red'
  },
  rowOption: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  exitBtn: {
    position: 'absolute',
    top: 15,
    right: 15,
    zIndex: 10
  },
  backgroundImage: {
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: -1
  },
  shadowNone: {
    shadowRadius: 0,
    shadowOpacity: 0,
    elevation: 0
  },
  shadowHalf: shadowHalf,
  shadow1: {
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 4 },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 4,
  },
  bg: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    resizeMode: 'stretch',
  },
  fullAbsolute: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
  overlay: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    backgroundColor: '#000000',
    opacity: 0.5,
  },
  safariScreenFix: {
    position: 'fixed',
    overflow: 'hidden',
    width: '100vw',
    height: '100vh'
  }
}