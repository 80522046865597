import { useState } from 'react'
import { StyleSheet, Text, View, Image, TouchableOpacity, Platform } from 'react-native';
import * as Clipboard from 'expo-clipboard';
import SettingsGear from '../assets/SettingsGear';
import InfoIcon from '../assets/InfoIcon';
import Button from '../components/Button';
import ThemedText from '../components/ThemedText';
import SettingsMenu from './SettingsMenu';
import utils from '../utils/utils';
import theme from '../styles/theme';
import Analytics from '../analytics/analytics';
import Packs, { Tiers } from '../staticData/packData.js';
import CommunityPackAuthors from '../staticData/communityPackAuthors';
import EditorRules from './EditorRules';
import KeyIcon from '../assets/icons/KeyIcon';
import BackIcon from '../assets/icons/BackIcon';
import Daily from '../staticData/dailyData';
import { withBoardContext } from '../contexts/BoardContext';
import { withSaveContext } from '../contexts/SaveContext';
import { withThemeContext } from '../contexts/ThemeContext';
import { playSound } from '../utils/soundUtils';
import { useWindowDimensions } from 'react-native';

const Header = (props) => {
  /*
    navigate: passed navigation function
    packID: packID,
    customGameID: customGameID,
  */
  const [showEditorRulesMode, setShowEditorRulesMode] = useState(false);
  const [showSettingsMode, setShowSettingsMode] = useState(false);

  const [linkSharedMode, setLinkSharedMode] = useState(false);
  const levels = utils.getPropertyByKeys(Packs, [props.packID]) || Packs['starter'];
  const { themeStyle, isDarkMode } = props.theme;
  const authors = utils.getPropertyByKeys(CommunityPackAuthors, [props.packID]);
  const { width: screenWidth, height: screenHeight } = useWindowDimensions();

  const renderLogo = () => {
    const { levelIndex } = props.board;
    const { isDarkMode } = props.theme;
    const { isPacks } = props;

    if (Platform.OS !== 'web' || !isPacks) return null;

    let finalStyle = {
      position: 'absolute',
      left: 10,
    }

    let iconStyle = {
      width: 150,
      height: 38,
    };

    // Default case in pack screen
    let iconSource = require('../assets/images/honeycomb-logo-full.png');
    if (isDarkMode) iconSource = require('../assets/images/honeycomb-logo-full-dark.png');

    if (!theme.isLargeScreen) {
      iconSource = require('../assets/images/honeycomb-logo-small.png');
      if (isDarkMode) iconSource = require('../assets/images/honeycomb-logo-small-dark.png');
      finalStyle.left = 8;
      iconStyle.width = 140 / 4;
      iconStyle.height = 167 / 4;
    }

    // Always show logo on desktop, but only on pack screen on mobile
    if ((theme.isLargeScreen || props.isPacks) ||
        (props.routeName !== 'CustomGame' && !theme.isLargeScreen && props.packID && props.packID === 'starter' && levelIndex === 0)) {
      return (
        <TouchableOpacity
          style={finalStyle}
          onPress={() => props.navigate('Packs')}
        >
          <Image style={iconStyle} source={iconSource}/>
        </TouchableOpacity>
      );
    }
  }

  const renderBackButton = () => {
    const { firstLevelCompleted } = props;
    const { levelIndex } = props.board;
    const { themeStyle } = props.theme;

    let backBtn = null;
    if (props.routeName !== 'CustomGame' &&
        (props.packID && props.packID === 'starter' && levelIndex === 0 && !firstLevelCompleted)) return null;

    let left = 10;
    if(Platform.OS === 'web' && theme.isLargeScreen) left = ((theme.SCREEN_WIDTH - theme.smallScreenWidth) / 2) + 10;
    if (!props.isPacks) {
      // Appears left-justified above the 450-wide game area, with 10 padding
      backBtn = (
        <View style={{position: 'absolute', left: left}}>
          <TouchableOpacity
            onPress={() => {
              playSound('click');
              props.navigate('Packs');
            }}
          >
            <BackIcon color={themeStyle.TEXT_COLOR} size={40}/>
          </TouchableOpacity>
        </View>
      );
    }
    return backBtn;
  }

  const renderGameHeader = () => {
    const { customGameID, routeName, isEditor } = props;
    const { levelIndex } = props.board;
    // Responsive width for header, gameboard, and word area
    let width = (theme.isLargeScreen && Platform.OS === 'web') ? Math.min(theme.smallScreenWidth, theme.SCREEN_WIDTH) : theme.SCREEN_WIDTH;

    const renderLevelText = () => {
      const { isPacks, packID, customGameID, navigate, routeName, packColor } = props;
      const { levelProgress, tierProgress, setTierProgress } = props.save;
      const { themeStyle } = props.theme;

      let authorEl = null;
      let levelText = levelIndex + 1;
      if (customGameID) {
        // Set color of level background
        let textContainerColor = theme.YELLOW_HONEY;
        if (packColor) textContainerColor = packColor;

        // Decide to show white or black text on this background
        let packTitleColor = 'black'
        if (utils.checkIfBelowLuminanceThreshold(textContainerColor)) {
          packTitleColor = 'white';
        }

        if (routeName === 'Pack' || routeName === 'Daily') {
          if (packID) {
            let dailyNumber = '';
            if (routeName === 'Daily') dailyNumber = ' ' + parseInt(Daily.getDailyIndex() + 1);
            levelText = `${ utils.parsePackKey(packID) }${dailyNumber} ${'\u2022'} ${ levelIndex + 1 } / ${ levels.length }`;
            if (packID.indexOf('community') >= 0) {
              let authorName = authors && authors[levelIndex];
              if (authorName) authorEl = (
                <ThemedText style={{ color: packTitleColor }}>Author: { authorName }</ThemedText>
              );
            }
          }

          return (
            <View style={{ backgroundColor: textContainerColor, borderRadius: 12, paddingVertical: 6, paddingHorizontal: 12, ...theme.flexCenter }}>
              <ThemedText style={[styles.buttonText, { fontSize: 18, color: packTitleColor, fontFamily: 'Nunito-Bold', marginBottom: 1, marginTop: -1 } ]}>{ levelText }</ThemedText>
              { authorEl }
            </View>
          )

        } else {
          return (
            <View style={{ flexDirection: 'row', ...theme.flexCenter }}>
              <View style={{ backgroundColor: themeStyle.TEXT_COLOR, borderRadius: 12, paddingVertical: 6, paddingHorizontal: 12 }}>
                <ThemedText style={[styles.buttonText, { fontSize: 16, color: themeStyle.BG } ]}>Custom Level</ThemedText>
              </View>
            </View>
          )
        }

      } else if (isPacks) {
        // Calculate total progress
        let totalCompleted = utils.calcTotalLevelsCompleted(Packs, levelProgress);

        let threshold = 0;
        for (let key in Tiers) {
          let tier = Tiers[key];
          if (!tierProgress[key]) {
            threshold = tier.threshold;
            break;
          }
        }

        return (
          <View style={[styles.totalCounter, { backgroundColor: themeStyle.TEXT_COLOR }]}>
            <KeyIcon color={themeStyle.BG} size={24}/>
            <ThemedText style={{ color: themeStyle.BG, fontFamily: 'Nunito-Bold', fontSize: 24, marginLeft: 4 }}>{ totalCompleted }</ThemedText>
          </View>
        )

      } else {
        let levelText = "Puzzle Maker";
        if (packID) {
          if (packID.indexOf('community') >= 0) {
            let authorName = authors && authors[levelIndex];
            if (authorName) authorEl = (
              <ThemedText style={{ color: 'white'}}>Author: { authorName }</ThemedText>
            );
          }
          levelText = `${ utils.parsePackKey(packID) } ${'\u2022'} ${ levelIndex + 1 } / ${ levels.length }`;
        }
        return (
          <View style={{ backgroundColor: theme.DARK_THEME.CARD_BG, borderRadius: 12, paddingVertical: 6, paddingHorizontal: 12, ...theme.flexCenter }}>
            <ThemedText style={[styles.buttonText, { fontSize: 18, color: '#FFF', fontFamily: 'Nunito-Bold' } ]}>{ levelText }</ThemedText>
            { authorEl }
          </View>
        )
      }
    }

    let rulesBtn = null;
    if (isEditor) rulesBtn = (
      <TouchableOpacity
        style={[styles.rulesButton, { borderColor: themeStyle.TEXT_COLOR }]}
        onPress={() => {
          playSound('click');
          setShowEditorRulesMode(true)
        }}
      >
        <InfoIcon iconColor={themeStyle.TEXT_COLOR} markColor={themeStyle.BG} size={42}/>
      </TouchableOpacity>
    );

    let settingsBtn = (
      <TouchableOpacity
        style={styles.settingsButton}
        onPress={() => {
          playSound('click');
          setShowSettingsMode(true)
        }}
      >
        <SettingsGear color={themeStyle.TEXT_COLOR} size={42}/>
      </TouchableOpacity>
    );

    let nextBtn = null;

    if (props.completionMinimized) {
      settingsBtn = null;

      let buttonTitle = 'Done'
      let finalOnPress = () => props.navigate('Packs');

      if (props.completionOnPress) {
        if (!customGameID ||
            customGameID && (routeName === 'Pack' || (routeName === 'Daily' && levelIndex === 0))) {
          buttonTitle = 'Next';
          finalOnPress = () => props.completionOnPress();
        }
      }

      nextBtn = (
        <View style={{ position: 'absolute', right: 10 }}>
          <Button
            style={{ backgroundColor: theme.YELLOW_HONEY }}
            title={buttonTitle}
            onPress={() => finalOnPress()}
          />
        </View>
      )
    }

    return (
      <View style={[styles.gameHeader, { width: width }]}>
        { renderLevelText() }
        { rulesBtn }
        { settingsBtn }
        { nextBtn }
      </View>
    );
  }

  const renderEditorButtonsEl = () => {
    const renderEditorBtn = () => {
      if (props.isEditor || (props.isGame && props.routeName !== 'CustomGame')) return null;

      let finalStyle = {
        position: 'absolute',
        right: 10,
        top: 10,
        flexDirection: 'column'
      }

      let puzzleMakerText = "Open Puzzle Maker";
      if (theme.isLargeScreen && (theme.SCREEN_WIDTH < theme.largeScreenWidth + 100) && Platform.OS === 'web') puzzleMakerText = "Puzzle Maker";

      return (
        <View style={finalStyle}>
          <Button
            color={'#2EF9C0'}
            title={puzzleMakerText}
            onPress={() => {
              props.navigate('Editor');
              Analytics.logAppButtonPressed('Open Puzzle Maker');
            }}
          />
        </View>
      )
    }

    const renderExport = () => {
      const { exportBoard, checkValidPuzzle, numWords } = props.board;

      if (theme.SCREEN_WIDTH < theme.largeScreenWidth + 100) return null;

      let alertEl = null;
      if (!checkValidPuzzle()) {
        alertEl = <Text style={{ width: 160, marginTop: 10, fontFamily: 'Nunito-SemiBold', color:'#FF5567', fontSize: 14}}>All words must be 3+ letters, in the dictionary, and placed on the board.</Text>
      }

      return (
        <View style={{ position: 'absolute', top: 10, right: 10 }}>
          <Button
            disabled={numWords < 1 || !checkValidPuzzle()}
            color={linkSharedMode ? '#9EEBE3' : '#74E2D7'}
            title={linkSharedMode ? 'Link Copied!' : 'Share Puzzle Link'}
            onPress={() => {
              if (checkValidPuzzle()) {
                let code = exportBoard();
                let url = 'https://honeycomb.goodgameshelf.com/game/' + code;
                if (Platform.OS === 'web') url = window.location.origin + '/game/' + code;
                // Clipboard.setString(url);
                utils.setClipboardString(url);
                Analytics.logCustomPuzzleShared(numWords, code, url);
                setLinkSharedMode(true);
                setTimeout(() => {
                  setLinkSharedMode(false);
                }, 3000);
              }
            }}
          />
          { alertEl }
        </View>
      );
    }

    // Variable position of the editorButtons for web
    if (theme.isLargeScreen && Platform.OS === 'web') {
      if (props.isEditor) return renderExport();
      else return renderEditorBtn();
    }
  }

  // Handle mobile overflow issue for switches
  let containerStyle = { ...styles.fixedContainer };
  if ((showEditorRulesMode || showSettingsMode)) containerStyle.height = theme.SCREEN_HEIGHT;

  // Settings and rules are overlaid over entire screen
  let settingsMenuEl = null;
  if (showSettingsMode) settingsMenuEl = (
    <SettingsMenu
      setShowSettingsMode={setShowSettingsMode}
      navigate={props.navigate}
      isEditor={props.isEditor}
    />
  )

  let editorRulesEl = null;
  if (showEditorRulesMode) editorRulesEl = (
    <EditorRules
      setShowEditorRulesMode={setShowEditorRulesMode}
    />
  );

  return (
    <View style={containerStyle}>
      <View style={[styles.container, { backgroundColor: themeStyle.BG } ]}>
        {/* Persistent Elements */}
        { renderEditorButtonsEl() }
        { renderGameHeader() }
        { renderBackButton() }
        { renderLogo() }
      </View>

      {/* Overlaid Elements */}
      { editorRulesEl }
      { settingsMenuEl }
    </View>
  );
}
export default withBoardContext(withSaveContext(withThemeContext(Header)));

const styles = StyleSheet.create({
  fixedContainer: {
    height: 60,
    width: '100%',
    zIndex: 1,
  },
  container: {
    position: 'absolute',
    paddingVertical: 10,
    left: 0,
    width: '100%',
    height: '100%',
    ...theme.flexCenter,
    // borderBottomWidth: 2,
    // borderColor: theme.GRAY_1
  },
  gameHeader: {
    height: 40,
    alignItems: 'center',
    justifyContent: 'center',
  },
  settingsButton: {
    position: 'absolute',
    right: 5,
    height: 40,
    width: 40,
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonText: {
    fontSize: 18,
    fontFamily: 'Nunito-SemiBold',
    textTransform: 'capitalize'
  },

  // Rules
  rulesButton: {
    position: 'absolute',
    paddingTop: 1,
    right: 55,
    height: 40,
    width: 40,
    alignItems: 'center',
    justifyContent: 'center',
  },
  ruleText: {
    fontSize: 16,
    fontFamily: 'Nunito-SemiBold',
    marginBottom: 10
  },
  rulesImage: {
    width: 128,
    height: 109,
    marginBottom: 20,
    alignSelf: 'center',
  },
  totalCounter: {
    height: 40,
    ...theme.row,
    paddingLeft: 10,
    paddingRight: theme.isSmallScreen ? 4 : 10,
    paddingTop: 1,
    borderRadius: 12,
  }
});
