import Animated, {
  useAnimatedStyle,
  useAnimatedProps
} from 'react-native-reanimated';
import React, { Component } from 'react';
import { Platform } from 'react-native';
import PropTypes from 'prop-types';
import Svg, { Defs, Pattern, G, Rect, Path, RadialGradient, Stop } from 'react-native-svg';
import utils from '../utils/utils.js';
import { withBoardContext } from '../contexts/BoardContext';

const HexGridPack = (props) => {
  const { width, height, viewBox = "-50 -50 100 100", isGame } = props;

  return (
    <Svg
      width={width} height={height}
      style={{ position: 'absolute', top: 0 }}
      viewBox={viewBox} version="1.1" xmlns="http://www.w3.org/2000/svg"
    >
      { props.children }
    </Svg>
  );
}

export default HexGridPack;
