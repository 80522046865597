import Hex from '../HexGrid/models/Hex';
import Board from '../models/Board';
import HexUtils from '../HexGrid/HexUtils.js';
import utils from '../utils/utils.js';
import Coords from '../coords.js';

export default class BoardUtils {

  static _negativeToLetter(n) {
    if (n === -1) return 'a';
    if (n === -2) return 'b';
    if (n === -3) return 'c';
    if (n === -4) return 'd';
    if (n === -5) return 'e';
    if (n === -6) return 'f';
    if (n === -7) return 'g';
    if (n === -8) return 'h';
    if (n === -9) return 'i';
    else return n;
  }

  static _letterToNegative(n) {
    if (n === 'a') return -1;
    if (n === 'b') return -2;
    if (n === 'c') return -3;
    if (n === 'd') return -4;
    if (n === 'e') return -5;
    if (n === 'f') return -6;
    if (n === 'g') return -7;
    if (n === 'h') return -8;
    if (n === 'i') return -9;
    else return n;
  }

  static _findBoardSizeFromNumHexes(n) {
    let numLeft = n;
    let index = 0;
    while (numLeft > 0) {
      index++;
      numLeft -= (6 * index);
    }
    return index - 1; // -1 because our boardSize is radius - 1
  }

  static _compressEmpties(str) {
    let newStr = '';
    let numChained = 0;
    for (var i = 0; i < str.length; i++) {
      let letter = str[i];
      if (letter === '_') {
        numChained++;
      } else {
        if (numChained > 0) {
          newStr += numChained;
          numChained = 0;
        }
        newStr += letter;
      }
    }
    if (numChained > 0) {
      newStr += numChained;
      numChained = 0;
    }

    return newStr;
  }

  static _decompressEmpties(str) {
    let newStr = '';
    let count = null;
    for (var i = 0; i < str.length; i++) {
      let letter = str[i];
      if (!isNaN(parseInt(letter))) {
        if (!count) count = letter;
        else count += letter;
      } else {
        if (count) {
          for (var j = 0; j < parseInt(count); j++) {
            newStr += '_';
          }
          count = null;
        }
        newStr += letter;
      }
    }
    if (count) {
      for (var j = 0; j < parseInt(count); j++) {
        newStr += '_';
      }
    }

    return newStr;
  }

  static _compressHex(hex) {
    if (hex.letter.length === 0) return '';
    let str = '';
    str = `${BoardUtils._negativeToLetter(hex.q)}${BoardUtils._negativeToLetter(hex.r)}${BoardUtils._negativeToLetter(hex.s)}`;
    return str;
  }

  static _decompressHex(str) {
    let hex = new Hex(BoardUtils._letterToNegative(str[0]), BoardUtils._letterToNegative(str[1]), BoardUtils._letterToNegative(str[2]));
    return hex;
  }

  static _decompress(str) {
    // console.log("DECOMPRESS", str);
    let boardData = {};
    let boardSize = BoardUtils._findBoardSizeFromNumHexes(str.length);
    let spiral = HexUtils.spiral(boardSize + 1);
    for (var i = 0; i < str.length; i++) {
      let letter = str[i];
      if (letter === '_') letter = '';
      let hex =
        new Hex(
          spiral[i].q,
          spiral[i].r,
          spiral[i].s,
          letter,
          false,
          false,
          null,
        );
      let positionKey = Board.positionToString(hex);
      boardData[positionKey] = hex;
    }
    return boardData;
  }

  static _convertOrientation(str) {
    let decodedStr = utils.atob(str);
    let arr1 = decodedStr.split('$');
    let decompressedBoardString = BoardUtils._decompressEmpties(arr1[0])
    let boardData = BoardUtils._decompress(decompressedBoardString);
    let convertedBoardData = {};

    // Convert board spaces
    for (let key in boardData) {
      let hex = boardData[key];

      if (Coords.hasOwnProperty(key)) {
        key = Coords[key];
        let newHex = Board.stringToPosition(key);
        hex.q = newHex.q;
        hex.r = newHex.r;
        hex.s = newHex.s;
      }

      if (!convertedBoardData.hasOwnProperty(key) &&
          hex.letter) {
        convertedBoardData[key] = hex;
      }
    }

    // Convert words
    let wordData = {};
    let words = arr1[1].split(',');
    for (let i = 0; i < words.length; i++) {
      let word = words[i];
      if (word.length) {
        let arr2 = word.split('&');
        if (arr2[0].length) {
          let startHex = BoardUtils._decompressHex(arr2[0])
          let positionKey = Board.positionToString(startHex);

          if (Coords.hasOwnProperty(positionKey)) {
            positionKey = Coords[positionKey];
            let newHex = convertedBoardData[positionKey];
            startHex.q = newHex.q;
            startHex.r = newHex.r;
            startHex.s = newHex.s;
          }

          let wordObj = {
            word: convertedBoardData[positionKey].letter,
            startHex: convertedBoardData[positionKey],
            color: arr2[1],
          }
          convertedBoardData[positionKey].wordIndex = i+1;
          wordData[`word${i+1}`] = wordObj;
        }
      }
    }

    let numWords = 0;
    for (let key in wordData) {
      numWords++;
    }
    let boardSize = BoardUtils._findBoardSizeFromNumHexes(decompressedBoardString.length);

    let spiral = HexUtils.spiral(boardSize);
    let hexesString = '';
    for (var i = 0; i < spiral.length; i++) {
      let positionKey = Board.positionToString(spiral[i]);
      let stateHex = convertedBoardData[positionKey];
      let letter = utils.getPropertyByKeys(convertedBoardData, [positionKey, 'letter']);
      let letterLen = utils.getPropertyByKeys(convertedBoardData, [positionKey, 'letter', 'length']);
      if (letterLen === 1) {
        hexesString += letter;
      } else {
        hexesString += '_';
      }
    }

    // Compress empty (underscores)
    hexesString = BoardUtils._compressEmpties(hexesString);

    // Add word data
    hexesString += '$';
    for (let i = 1; i <= numWords; i++) {
      let word = wordData[`word${i}`];
      hexesString += `${BoardUtils._compressHex(word.startHex)}&${word.color},`
    }

    return utils.btoa(hexesString);
  }
}
