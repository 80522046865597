import React from 'react';
import { Animated } from 'react-native';
import LZString from 'lz-string';
import Hex from '../HexGrid/models/Hex';
import HexUtils from '../HexGrid/HexUtils.js';
import Dict from '../Dictionary/Dict'
import Board from '../models/Board';
import utils from '../utils/utils.js';
import boardUtils from '../utils/boardUtils.js';

export const BoardContext = React.createContext({});
export class BoardContextProvider extends React.Component {

  constructor(props) {
    super();

    this.state = {
      levelIndex: 0,
      boardSize: 5,
      numWords: 1,
      chooseStartTileMode: null,
      word1: {
        word: 'HAPPY',
        startHex: null,
        color: '#FF8587'
      },
      wordIndex: 1,
      levelCode: '',
      isPointerDown: false,
    }

    let isSelected = false;
    let isPerm = false;
    let tileColor = 'neutral'; //'neutral', 'blue', 'red'

    for (let r = -this.state.boardSize; r <= this.state.boardSize; r++) {
      let q1 = Math.max(-this.state.boardSize, -r - this.state.boardSize);
      let q2 = Math.min(this.state.boardSize, -r + this.state.boardSize);
      for (let q = q1; q <= q2; q++) {
        //console.log(LetterGen);
        let s = -q-r;

        let letter = '';
        let hex = new Hex(q, r, s, letter, isSelected, isPerm, tileColor);
        let positionKey = Board.positionToString(hex);
        this.state[positionKey] = hex;
      }
    }
  }

  _convertOldCode(str) {
    let decodedStr = LZString.decompressFromBase64(str);
    let arr1 = decodedStr.split('$');
    let words = arr1[0].split('!');
    let boardData = {};
    let startHexes = {};
    for (var i = 0; i < words.length; i++) {
      if (words[i].length) {
        let newHex = new Hex(boardUtils._letterToNegative(words[i][0]), boardUtils._letterToNegative(words[i][1]), boardUtils._letterToNegative(words[i][2]), words[i][3], false, false, null);
        let positionKey = Board.positionToString(newHex);
        boardData[positionKey] = newHex;

        if (words[i][4] !== 'n') {
          startHexes[words[i][4]] = newHex;
        }
      }
    }


    let boardSize = boardUtils._findBoardSizeFromNumHexes(Object.keys(boardData).length);
    let spiral = HexUtils.spiral(boardSize + 1);
    let hexesString = '';
    for (var i = 0; i < spiral.length; i++) {
      let positionKey = Board.positionToString(spiral[i]);
      let letter = utils.getPropertyByKeys(boardData, [positionKey, 'letter']);
      let letterLen = utils.getPropertyByKeys(boardData, [positionKey, 'letter', 'length']);
      if (letterLen === 1) {
        hexesString += letter;
      } else {
        hexesString += '_';
      }
    }

    hexesString += '$';
    let words2 = arr1[1].split(',');
    for (var i = 0; i < words2.length; i++) {
      if (words2[i].length) {
        let arr3 = words2[i].split('&');
        hexesString += `${boardUtils._compressHex(startHexes[i+1])}&${arr3[1]},`
      }
    }

    return utils.btoa(hexesString)
  }

  getTileKeys() {
    let keys = [];
    for (let q = -this.state.boardSize; q <= this.state.boardSize; q++) {
      let r1 = Math.max(-this.state.boardSize, -q - this.state.boardSize);
      let r2 = Math.min(this.state.boardSize, -q + this.state.boardSize);
      for (let r = r1; r <= r2; r++) {
        //console.log(LetterGen);
        let s = -q-r;

        let hex = new Hex(q, r, s);
        let positionKey = Board.positionToString(hex);
        keys.push(positionKey);
      }
    }
    return keys;
  }

  getBoardData() {
    let str = this.state.levelCode;
    let boardData = {};
    let arr = str.split('$');
    let decompressedBoardString = boardUtils._decompressEmpties(arr[0]);
    let boardSize = boardUtils._findBoardSizeFromNumHexes(decompressedBoardString.length);
    let spiral = HexUtils.spiral(boardSize + 1);
    for (var i = 0; i < str.length && i < spiral.length; i++) {
      let positionKey = Board.positionToString(spiral[i]);
      let currHex = this.state[positionKey];
      boardData[positionKey] = currHex;
    }
    return boardData;
  }

  setLevelIndex(index) {
    this.setState({ levelIndex: index });
  }

  setTile(hex) {
    let positionKey = Board.positionToString(hex);
    this.setState({
      [positionKey]: hex
    })
  }

  setWord(startHex, word, color, n) {
    this.setState({
      [`word${n}`]: {
        word: word,
        startHex: startHex,
        color: color,
      },
      numWords: Math.max(n, this.state.numWords),
    })
  }

  // Editor function
  removeWord(n) {
    const { numWords } = this.state;

    // Set the new states of the word objects
    let stateObj = {};
    for (let i = 1; i <= numWords; i++) {
      if (i === n) {
        // Delete the word
        stateObj[`word${i}`] = {
          word: '',
          startHex: null,
          color: '#999'
        }
      } else if (i > n) {
        // Remap all other words to new keys
        let wordData = this.state[`word${i}`];
        let newWordData = JSON.parse(JSON.stringify(wordData));
        stateObj[`word${i - 1}`] = newWordData;
      }
    }

    // Reduce the number of words by 1
    stateObj.numWords = numWords - 1;

    // Set the new states of the tiles
    let tileKeys = this.getTileKeys();
    for (let j = 0; j < tileKeys.length; j++) {
      let hex = this.state[tileKeys[j]];

      // Decrement word index for words with index higher than deleted word
      if (hex && hex.wordIndex && hex.wordIndex > n) {
        let newHex = JSON.parse(JSON.stringify(hex));
        newHex.wordIndex -= 1;
        stateObj[tileKeys[j]] = newHex;
      }
    }

    this.setState(stateObj);
  }

  setWordIndex(i) {
    if (i <= this.state.numWords &&
        i > 0) {
      this.setState({ wordIndex: i });
    }
  }

  setChooseStartTileMode(index, letterIndex, lastHex) {
    if (index) {
      let word = utils.getPropertyByKeys(this.state, [`word${index}`, 'word']);
      this.setState({
        chooseStartTileMode: {
          index: index,
          word,
          lastHex,
          letterIndex: letterIndex || 0,
        }
      });
    } else {
      this.setState({ chooseStartTileMode: null })
    }
  }

  setIsPointerDown(bool) {
    this.setState({ isPointerDown: bool });
  }

  resetBoard() {
    const { levelCode } = this.state;
    if (levelCode.length) {
      this.importBoard(levelCode);
    }
  }

  _clearBoard() {
    for (let q = -this.state.boardSize; q <= this.state.boardSize; q++) {
      let r1 = Math.max(-this.state.boardSize, -q - this.state.boardSize);
      let r2 = Math.min(this.state.boardSize, -q + this.state.boardSize);
      for (let r = r1; r <= r2; r++) {
        let s = -q-r;

        let hex = new Hex(q, r, s, '', false, false, null);
        let positionKey = Board.positionToString(hex);
        this.setState({ [positionKey]: hex });
      }
    }

    for (var i = 1; i <= this.state.numWords; i++) {
      this.setState({ [`word${i}selected`]: null });
    }
  }

  exportBoard() {
    let spiral = HexUtils.spiral(this.state.boardSize);
    let hexesString = '';
    for (var i = 0; i < spiral.length; i++) {
      let positionKey = Board.positionToString(spiral[i]);
      let stateHex = this.state[positionKey];
      let letter = utils.getPropertyByKeys(this.state, [positionKey, 'letter']);
      let letterLen = utils.getPropertyByKeys(this.state, [positionKey, 'letter', 'length']);
      if (letterLen === 1) {
        hexesString += letter;
      } else {
        hexesString += '_';
      }
    }

    // Compress empty (underscores)
    hexesString = boardUtils._compressEmpties(hexesString);

    const isStartHex = (hex) => {
      for (let i = 1; i <= this.state.numWords; i++) {
        if (hex.equals(wordData[`word${i}`].startHex)) {
          return true;
        }
      }
      return false;
    }

    // Add word data
    hexesString += '$';
    for (let i = 1; i <= this.state.numWords; i++) {
      let word = this.state[`word${i}`];
      hexesString += `${boardUtils._compressHex(word.startHex)}&${word.color},`
    }

    return utils.btoa(hexesString);
  }

  // Game Functions

  _findStartHex(boardData, wordIndex) {
    for (let key in boardData) {
      if (parseInt(boardData[key].wordIndex) === parseInt(wordIndex)) {
        return boardData[key];
      }
    }
    return null;
  }

  findMaxUsedRadius() {
    let boardData = this.getBoardData();
    let max = 0;
    for (let key in boardData) {
      let hex = boardData[key];
      if (hex && hex.letter && hex.letter.length) {
        max = Math.max(max, hex.q, hex.r, hex.s);
      }
    }
    return max;
  }

  importBoard(str) {
    if (!str ||
        str === this.state.levelCode) return false;

    // reset
    this._clearBoard();
    this.setState({
      wordIndex: 1,
      levelCode: str
    })

    let decodedStr = utils.atob(str);
    let arr1 = decodedStr.split('$');
    let decompressedBoardString = boardUtils._decompressEmpties(arr1[0])
    let boardData = boardUtils._decompress(decompressedBoardString);
    let wordData = {};
    let words = arr1[1].split(',');
    for (let i = 0; i < words.length; i++) {
      let word = words[i];
      if (word.length) {
        let arr2 = word.split('&');
        if (arr2[0].length) {
          let startHex = boardUtils._decompressHex(arr2[0])
          let positionKey = Board.positionToString(startHex);
          let wordObj = {
            word: boardData[positionKey].letter,
            startHex: boardData[positionKey],
            color: arr2[1],
          }
          boardData[positionKey].wordIndex = i+1;
          wordData[`word${i+1}`] = wordObj;
        }
      }
    }

    for (let key in boardData) {
      this.setState({
        [key]: boardData[key]
      })
    }

    let numWords = 0;
    for (let key in wordData) {
      numWords++;
      this.setState({
        [key]: wordData[key],
        [key + 'selected']: [wordData[key].startHex]
      })
    }
    let boardSize = boardUtils._findBoardSizeFromNumHexes(decompressedBoardString.length);
    this.setState({
      numWords,
      boardSize
    })
  }

  selectTile(hex) {
    const { isPointerDown } = this.state;
    let key = `word${this.state.wordIndex}selected`;
    let selected = this.state[key];
    if (!selected || !hex) return;
    let lastHex = selected[selected.length - 1];

    // Select
    if (hex.letter &&
        HexUtils.distance(hex, lastHex) === 1 &&
        hex.wordIndex !== this.state.wordIndex &&
        !hex.wordIndex) {
      hex.wordIndex = this.state.wordIndex;
      this.setTile(hex);
      selected.push(hex);
      this.setState({
        isPointerDown: true, // For dragging
        [key]: selected
      });

    // Deselect
    } else if (!isPointerDown &&
               selected.length > 1 &&
               hex.wordIndex > 0 &&
               hex.wordIndex === this.state.wordIndex) {
      for (var i = 0; i < selected.length; i++) {
        if (HexUtils.equals(hex, selected[i])) break;
      }
      this.unselectWord(hex.wordIndex, i);
      this.setState({ isPointerDown: true }); // For dragging

    // Change word
    } else if (!isPointerDown &&
               hex.wordIndex > 0 &&
               hex.wordIndex !== this.state.wordIndex) {
      this.setWordIndex(parseInt(hex.wordIndex));
      this.setState({ isPointerDown: true });

    // Enable dragging through start letter
    } else if (HexUtils.equals(hex, lastHex) &&
               hex.wordIndex === this.state.wordIndex) {
      this.setState({ isPointerDown: true });
    }
  }

  // Unselect word with wordIndex (i)
  //   j is optional for index to deselect to
  unselectWord(i, j) {
    let stateObj = {};
    let key = `word${i}selected`;
    let selected = this.state[key];
    if (selected) {
      let startIndex = Math.max(Math.min(j + 1 || 0, selected.length - 1), 1); // Keep in range
      for (let i = startIndex; i < selected.length; i++) {
        let hex = selected[i];
        hex.wordIndex = null;
        let positionKey = Board.positionToString(hex);
        stateObj[positionKey] = hex;
      }
      selected = selected.slice(0, startIndex - selected.length);
      stateObj[key] = selected;
    }
    this.setState(stateObj);
  }

  checkValidPuzzle() {
    const { numWords, boardSize } = this.state;
    let numHexes = 0;
    for (let q = -boardSize; q <= boardSize; q++) {
      let r1 = Math.max(-boardSize, -q - boardSize);
      let r2 = Math.min(boardSize, -q + boardSize);
      for (let r = r1; r <= r2; r++) {
        //console.log(LetterGen);
        let s = -q-r;

        let hex = new Hex(q, r, s);
        let positionKey = Board.positionToString(hex);
        if (utils.getPropertyByKeys(this.state, [positionKey, 'letter'])) numHexes++;
      }
    }

    let numLetters = 0;
    for (var i = 1; i <= numWords; i++) {
      let word = utils.getPropertyByKeys(this.state, [`word${i}`, 'word']);
      if (!word || !Dict.isInMainDictionary(word)) {
        return false;
      }
      numLetters += word.length;
    }

    if (numLetters !== numHexes) return false;
    return true;
  }

  checkCompletion() {
    const { levelCode } = this.state;
    if (!levelCode) return false;
    let hexesString = utils.atob(levelCode).split('$')[0];
    let decompressedBoardString = boardUtils._decompressEmpties(hexesString);
    let totalHexes = 0;
    for (var i = 0; i < decompressedBoardString.length; i++) {
      if (decompressedBoardString[i] !== '_') totalHexes++;
    }

    // Check for empty puzzle
    if (totalHexes === 0) return false;

    let hexesSelected = 0;
    for (let i = 1; i <= this.state.numWords; i++) {
      let selected = this.state[`word${i}selected`];
      if (!selected) return false;
      let currWord = '';
      for (let j = 0; j < selected.length; j++) {
        currWord += selected[j].letter;
      }
      hexesSelected += currWord.length;
      if (!Dict.isInMainDictionary(currWord)) return false; // Invalid word
    }

    return hexesSelected === totalHexes;
  }

  render () {
    return (
      <BoardContext.Provider
        value={{
          ...this.state,
          getTileKeys: this.getTileKeys.bind(this),
          getBoardData: this.getBoardData.bind(this),
          removeWord: this.removeWord.bind(this),
          setTile: this.setTile.bind(this),
          setLevelIndex: this.setLevelIndex.bind(this),
          setWord: this.setWord.bind(this),
          setWordIndex: this.setWordIndex.bind(this),
          setChooseStartTileMode: this.setChooseStartTileMode.bind(this),
          setIsPointerDown: this.setIsPointerDown.bind(this),
          findMaxUsedRadius: this.findMaxUsedRadius.bind(this),
          exportBoard: this.exportBoard.bind(this),
          importBoard: this.importBoard.bind(this),
          resetBoard: this.resetBoard.bind(this),
          selectTile: this.selectTile.bind(this),
          unselectWord: this.unselectWord.bind(this),
          checkValidPuzzle: this.checkValidPuzzle.bind(this),
          checkCompletion: this.checkCompletion.bind(this),
        }}
      >
        {this.props.children}
      </BoardContext.Provider>
    )
  }
}

// create the consumer as higher order component
export const withBoardContext = ChildComponent => props => (
  <BoardContext.Consumer>
    {
      context => <ChildComponent {...props} board={context}  />
    }
  </BoardContext.Consumer>
);