import { Component } from 'react'
import { StyleSheet, View, TouchableOpacity, Image, Linking, Platform } from 'react-native';
import Button from './Button';
import theme from '../styles/theme';
import Analytics from '../analytics/analytics';
import { withThemeContext } from '../contexts/ThemeContext';

class Socials extends Component {
  render() {
    const { themeStyle } = this.props.theme;
    const { showBuyNow } = this.props;
    if (Platform.OS !== 'web' || !theme.isLargeScreen) return null;

    let finalStyle = {
      position: 'absolute',
      bottom: 15,
      right: 15,
      flexDirection: 'row',
      alignItems: 'center',
      zIndex: 1,
    }

    let buyNowBtn = null;
    if (Platform.OS === 'web') {
      buyNowBtn = (
        <Button
          color={themeStyle.TEXT_COLOR}
          titleStyle={{ color: themeStyle.BG, fontSize: 18, fontFamily: 'Nunito-SemiBold' }}
          title="Buy the App"
          onPress={showBuyNow}
        />
      )
    }

    return (
      <View style={finalStyle}>
        { buyNowBtn }
        <TouchableOpacity
          style={{ marginTop: 5, marginHorizontal: 10 }}
          onPress={() => {
            Linking.openURL('https://discord.gg/g5q2dwrdt5')
            Analytics.logLinkClicked('Discord from Game Screen');
          }}
        >
          <Image style={{ width: 44.4, height: 50 }} source={require('../assets/icons/discord-logo.png')}/>
        </TouchableOpacity>
        <TouchableOpacity
          style={{ marginHorizontal: 10 }}
          onPress={() => {
            Linking.openURL('https://twitter.com/SpiralburstDev')
            Analytics.logLinkClicked('Twitter from Game Screen');
          }}
          >
          <Image style={{ width: 40, height: 40 }} source={require('../assets/icons/twitter-logo.png')}/>
        </TouchableOpacity>
      </View>
    );
  }
}

export default withThemeContext(Socials);

const styles = StyleSheet.create({
});