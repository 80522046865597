import Animated, {
  useSharedValue,
  useAnimatedStyle,
  useAnimatedProps,
  useDerivedValue,
  SlideInRight,
  SlideOutDown,
  Layout,
  measure,
  interpolate,
  withTiming,
  withDelay,
  withSequence,
  Easing,
  runOnJS,
} from 'react-native-reanimated';
import { useState, useEffect, useMemo } from 'react'
import { StyleSheet, Text, View, Platform } from 'react-native';
import {
  HexGrid,
  HexLayout,
  Tile, HexText,
  HexPattern,
  Hex,
  HexUtils} from '../HexGrid';
import Orientation from '../HexGrid/models/Orientation';
import Svg, { Path, Polygon, G } from 'react-native-svg';
const AnimatedG = Animated.createAnimatedComponent(G);
import theme from '../styles/theme';
import { withBoardContext } from '../contexts/BoardContext';
import { withThemeContext } from '../contexts/ThemeContext';

const timeUnit = 500;

// Web Only Fn
const easeInOutCubic = (x) => {
  return x < 0.5 ? 4 * x * x * x : 1 - Math.pow(-2 * x + 2, 3) / 2;
}
const easeInCubic = (x) => {
  return x * x * x;
}
const easeOutCubic = (x) => {
  return 1 - Math.pow(1 - x, 3);
}
const interpTimestamp = (timestamp) => {
  let scale = 1;
  if (timestamp >= 0 && timestamp <= timeUnit) {
    let perc = easeOutCubic(timestamp / timeUnit);
    scale = 1 + (.1 * perc);
  }
  if (timestamp > timeUnit && timestamp <= timeUnit * 2) {
    let perc = easeInCubic((timestamp - timeUnit) / timeUnit);
    scale = 1.1 - (.1 * perc);
  }
  return scale;
}

const GameBoardTransition = (props) => {
  // props
  // - prevBoard
  // - nextBoard

  const [animationStep, setAnimationStep] = useState(0);
  const [timestamp, setTimestamp] = useState(0);
  const [start, setStart] = useState(0);
  const scale0 = useSharedValue(1);
  const scale1 = useSharedValue(1);
  const scale2 = useSharedValue(1);
  const scale3 = useSharedValue(1);
  const scale4 = useSharedValue(1);
  const scale5 = useSharedValue(1);
  const scale6 = useSharedValue(1);
  const { isGame, currentTile } = props;
  const { findMaxUsedRadius } = props.board;
  const { isColorBlindMode, getColorBlindColor } = props.theme;
  let tileKeys = Object.keys(props.prevBoard);

  const maxRadius = useMemo(() => {
    return findMaxUsedRadius()
  }, []);

  // Mount
  useEffect(() => {
    // Start animations
    let easeOutCubic = Easing.bezier(0.33, 1, 0.68, 1);
    let easeInCubic = Easing.bezier(0.32, 0, 0.67, 0);
    const endStep1 = () => {
      runOnJS(setAnimationStep)(1);
    };
    const endStep2 = () => {
      runOnJS(setAnimationStep)(2);
    };
    for (let i = 0; i <= maxRadius; i++) {
      let delay = i * timeUnit * .3;

      let anim1 = withTiming(1.1, { duration: timeUnit, easing: easeOutCubic });
      let anim2 = withTiming(1, { duration: timeUnit, easing: easeInCubic });

      if (i === maxRadius) {
        anim1 = withTiming(1.1, { duration: timeUnit, easing: easeOutCubic }, () => { runOnJS(setAnimationStep)(1); });
        anim2 = withTiming(1, { duration: timeUnit, easing: easeInCubic }, () => { runOnJS(setAnimationStep)(2); });
      }

      if (i === 0) scale0.value = withSequence(anim1, anim2);
      if (i === 1) scale1.value = withDelay(delay, withSequence(anim1, anim2));
      if (i === 2) scale2.value = withDelay(delay, withSequence(anim1, anim2));
      if (i === 3) scale3.value = withDelay(delay, withSequence(anim1, anim2));
      if (i === 4) scale4.value = withDelay(delay, withSequence(anim1, anim2));
      if (i === 5) scale5.value = withDelay(delay, withSequence(anim1, anim2));
      if (i === 6) scale6.value = withDelay(delay, withSequence(anim1, anim2));
    }
  }, []);

  if (Platform.OS === 'web') {
    useEffect(() => {
      let frame = undefined;
      var prev = undefined;
      const loop = (timestamp) => {
        var delta = prev ? timestamp - prev : 0;
        prev = timestamp;
        if (start === 0) {
          setStart(timestamp);
        } else {
          setTimestamp(timestamp - start);
        }

        // Cleanup
        frame = undefined;
      }
      requestAnimationFrame(loop);
      return;
    });
  }

  let scale = 1;


  // console.log("BOARD")
  if (Platform.OS === 'web') {
    if (timestamp > (timeUnit * 2 + (maxRadius * timeUnit * .3) + 100)) props.endTransition(); // 100 buffer
  } else {
    if (animationStep === 2) props.endTransition(); // 100 buffer
  }

  let finalWidth = props.width || theme.smallScreenWidth;
  let finalHeight = finalWidth * 5 / 4;
  let translationStyle = {};

  let center = new Hex(0,0,0);
  // Grid Vars (sync with GameBoard)
  let layoutSize = { x: 6.5, y: 6.5 };
  let layoutOrigin = { x: 0, y: 0 }
  if (Platform.OS !== 'web') {
    layoutSize = { x: 6.5, y: 6.5 };
    layoutOrigin = { x: 0, y: -12 }
  }
  let layoutObj = {
    size: layoutSize,
    origin: layoutOrigin,
    orientation: new Orientation(Math.sqrt(3.0), Math.sqrt(3.0) / 2.0, 0.0, 3.0 / 2.0, Math.sqrt(3.0) / 3.0, -1.0 / 3.0, 0.0, 2.0 / 3.0, 0.5)
  }

  const animatedProps0 = useAnimatedProps(() => {
    return { scale: scale0.value };
  });

  const animatedProps1 = useAnimatedProps(() => {
    return { scale: scale1.value };
  });

  const animatedProps2 = useAnimatedProps(() => {
    return { scale: scale2.value };
  });

  const animatedProps3 = useAnimatedProps(() => {
    return { scale: scale3.value };
  });

  const animatedProps4 = useAnimatedProps(() => {
    return { scale: scale4.value };
  });

  const animatedProps5 = useAnimatedProps(() => {
    return { scale: scale5.value };
  });

  const animatedProps6 = useAnimatedProps(() => {
    return { scale: scale6.value };
  });

  return (
    <View style={translationStyle}>
      <HexGrid width={finalWidth} height={finalHeight}>
        <HexLayout
          size={layoutSize}
          spacing={1.1}
          origin={layoutOrigin}
          layout={layoutObj}
          flat={false}
        >
          { tileKeys.map((hex, i) => {
              let currHex = props.prevBoard[hex];
              if (!currHex) return;
              let r = HexUtils.distance(center, currHex);
              let staggeredTimestamp = timestamp - (r * timeUnit * .3) // staggered by
              let colorOverride = null;
              let shouldSwitchWeb = Platform.OS === 'web' && staggeredTimestamp >= timeUnit;
              let shouldSwitchElse = Platform.OS !== 'web' && animationStep >= 1;
              if (shouldSwitchWeb || shouldSwitchElse) {
                if (props.nextBoard[hex]) {
                  currHex = props.nextBoard[hex];
                  let color = currHex.color;
                  if (isColorBlindMode) {
                    color = getColorBlindColor(currHex.wordIndex) || color;
                  }
                  colorOverride = color;
                }
              }

              let animProps = animatedProps0;
              if (r === 1) animProps = animatedProps1;
              if (r === 2) animProps = animatedProps2;
              if (r === 3) animProps = animatedProps3;
              if (r === 4) animProps = animatedProps4;
              if (r === 5) animProps = animatedProps5;
              if (r === 6) animProps = animatedProps6;

              //console.log("HEX", nextBoard[hex])

              let isSelected = currentTile && currentTile.equals(currHex);
              let tileEl = (
                <Tile
                  isGame={props.isGame}

                  q={currHex.q}
                  r={currHex.r}
                  s={currHex.s}
                  letter={currHex.letter}
                  isSelected={isSelected}
                  isPerm={currHex.isPerm}
                  wordIndex={currHex.wordIndex}
                  colorOverride={colorOverride}

                  boardSize={6.5}
                />
              )

              if (Platform.OS === 'web') {
                let scale = interpTimestamp(staggeredTimestamp);
                return (
                  <G key={hex} scale={scale} pointerEvents="none">
                    { tileEl }
                  </G>
                )
              } else {
                return (
                  <AnimatedG key={hex} animatedProps={animProps} pointerEvents="none">
                    { tileEl }
                  </AnimatedG>
                )
              }
            })
          }
        </HexLayout>
      </HexGrid>
    </View>
  );
}

export default withThemeContext(withBoardContext(GameBoardTransition));

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
  },
});
