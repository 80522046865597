import { StyleSheet, View, Image, TouchableOpacity, ScrollView } from 'react-native';
import ThemedText from './ThemedText';
import theme from '../styles/theme';
import { withThemeContext } from '../contexts/ThemeContext';
import { playSound } from '../utils/soundUtils';

const EditorRules = (props) => {
  const { setShowEditorRulesMode } = props;
  const { themeStyle, isDarkMode } = props.theme;

  let containerStyle = {
    backgroundColor: themeStyle.BG,
    zIndex: 1,
  };

  let rulesImageEmptySource = require('../assets/images/rules-puzzle-empty.png');
  if (isDarkMode) rulesImageEmptySource = require('../assets/images/rules-puzzle-empty-dark.png');

  let placeText = 'Place on Board';
  let removeText = 'Remove from Board';
  if (!theme.isLargeScreen) {
    placeText = 'Place';
    removeText = 'Remove';
  }

  let shareText = 'Share Puzzle Link';
  if (!theme.isLargeScreen) {
    shareText = 'Share';
  }

  return (
    <View style={containerStyle}>
      <View style={styles.menuHeader}>
        <ThemedText style={[styles.ruleText, { fontSize: 24 }]}>Using the Editor</ThemedText>
        <TouchableOpacity
          style={styles.menuCloseButton}
          onPress={() => {
            playSound('click');
            setShowEditorRulesMode(false)
          }}
        >
          <ThemedText style={{ fontSize: 24 }}>x</ThemedText>
        </TouchableOpacity>
      </View>
      <ScrollView
        style={styles.rulesStyle}
        contentContainerStyle={{ paddingBottom: 60 }}
      >
        <ThemedText style={[styles.ruleText, { marginTop: 15, fontSize: 20, textAlign: 'center' }]}>How to Create a Puzzle</ThemedText>
        <ThemedText style={styles.ruleText}>1. Press "Add Word" and type in a word.</ThemedText>
        <ThemedText style={styles.ruleText}>2. Press "{ placeText }" to add the word to the puzzle. You must tap an empty hex and place letters one at a time.</ThemedText>
        <ThemedText style={styles.ruleText}>3. You can place up to 6 words.</ThemedText>
        <ThemedText style={styles.ruleText}>4. When finished, press "{ shareText }" and send the copied link to a friend! They will see only the first letter of each word, like D in DOG and C in CAT in the example below.</ThemedText>
        <Image style={[styles.rulesImage, { borderColor: themeStyle.TEXT_COLOR }]} source={rulesImageEmptySource}/>

        <ThemedText style={[styles.ruleText, { marginTop: 15, fontSize: 20, textAlign: 'center' }]}>Editor Constraints</ThemedText>
        <ThemedText style={styles.ruleText}>1. Words must be at least 3 letters long and in our dictionary. Let us know at support@spiralburst.com if there is a word you want to use!</ThemedText>
        <ThemedText style={styles.ruleText}>2. Each letter in a word must be adjacent to the last letter placed. However, words don't need to touch each other, and you can even leave holes in the puzzle.</ThemedText>
        <ThemedText style={styles.ruleText}>3. Each added word must be placed on the board. If you no longer want a word, press "Remove Word."</ThemedText>

        <ThemedText style={[styles.ruleText, { marginTop: 15, fontSize: 20, textAlign: 'center' }]}>Puzzle Making Tips</ThemedText>
        <ThemedText style={styles.ruleText}>1. You can change the colors of words. Pick colors that have good contrast with each other. Let us know if you want more colors!</ThemedText>
        <ThemedText style={styles.ruleText}>2. It's easier to solve a puzzle if the words are related to a theme. If you want to make a harder puzzle, choose words that aren't related.</ThemedText>
        <ThemedText style={styles.ruleText}>3. The locations of starting letters affect difficulty. Placing starts next to each other or on the edge of the puzzle will make the puzzle easier to solve.</ThemedText>
      </ScrollView>
    </View>
  );
}
export default withThemeContext(EditorRules);

const styles = StyleSheet.create({
  rulesStyle: {
    paddingHorizontal: 20,
    marginBottom: 60,
  },
  buttonText: {
    fontSize: 18,
    fontFamily: 'Nunito-SemiBold',
    textTransform: 'capitalize'
  },
  menuCloseButton: {
    position: 'absolute',
    top: 10,
    right: 10,
    width: 30,
    height: 30,
    alignItems: 'center',
    justifyContent: 'center',
  },
  menuHeader: {
    height: 50,
    alignSelf: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  menuFooter: {
    height: 30,
    position: 'absolute',
    bottom: 10,
    right: 10
  },

  // Rules
  rulesButton: {
    position: 'absolute',
    paddingTop: 1,
    right: 50,
    height: 26,
    width: 26,
    alignItems: 'center',
    justifyContent: 'center',
    borderWidth: 2,
    borderRadius: 13,
  },
  ruleText: {
    fontSize: 16,
    fontFamily: 'Nunito-SemiBold',
    marginBottom: 15
  },
  rulesImage: {
    width: 128,
    height: 109,
    marginBottom: 20,
    alignSelf: 'center',
    borderWidth: 1,
    borderRadius: 6,
  },
});
