import { useState, useEffect } from 'react'
import { StyleSheet, Text, View, TextInput, Button, Dimensions, Platform } from 'react-native';

const color = (r, g, b) => {
  return 'rgb(' + r + ',' + g + ',' + b + ')';
}

// Cosine interpolation
const interpolation = (a, b, t) => {
  return (1-Math.cos(Math.PI*t))/2 * (b-a) + a;
}

const createPoisson = (radius) => {
  // console.log("CREATE POISSON")
  // domain is the set of points which are still available to pick from
  // D = union{ [d_i, d_i+1] | i is even }
  let radius2 = radius * 2;
  let domain = [radius, 1-radius];
  let measure = 1-radius2;
  let spline = [0, 1];
  while (measure) {
    let dart = measure * Math.random(), i, l, interval, a, b, c, d;

    // Find where dart lies
    for (i = 0, l = domain.length, measure = 0; i < l; i += 2) {
      a = domain[i], b = domain[i+1], interval = b-a;
      if (dart < measure+interval) {
        spline.push(dart += a-measure);
        break;
      }
      measure += interval;
    }
    c = dart-radius, d = dart+radius;

    // Update the domain
    for (i = domain.length-1; i > 0; i -= 2) {
      l = i-1, a = domain[l], b = domain[i];
      // c---d          c---d  Do nothing
      //   c-----d  c-----d    Move interior
      //   c--------------d    Delete interval
      //         c--d          Split interval
      //       a------b
      if (a >= c && a < d)
        if (b > d) domain[l] = d; // Move interior (Left case)
        else domain.splice(l, 2); // Delete interval
      else if (a < c && b > c)
        if (b <= d) domain[i] = c; // Move interior (Right case)
        else domain.splice(i, 0, c, d); // Split interval
    }

    // Re-measure the domain
    for (i = 0, l = domain.length, measure = 0; i < l; i += 2)
      measure += domain[i+1]-domain[i];
  }

  return spline.sort();
}

const createSplineY = (splineX, deviation) => {
  let splineY = [];
  for (var i = 1, l = splineX.length-1; i < l; ++i) {
    splineY[i] = deviation * Math.random();
  }
  splineY[0] = splineY[l] = deviation * Math.random();
  return splineY;
}

// Vars
const spread = 20
const sizeMin = 5
const sizeMax = 12 - sizeMin
const eccentricity = 10
const deviation = 100
const dxThetaMin = -.1
const dxThetaMax = -dxThetaMin - dxThetaMin
const dyMin = .27
const dyMax = .32
const dThetaMin = .4
const dThetaMax = .7 - dThetaMin;
const windowHeight = Dimensions.get('window').height;
const radius = 1/eccentricity;
const initialSplineX = createPoisson(radius);

const Confetto = (props) => {
  // props
  // - frame
  // - delta
  // - index
  // - containerWidth
  // - removeConfetto

  // Init
  const windowWidth = props.containerWidth || Dimensions.get('window').width;
  const [outerWidth] = useState(sizeMin + sizeMax * Math.random());
  const [outerHeight] = useState(sizeMin + sizeMax * Math.random());
  const [innerBackgroundColor] = useState(color(200 * Math.random()|0, 200 * Math.random()|0, 200 * Math.random()|0));
  const [axis] = useState(`rotate3D(${Math.cos(360 * Math.random())}, ${Math.cos(360 * Math.random())},0,`);
  const [theta, setTheta] = useState(360 * Math.random());
  const [dTheta] = useState(dThetaMin + dThetaMax * Math.random());
  const [x, setX] = useState(windowWidth * Math.random());
  const [y, setY] = useState(-deviation);
  const [dx] = useState(Math.sin(dxThetaMin + dxThetaMax * Math.random()));
  const [dy] = useState(dyMin + dyMax * Math.random());
  const [splineX] = useState(initialSplineX);
  const [splineY] = useState(createSplineY(initialSplineX, deviation));

  let outerStyle = {
    position: 'absolute',
  };
  let innerStyle = {
    width: '100%',
    height: '100%',
  };
  outerStyle.width = outerWidth;
  outerStyle.height = outerHeight;
  outerStyle.perspective = 50;
  outerStyle.transform = `rotate(${(360 * Math.random())}deg)`;
  outerStyle.left = x;
  outerStyle.top = y;
  innerStyle.transform = `${axis} ${theta}deg)`;
  innerStyle.backgroundColor = innerBackgroundColor;
  // Mobile styling
  if (Platform.OS !== 'web') {
    outerStyle.transform = [{ rotateX: `${(360 * Math.random())}deg` }];
    innerStyle.transform = [{ rotateX: `${(360 * Math.random())}deg` }, { rotateY: `${(360 * Math.random())}deg` }, { rotateZ: `${(360 * Math.random())}deg` }];
  }

  // fade out
  let opacity = Math.max(0, 1 - (y / windowHeight));
  outerStyle.opacity = opacity;

  useEffect(() => {
    // Update fn
    let delta = props.delta;
    let newY = y + (dy * delta);
    setX(x + (dx * delta));
    setY(newY);
    setTheta(theta + (dTheta * delta));

    if (newY > windowHeight + deviation &&
        props.index &&
        props.removeConfetto) {
      props.removeConfetto(props.index);
    }
    return;
  }, [props.frame]);

  let phi = props.frame % 7777 / 7777;
  let i = 0;
  let j = 1;
  while (phi >= splineX[j]) i = j++;
  let rho = interpolation(
    splineY[i],
    splineY[j],
    (phi - splineX[i]) / ( splineX[j] - splineX[i])
  );
  phi *= Math.PI * 2;

  // styles
  outerStyle.left = x + rho * Math.cos(phi);
  outerStyle.top = y + rho * Math.sin(phi);
  innerStyle.transform = `${axis} ${theta}deg)`;
  // Mobile styling
  if (Platform.OS !== 'web') {
    innerStyle.transform = [{ rotateX: `${(360 * Math.random())}deg` }, { rotateY: `${(360 * Math.random())}deg` }, { rotateZ: `${(360 * Math.random())}deg` }];
  }

  return (
    <View style={outerStyle}>
      <View style={innerStyle}>
      </View>
    </View>
  );
}

export default Confetto;

const styles = StyleSheet.create({

});
