import utils from '../../utils/utils.js';

class Hex {
  constructor(q, r, s, letter, isSelected, isPerm, wordIndex) {
    this.q = q;
    this.r = r;
    this.s = s;
    this.letter = letter;
    this.isSelected = isSelected;
    this.isPerm = isPerm;
    this.wordIndex = wordIndex;
    //this tileKey would no longer be a thing here
  }

  toObject() {
    return utils.toObject(this);
  }

  setLetter(c) {
    this.letter = c;
  }

  equals(hex) {
    //removed check on letter
    if (hex &&
        hex.q === this.q &&
        hex.r === this.r &&
        hex.s === this.s) {
      return true;
    }
    else {
      return false;
    }
  }
}

export default Hex;
