import { useState, Component } from 'react'
import * as Clipboard from 'expo-clipboard';
import { StyleSheet, Text, View, TouchableOpacity, Image, ScrollView, Platform } from 'react-native';
import Button from '../components/Button';
import BuyNowModal from '../components/BuyNowModal';
import GameBoard from '../components/GameBoard';
import Header from '../components/Header';
import TileInfo from '../components/TileInfo';
import Words from '../components/Words';
import theme from '../styles/theme';
import Socials from '../components/Socials';
import Promotional from '../components/Promotional';
import { withBoardContext } from '../contexts/BoardContext';
import { withThemeContext } from '../contexts/ThemeContext';
import Analytics from '../analytics/analytics';

class EditorScreen extends Component {
  constructor(props) {
    super(props);
    const { importBoard } = props.board;
    importBoard('OTEk');

    this.state = {
      showRulesMode: false,
      showSettingsMode: false,
      showBuyNowModal: false,

      linkSharedMode: false,
    };
  }

  componentDidMount() {
    const { importBoard } = this.props.board;
    this._unsubscribe = this.props.navigation.addListener('focus', () => {
      importBoard('OTEk');
    });
  }

  componentWillUnmount() {
    this._unsubscribe();
  }

  renderGameArea() {
    let gameBoardWidth = Math.min(theme.SCREEN_WIDTH, theme.smallScreenWidth);
    if (theme.isLargeScreen && Platform.OS === 'web') gameBoardWidth = Math.min(theme.smallScreenWidth, theme.SCREEN_WIDTH / 2);

    let wordPaddingLeft = 0;

    if (theme.isSmallScreen ||
        Platform.OS !== 'web') {
      wordPaddingLeft = 10;
    }

    let gameContainerStyle = {}
    if (theme.isLargeScreen && Platform.OS === 'web') {
      gameContainerStyle = {
        flexDirection: 'row-reverse',
        justifyContent: 'center',
      }
    }

    return (
      <View style={[ styles.gameContainer, gameContainerStyle ]}>
        <Words />
        <GameBoard
          width={gameBoardWidth}
          isGame={false}
        />
      </View>
    );
  }


  render() {
    const { themeStyle } = this.props.theme;

    let containerStyle = [styles.container, {
      backgroundColor: themeStyle.BG,
    }];

    let buyNowModalEl = null;
    if (this.state.showBuyNowModal) {
      buyNowModalEl = (
        <BuyNowModal
          close={() => this.setState({ showBuyNowModal: false })}
        />
      );
    }

    return (
      <View style={containerStyle}>
        <Header navigate={this.props.navigation.replace} isEditor={true} />
        { this.renderGameArea() }
        <Promotional/>
        <Socials showBuyNow={() => {
          this.setState({ showBuyNowModal: true });
          Analytics.logAppButtonPressed('buy-now-from-editor');
        }} />
        { buyNowModalEl }
      </View>
    );
  }
}

export default withBoardContext(withThemeContext(EditorScreen));

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
  },
  gameContainer: {
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: theme.SCREEN_WIDTH,
    overflow: 'hidden',
    flex: 1
  }
});
