import { Component } from 'react'
import LottieView from 'lottie-react-native';
import { StyleSheet, Text, View, TouchableOpacity, Image, Linking, Pressable, ScrollView, Platform } from 'react-native';
import * as Clipboard from 'expo-clipboard';
import Button from '../components/Button';
import BuyNowModal from '../components/BuyNowModal';
import Confetti from '../components/Confetti';
import CompletionModal from '../components/CompletionModal';
import GameBoard from '../components/GameBoard';
import Header from '../components/Header';
import ThemedText from '../components/ThemedText';
import Words from '../components/Words';
import Socials from '../components/Socials';
import Promotional from '../components/Promotional';
import { withBoardContext } from '../contexts/BoardContext';
import { withSaveContext } from '../contexts/SaveContext';
import { withThemeContext } from '../contexts/ThemeContext';
import theme from '../styles/theme';
import utils from '../utils/utils.js';
import { playSound } from '../utils/soundUtils.js';
import Analytics from '../analytics/analytics';
import Packs, { Tiers } from '../staticData/packData.js';
import Daily from '../staticData/dailyData.js';
import Countdown from '../components/Countdown';
import CommunityPackAuthors from '../staticData/communityPackAuthors';

let levels = Packs.starter;

class GameScreen extends Component {
  constructor(props) {
    super(props);
    const { importBoard, levelIndex, setLevelIndex } = props.board;
    const { hasLoaded, levelProgress } = props.save;

    let gameID = utils.getPropertyByKeys(props.route, ['params', 'gameID']);
    let packID = utils.getPropertyByKeys(props.route, ['params', 'packID']) || 'starter';
    let routeName = utils.getPropertyByKeys(props, ['route', 'name']);
    if (routeName === 'Daily') packID = 'daily';

    if (routeName === 'CustomGame' &&
        gameID) {
      try {
        if (btoa(atob(gameID)) == gameID) {
          importBoard(gameID);
        }
      } catch (err) {
        console.log(err);
      }

    } else if (packID) {
      if (Packs.hasOwnProperty(packID)) {
        levels = Packs[packID];
        let currLevelIndex = utils.getPropertyByKeys(levelProgress, [packID]) || 0;

        if (gameID) {
          // Check for valid gameID
          let isValidGameID = parseInt(gameID) > 0 && parseInt(gameID) <= levels.length;
          if (isValidGameID) {
            currLevelIndex = parseInt(gameID) - 1;
            importBoard(levels[currLevelIndex]);
          } else {
            this.props.navigation.replace('Pack', { packID: packID });
          }
        } else {
          importBoard(levels[0]);
        }
        if (currLevelIndex >= 0 && currLevelIndex !== levelIndex) setLevelIndex(currLevelIndex);

      // Check for valid packID
      } else {
        this.props.navigation.replace('Packs');
      }
    }

    this._initialLoad = false;
    this._initialBoardLoad = false;
    this._routeName = routeName;


    this.state = {
      // Game
      packID: packID,
      customGameID: gameID,

      // Menus
      showRulesMode: false,
      showSettingsMode: false,
      showCompletionMode: false,
      showBuyNowModal: false,
      completionMinimized: false,

      // Animations
      showConfettiMode: false,
      triggerTransition: false,

      // Feedback
      surveyTapped: false,
      linkSharedMode: false,

      // Dynamic Colors
      packColor: theme.YELLOW_HONEY
    };
  }

  componentDidMount() {
    this._unsubscribe = this.props.navigation.addListener('focus', () => {
      const { importBoard, levelIndex, setLevelIndex } = this.props.board;
      const { levelProgress } = this.props.save;
      const { packID } = this.state;
      if (!this._initialLoad) {
        this._initialLoad = true;
      } else {
        let currLevelIndex = utils.getPropertyByKeys(levelProgress, [packID]);
        setLevelIndex(currLevelIndex);
        importBoard(levels[currLevelIndex]);
        this.setState({ showConfettiMode: false, showCompletionMode: false, completionMinimized: false });

      }
    });

    // Get pack's color
    for (let key in Tiers) {
      let tier = Tiers[key];
      let tierPacks = tier.packs;
      if (tierPacks && tierPacks.includes(this.state.packID) && tier.color) {
        this.setState({ packColor: tier.color })
      }
    }
  }

  componentWillUnmount() {
    this._unsubscribe();
  }

  componentDidUpdate(prevProps, prevState) {
    const { checkCompletion, importBoard, levelIndex, setLevelIndex, isPointerDown } = this.props.board;
    const { hasLoaded, levelProgress, setLevelProgress } = this.props.save;
    const { packID, customGameID } = this.state;

    // Initial board load handling
    let currLevelIndex = utils.getPropertyByKeys(levelProgress, [packID]) || 0;
    if (!this._initialBoardLoad && hasLoaded && !prevProps.save.hasLoaded) {
      this._initialBoardLoad = true;

      // Load saved progress
      if (!customGameID &&
          !levelProgress) {
        setLevelIndex(0);
        importBoard(levels[0]);

      } else if (this._routeName === 'Game') {
        // Go to home screen if already has progress
        this.props.navigation.replace('Packs');
      }
    }

    if (!isPointerDown) {
      if (!this.state.showConfettiMode &&
          !this.state.showCompletionMode &&
          !this.state.completionMinimized &&
          !this.state.triggerTransition &&
          checkCompletion()) {
        playSound('success');
        setLevelProgress(packID, levelIndex);
        Analytics.logLevelCompleted(packID, customGameID, levelIndex + 1);

        let stateObj = {
          showConfettiMode: true,
          showCompletionMode: true,
          completionMinimized: false,
        };
        if (Platform.OS === 'web' &&
            packID === 'daily' &&
            levelIndex >= 1) {
          console.log('index', levelIndex)
          stateObj.showBuyNowModal = true;
        }
        this.setState(stateObj);
      }
    }
  }

  endConfetti() {
    this.setState({ showConfettiMode: false });
  }

  _getNextLevel(levelIndex) {
    const { levelProgress } = this.props.save;
    const { packID } = this.state;
    let newLevelIndex = levelIndex + 1;
    if (newLevelIndex >= levels.length) {
      for (let i = 0; i < levels.length; i ++) {
        let levelIsComplete = utils.getPropertyByKeys(levelProgress, [packID, i])
        if (!levelIsComplete) {
          newLevelIndex = i;
          break;
        }
      }
    }
    return newLevelIndex;
  }

  endTransition() {
    const { levelIndex, setLevelIndex } = this.props.board;
    const { levelProgress } = this.props.save;
    const { packID, customGameID } = this.state;
    let routeName = utils.getPropertyByKeys(this.props, ['route', 'name']);
    let newLevelIndex = this._getNextLevel(levelIndex);
    if (routeName === 'Pack' && customGameID) {
      this.props.navigation.replace('Pack', {
        packID: packID,
        gameID: newLevelIndex + 1
      });
    }
    this.props.board.importBoard(levels[newLevelIndex]);
    setLevelIndex(newLevelIndex);
    this.setState({ triggerTransition: false });
  }

  advanceLevel() {
    const { levelIndex, setIsPointerDown } = this.props.board;
    let newLevelIndex = this._getNextLevel(levelIndex);
    this.setState({
      showConfettiMode: false,
      showCompletionMode: false,
      completionMinimized: false,
      triggerTransition: levels[newLevelIndex],
    });
    setIsPointerDown(false);
  }

  renderConfetti() {
    if (this.state.showConfettiMode) {
      if (Platform.OS === 'web') {
        return (
          <View pointerEvents={'none'} style={{ ...theme.fullAbsolute, overflow: 'hidden', zIndex: 1000 }}>
            <Confetti endConfetti={this.endConfetti.bind(this)}/>
          </View>
        )

      } else {
        return (
          <View
            style={{
              position: 'absolute',
              top: '50%',
              marginTop: -theme.SCREEN_WIDTH / 2,
              width: theme.SCREEN_WIDTH,
              height: theme.SCREEN_WIDTH,
              zIndex: 100,
            }}
            pointerEvents="none"
          >
            <LottieView
              autoPlay
              loop={false}
              style={{
                width: theme.SCREEN_WIDTH,
                height: theme.SCREEN_WIDTH,
              }}
              // Find more Lottie files at https://lottiefiles.com/featured
              source={require('../assets/lottie/confetti.json')}
            />
          </View>
        )
      }

    }
    return null;
  }

  renderCompletionModal() {
    const { surveyTapped, linkSharedMode, packID, customGameID, showCompletionMode, showBuyNowModal, packColor } = this.state;
    let routeName = utils.getPropertyByKeys(this.props, ['route', 'name']);

    if (Platform.OS === 'web' &&
        packID === 'daily' &&
        showBuyNowModal) {
      return null;
    }

    return (
      <CompletionModal
        levels={levels}
        surveyTapped={surveyTapped}
        linkSharedMode={linkSharedMode}
        packID={packID}
        showCompletionMode={showCompletionMode}
        packColor={packColor}
        routeName={routeName}
        advanceLevel={this.advanceLevel.bind(this)}
        goToPacks={() => { this.props.navigation.replace('Packs'); }}
        goToEditor={() => { this.props.navigation.replace('Editor'); }}
        minimize={() => {
          this.setState({
            showCompletionMode: false,
            completionMinimized: true,
          });
        }}
      >
      </CompletionModal>
    );
  }

  renderGameArea() {
    const { levelIndex, checkCompletion, isPointerDown } = this.props.board;
    const { isDarkMode } = this.props.theme;
    const { triggerTransition, packID, customGameID } = this.state;

    // Responsive width for header, gameboard, and word area
    let gameBoardWidth = Math.min(theme.SCREEN_WIDTH, theme.smallScreenWidth);
    if (theme.isLargeScreen && Platform.OS === 'web') gameBoardWidth = Math.min(theme.smallScreenWidth, theme.SCREEN_WIDTH / 2);
    let widthRemainder = theme.SCREEN_WIDTH - gameBoardWidth;

    let widthOffset = 0;
    let newBottom = 0;
    if (theme.isLargeScreen && Platform.OS === 'web') {
      widthOffset = gameBoardWidth / 2 - 20;
      newBottom = (theme.SCREEN_HEIGHT - 60) / 2;
    }

    let helpTextEl = null;
    let routeName = utils.getPropertyByKeys(this.props, ['route', 'name']);

    if (routeName !== 'CustomGame' &&
        packID === 'starter' &&
        (levelIndex === 0 || levelIndex === 1) &&
        !triggerTransition) {

      let imageStyle = {};
      let imageOuterStyle = {};
      let imageSource = require('../assets/images/help-tap-to-fill.png');

      // First image for START level on touch screens
      let showTouchImage = (Platform.OS === 'android' || Platform.OS === 'ios') || (Platform.OS === 'web' && utils.isTouchDevice());
      if (isDarkMode) imageSource = require('../assets/images/help-tap-to-fill-white.png');

      // START level
      if (levelIndex === 0) {
        imageStyle = {
          width: 192,
          height: 112,
        }


        // Magic number for left: 3 / 8 is based on 3 out of 8 hexes, since the max puzzle width for this row is 8 (7 + 2 halves). So the 3 points at the 3rd hex
        imageOuterStyle = {
          position: 'absolute',
          bottom: (newBottom > 0) ? newBottom + 45 : (gameBoardWidth * 5 / 4) / 2 + 45,
          left: gameBoardWidth * 3 / 8 + (widthRemainder / 2) - (imageStyle.width / 2) - widthOffset,
          // filter: isDarkMode ? 'invert(1)' : null
        }
        // If using a mouse, show click to fill image instead
        if (!showTouchImage) {
          imageSource = require('../assets/images/help-click-to-fill.png');
          if (isDarkMode) imageSource = require('../assets/images/help-click-to-fill-white.png');

          imageOuterStyle.bottom = (newBottom > 0) ? newBottom - 157 : (gameBoardWidth * 5 / 4) / 2 - 157;
          imageOuterStyle.left = (gameBoardWidth * 3 / 8) + (widthRemainder / 2) - (imageStyle.width / 2) + 10 - widthOffset;
        }
      // TRICKY level
      } else if (levelIndex === 1) {
        let leftOffset = 0;
        if (!theme.isSmallScreen) leftOffset = 25;
        imageStyle = {
          width: 162,
          height: 112,
        }
        imageOuterStyle = {
          position: 'absolute',
          bottom: (newBottom > 0) ? newBottom + 45 : (gameBoardWidth * 5 / 4) / 2 + 45,
          left: gameBoardWidth * 6 / 8 + (widthRemainder / 2) - (imageStyle.width / 2) - leftOffset - widthOffset,
          // filter: isDarkMode ? 'invert(1)' : null
        }
        // }
        imageSource = require('../assets/images/help-drag-to-fill.png');
        if (isDarkMode) imageSource = require('../assets/images/help-drag-to-fill-white.png');
      }
      helpTextEl = (
        <View style={imageOuterStyle}>
          <Image style={imageStyle} source={imageSource}/>
        </View>
      )
    }


    let isSolved = false;
    if (!isPointerDown) isSolved = checkCompletion();

    let gameContainerStyle = {}
    if (theme.isLargeScreen && Platform.OS === 'web') {
      gameContainerStyle = {
        flexDirection: 'row-reverse',
        justifyContent: 'center',
      }
    }

    return (
      <View style={[ styles.gameContainer, gameContainerStyle ]}>
        { helpTextEl }
        <Words isGame={true} levelCode={levels[levelIndex]} isSolved={isSolved}/>
        <GameBoard
          width={gameBoardWidth}
          isGame={true}
          triggerTransition={triggerTransition}
          endTransition={this.endTransition.bind(this)}
          isSolved={isSolved}
        />
      </View>
    )
  }

  render() {
    const { packID, customGameID, completionMinimized, packColor } = this.state;
    const { levelIndex } = this.props.board;
    const { themeStyle } = this.props.theme;
    const { levelProgress, hasLoaded } = this.props.save;

    if (!hasLoaded) return null;

    let routeName = utils.getPropertyByKeys(this.props, ['route', 'name']);

    let completionOnPress = null;
    if (completionMinimized && levelIndex + 1 <= levels.length) {
      // only when the pack is NOT completed. Header will otherwise default to routing back to PackScreen
      completionOnPress = this.advanceLevel.bind(this);
    }

    let containerStyle = [styles.container, {
      backgroundColor: themeStyle.BG
    }];

    // if (utils.isSafari()) containerStyle.push({ ...theme.safariScreenFix });

    // Specifically for using
    let firstLevelCompleted = false;
    if (levelProgress && levelProgress['starter'] && levelProgress['starter'][0]) firstLevelCompleted = true;

    let buyNowModalEl = null;
    if (this.state.showBuyNowModal) {
      if (Platform.OS === 'web' &&
          packID === 'daily') {
        buyNowModalEl = (
          <BuyNowModal
            packID={packID}
            packColor={packColor}
            goToPacks={() => { this.props.navigation.replace('Packs'); }}
            close={() => this.setState({
              showBuyNowModal: false,
              showCompletionMode: false,
              completionMinimized: true,
            }) }
          />
        )
      } else {
        buyNowModalEl = (
          <BuyNowModal close={() => this.setState({
            showBuyNowModal: false,
          }) } />
        )
      }
    }

    return (
      <View style={containerStyle}>
        <Header
          navigate={this.props.navigation.replace}
          packID={packID}
          customGameID={customGameID}
          routeName={routeName}
          completionMinimized={completionMinimized}
          completionOnPress={completionOnPress}
          firstLevelCompleted={firstLevelCompleted}
          packColor={packColor}
          isGame={true}
        />
        <Promotional/>
        <Socials showBuyNow={() => {
          this.setState({ showBuyNowModal: true });
          Analytics.logAppButtonPressed('buy-now-from-game');
        }} />
        { this.renderGameArea() }
        { this.renderCompletionModal() }
        { this.renderConfetti() }
        { buyNowModalEl }
      </View>
    );
  }
}

export default withBoardContext(withSaveContext(withThemeContext(GameScreen)))

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
  },
  gameContainer: {
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: theme.SCREEN_WIDTH,
    overflow: 'hidden',
    flex: 1
  },
});