import { Component } from 'react'
import { StyleSheet, View, TouchableOpacity, Image, Linking, Platform } from 'react-native';
import theme from '../styles/theme';
import ThemedText from './ThemedText';
import Analytics from '../analytics/analytics';

export default class Promotional extends Component {
  render() {
    return null;
    if (Platform.OS !== 'web' || !theme.isLargeScreen) return null;

    let finalStyle = {
      position: 'absolute',
      bottom: 15,
      left: 15,
    }

    let iconStyle = {
      marginTop: 4,
      width: 52,
      height: 67,
    };

    return (
      <View style={finalStyle}>
        <ThemedText style={{ fontSize: 12 }}>Hosted on</ThemedText>
        <TouchableOpacity
          onPress={() => {
            Linking.openURL('https://goodgameshelf.com');
            Analytics.logLinkClicked('Good Game Shelf');
          }}
        >
          <Image style={iconStyle} source={require('../assets/images/good-game-shelf-logo.png')}/>
        </TouchableOpacity>
      </View>
    );
  }
}

const styles = StyleSheet.create({
});