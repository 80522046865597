class Trie {

  /* A trie node stores a set of suffixes. It has three fields:
   *   count: the number of suffixes
   *   terminal: whether or not the empty suffix is included. terminal
   *     nodes are used to indicate where a word ends.
   *   children: mapping of char to child node
   */
  constructor() {
    this.count = 0;
    this.terminal = false;
    this.children = {};
  }

  /* attempt to insert the suffix word[i..]
   * returns true if successful; false if the suffix was already present */
  insertSuffix(word, i) {
    if (i == word.length) {
      if (this.terminal)
        return false;
      this.count++;
      this.terminal = true;
      return true;
    }

    let c = word.charAt(i);
    if (!this.children.hasOwnProperty(c)) {
      this.children[c] = new Trie();
    }

    if (this.children[c].insertSuffix(word, i+1)) {
      this.count++;
      return true;
    }
    return false;
  }

  insertWord(word) {
    return this.insertSuffix(word, 0);
  }

  containsSuffix(word, i) {
    if (i == word.length)
      return this.terminal;
    let c = word.charAt(i);
    if (!this.children.hasOwnProperty(c))
      return false;
    return this.children[c].containsSuffix(word, i+1);
  }

  containsWord(word) {
    return this.containsSuffix(word, 0);
  }

  isTerminal() {
    return this.terminal;
  }

  size() {
    return this.count;
  }

  /* get the set of suffixes of this node which start with character c */
  suffixesStartingWith(c) {
    if (this.children.hasOwnProperty(c))
      return this.children[c];
    return new Trie();
  }

  /* get a list of all words in this trie */
  pushContentsWithPrefix(prefix, output) {
    if (this.terminal) {
      output.push(prefix);
    }
    for (var c in this.children) {
      /* internet says I need this. why? */
      if (!this.children.hasOwnProperty(c)) continue;
      this.children[c].pushContentsWithPrefix(prefix + c, output);
    }
  }

  contents() {
    let result = [];
    this.pushContentsWithPrefix("", result);
    return result;
  }

  forEach(func) {
    let c = this.contents();
    c.forEach(func);
  }

}

export default Trie;
