import React, { Component } from 'react';
import Svg, { Ellipse } from 'react-native-svg';

const BeePlatform = (props) => {
  const { size, color } = props;

  let finalWidth = size || 120;
  let finalHeight = size * 48 / 120 || 48;
  let finalColor = color || '#232323';

  return (
    <Svg width={finalWidth} height={finalHeight} viewBox="0 0 120 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <Ellipse cx="60" cy="24" rx="60" ry="24" fill={finalColor}/>
    </Svg>
  );
}

export default BeePlatform;