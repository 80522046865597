export default {
	// 1st Row
	"-5,0,5": "5,-5,0",
	"-5,1,4": "4,-5,1",
	"-5,2,3": "3,-5,2",
	"-5,3,2": "2,-5,3",
	"-5,4,1": "1,-5,4",
	"-5,5,0": "0,-5,5",

	// 2nd Row
	"-4,-1,5": "5,-4,-1",
	"-4,0,4": "4,-4,0",
	"-4,1,3": "3,-4,1",
	"-4,2,2": "2,-4,2",
	"-4,3,1": "1,-4,3",
	"-4,4,0": "0,-4,4",
	"-4,5,-1": "-1,-4,5",

	// 3rd Row
	"-3,-2,5": "5,-3,-2",
	"-3,-1,4": "4,-3,-1",
	"-3,0,3": "3,-3,0",
	"-3,1,2": "2,-3,1",
	"-3,2,1": "1,-3,2",
	"-3,3,0": "0,-3,3",
	"-3,4,-1": "-1,-3,4",
	"-3,5,-2": "-2,-3,5",

	// 4th Row
	"-2,-2,4": "4,-2,-2",
	"-2,-1,3": "3,-2,-1",
	"-2,0,2": "2,-2,0",
	"-2,1,1": "1,-2,1",
	"-2,2,0": "0,-2,2",
	"-2,3,-1": "-1,-2,3",
	"-2,4,-2": "-2,-2,4",

	// 5th Row
	"-1,-3,4": "4,-1,-3",
	"-1,-2,3": "3,-1,-2",
	"-1,-1,2": "2,-1,-1",
	"-1,0,1": "1,-1,0",
	"-1,1,0": "0,-1,1",
	"-1,2,-1": "-1,-1,2",
	"-1,3,-2": "-2,-1,3",
	"-1,4,-3": "-3,-1,4",

	// 6th Row
	"0,3,-3": "-3,0,3",
	"0,2,-2": "-2,0,2",
	"0,1,-1": "-1,0,1",
	// "0,0,0": "0,0,0",
	"0,-1,1": "1,0,-1",
	"0,-2,2": "2,0,-2",
	"0,-3,3": "3,0,-3",

	// 7th Row
	"1,-4,3": "3,1,-4",
	"1,-3,2": "2,1,-3",
	"1,-2,1": "1,1,-2",
	"1,-1,0": "0,1,-1",
	"1,0,-1": "-1,1,0",
	"1,1,-2": "-2,1,1",
	"1,2,-3": "-3,1,2",
	"1,3,-4": "-4,1,3",

	// 8th Row
	"2,-4,2": "2,2,-4",
	"2,-3,1": "1,2,-3",
	"2,-2,0": "0,2,-2",
	"2,-1,-1": "-1,2,-1",
	"2,0,-2": "-2,2,0",
	"2,1,-3": "-3,2,1",
	"2,2,-4": "-4,2,2",

	// 9th Row
	"3,-5,2": "2,3,-5",
	"3,-4,1": "1,3,-4",
	"3,-3,0": "0,3,-3",
	"3,-2,-1": "-1,3,-2",
	"3,-1,-2": "-2,3,-1",
	"3,0,-3": "-3,3,0",
	"3,1,-4": "-4,3,1",
	"3,2,-5": "-5,3,2",

	// 10th Row
	"4,-5,1": "1,4,-5",
	"4,-4,0": "0,4,-4",
	"4,-3,-1": "-1,4,-3",
	"4,-2,-2": "-2,4,-2",
	"4,-1,-3": "-3,4,-1",
	"4,0,-4": "-4,4,0",
	"4,1,-5": "-5,4,1",


	// 11th Row
	"5,-5,0": "0,5,-5",
	"5,-4,-1": "-1,5,-4",
	"5,-3,-2": "-2,5,-3",
	"5,-2,-3": "-3,5,-2",
	"5,-1,-4": "-4,5,-1",
	"5,0,-5": "-5,5,0",
}