import Animated, {
  useSharedValue,
  useAnimatedStyle,
  withTiming,
  Easing
} from 'react-native-reanimated';
import { Component, useState, useEffect } from 'react'
import { StyleSheet, View, FlatList, Image, Pressable, Platform, Text, Linking } from 'react-native';
import Button from '../components/Button';
import Countdown from '../components/Countdown';
import BuyNowModal from '../components/BuyNowModal';
import Header from '../components/Header';
import PackBoard from '../components/PackBoard';
import ThemedText from '../components/ThemedText';
import Promotional from '../components/Promotional';
import Socials from '../components/Socials';
import { withSaveContext } from '../contexts/SaveContext';
import { withThemeContext } from '../contexts/ThemeContext';
import theme from '../styles/theme';
import utils from '../utils/utils.js';
import { playSound } from '../utils/soundUtils.js';
import Packs, { Tiers } from '../staticData/packData.js';
import Daily from '../staticData/dailyData.js';
import ProgressBar from '../components/ProgressBar';
import TierIcon from '../assets/icons/TierIcon';
import Analytics from '../analytics/analytics';

const Pack = (props) => {
  const [animatedIn, setAnimatedIn] = useState(false);
  const [timestamp, setTimestamp] = useState(0);
  const { packKey, packProgress, goToPack, goToDaily, isAnimated, color, dailyIndex, refreshDaily, themeStyle, isDarkMode, showBuyNow } = props;
  const scale = useSharedValue(isAnimated ? 0.3 : 1);
  const opacity = useSharedValue(isAnimated ? 0 : 1);

  useEffect(() => {
    scale.value = withTiming(1, { duration: 400, easing: Easing.bezier(0.65, 0, 0.35, 1) });
    opacity.value = withTiming(1, { duration: 400, easing: Easing.bezier(0.65, 0, 0.35, 1) });
  }, [])

  let levels = Packs[packKey] || [];
  if (packKey === 'daily') levels = Daily.getDailyLevel(dailyIndex);

  let numCompleted = utils.calcPackLevelsCompleted(packProgress);
  const isCompleted = numCompleted >= levels.length;

  let width = Math.min(theme.SCREEN_WIDTH, theme.smallScreenWidth);

  let containerStyle = {
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: width,
    alignSelf: !theme.isSmallScreen ? 'center' : null,
  };

  let packTitleStyle = {};
  let packTitleContainerStyle = [styles.packTitleContainer, { backgroundColor: color }]

  let completionEl = null;
  if (isCompleted) {
    completionEl = (
      <Image style={{ position: 'absolute', right: -50, marginLeft: 5, width: 45, height: 45}} source={require('../assets/images/honeycomb-bee.png')}/>
    );
  }

  let packTitle = utils.parsePackKey(packKey);

  // Center title for daily and larger screens
  if (packKey === 'daily' || !theme.isSmallScreen) {
    packTitleContainerStyle.push({
      flexDirection: 'row',
      alignItems: 'center',
      alignSelf: 'center',
      paddingVertical: 4,
      paddingLeft: 9,
      paddingRight: 6,
      borderRadius: 8,
    })
  }

  let countdownEl = null;
  // Center pack for daily, and add the countdown and update title
  if (packKey === 'daily') {
    packTitle += ` Challenge ${parseInt(dailyIndex) + 1}`;
    containerStyle.alignItems = 'center';
    containerStyle.alignSelf = 'center';
    containerStyle.paddingTop = 10;
    containerStyle.paddingBottom = 10;
    containerStyle.borderBottomWidth = 1;
    containerStyle.borderBottomColor = themeStyle.BORDER_COLOR;
    countdownEl =
      <Countdown
        completedChallenge={isCompleted}
        refreshDaily={refreshDaily}
      />;
  }

  let descriptionEl = null;
  if (packKey.indexOf('community') >= 0) {
    descriptionEl = <ThemedText style={styles.packDescriptionText}>fan-made puzzles</ThemedText>
  }

  const animatedStyle = useAnimatedStyle(() => {
    return {
      transform: [{ scale: scale.value }],
      opacity: opacity.value,
    };
  });

  if (utils.checkIfBelowLuminanceThreshold(color)) {
    packTitleStyle.color = 'white';
  }

  let buyAppBtnEl = null;
  if (packKey === 'starter' && theme.isSmallScreen) {
    buyAppBtnEl = (
      <View style= {{ marginTop: -30, alignSelf: 'center', marginBottom: 50 }}>
        <Button
          color={themeStyle.TEXT_COLOR}
          titleStyle={{ color: themeStyle.BG, fontSize: 18, fontFamily: 'Nunito-SemiBold' }}
          title="Buy the App"
          onPress={showBuyNow}
        />
      </View>
    )
  }

  return (
    <Animated.View style={[styles.packContainer, containerStyle, animatedStyle]}>
      { buyAppBtnEl }
      <View style={packTitleContainerStyle}>
        <Text style={[styles.packTitle, packTitleStyle]}>{ packTitle }</Text>
        { completionEl }
        { descriptionEl }
      </View>

      <PackBoard
        width={width}
        packKey={packKey}
        levels={levels}
        goToPack={goToPack}
        goToDaily={goToDaily}
        packProgress={packProgress}
        color={color}
      />
      { countdownEl }
    </Animated.View>
  )
}

class PacksScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dailyIndex: Daily.getDailyIndex(),
      isUnlocking: null,
      showBuyNowModal: false,
    };
  }

  refreshDaily() {
    this.props.navigation.replace('Packs');
  }

  goToPack(packID, gameID) {
    let obj = { packID };
    if (gameID) obj.gameID = gameID;
    this.props.navigation.replace('Pack', obj);
  }

  goToDaily(gameID) {
    let obj = { packID: 'Daily' };
    if (gameID) obj.gameID = gameID;


    if (Platform.OS === 'web' && gameID >= 3) {
      this.setState({ showBuyNowModal: true });
    } else {
      this.props.navigation.replace('Daily', obj);
    }
  }

  renderPack(item, totalCompleted) {
    const { isUnlocking } = this.state;
    const { isDarkMode, themeStyle } = this.props.theme;
    const { levelProgress, setTierProgress } = this.props.save;

    let key = item.id;
    let packProgress = utils.getPropertyByKeys(levelProgress, [key]) || {};

    let el = null;

    let color = theme.YELLOW_HONEY;
    if (item.tierID) color = Tiers[item.tierID].color;

    if (item.isTier) {
      let tierIconSize = theme.isSmallScreen ? null : 100;

      if (Platform.OS === 'web' &&
                  item.index >= 4) {
        el = (
          <Pressable
            style={styles.tierContainer}
            onPress={() => {
              this.setState({ showBuyNowModal: true });
            }
          }>
            <TierIcon color={color} threshold={item.threshold} size={tierIconSize}/>
          </Pressable>
        );

      } else if (item.isNextTier) {
        let isLocked = totalCompleted < item.threshold;
        el = (
          <Pressable
            style={styles.tierContainer}
            disabled={isLocked || isUnlocking === key}
            onPress={() => {
              playSound('unlock');
              this.setState({ isUnlocking: key }, () => {
                setTimeout(() => {
                  setTierProgress(key)
                }, 100)
              });
            }
          }>
            <TierIcon color={color} threshold={item.threshold} isPulsing={!isLocked} isUnlocking={isUnlocking === key} size={tierIconSize}/>
            <ProgressBar color={color} percent={totalCompleted / item.threshold * 100 } />
          </Pressable>
        );

      } else {
        el = (
          <View style={styles.tierContainer} >
            <TierIcon color={color} threshold={item.threshold} size={tierIconSize}/>
          </View>
        );
      }

    } else {
      el = <Pack
        key={`pack-${key}`}
        packKey={key}
        goToPack={this.goToPack.bind(this)}
        goToDaily={this.goToDaily.bind(this)}
        dailyIndex={this.state.dailyIndex}
        refreshDaily={this.refreshDaily.bind(this)}
        packProgress={packProgress}
        isDarkMode={isDarkMode}
        isAnimated={key !== 'daily'}
        color={color}
        themeStyle={themeStyle}
        showBuyNow={() => {
          this.setState({ showBuyNowModal: true });
          Analytics.logAppButtonPressed('buy-now-from-packs-top');
        }}
      />
    }

    return el;
  }

  render() {
    const { themeStyle, isDarkMode } = this.props.theme;
    const { levelProgress, tierProgress, setTierProgress } = this.props.save;
    const { showBuyNowModal } = this.state;

    let packData = [];

    // Calculate total progress
    let totalCompleted = utils.calcTotalLevelsCompleted(Packs, levelProgress);

    // Daily always at the top, regardless of completion
    if (Packs['daily']) {
      let levels = Packs['daily'];
      packData.push({
        id: 'daily',
        levels: levels
      });
    }

    let isNextTier = true; // For showing only 1 unlockable tier in list
    let tierIndex = 0;
    for (let key in Tiers) {
      let tier = Tiers[key];
      if (tierProgress[key]) {
        for (let i = 0; i < tier.packs.length; i++) {
          let packKey = tier.packs[i];
          let levels = Packs[packKey] || [];

          packData.push({
            id: packKey,
            levels: levels,
            tierID: key,
          });
        }
      } else {
        packData.push({
          id: key,
          isTier: true,
          isNextTier: isNextTier,
          index: tierIndex,
          levels: [],
          threshold: tier.threshold,
          tierID: key,
        })
        if (isNextTier) isNextTier = false;
      }
      tierIndex++;
    }

    let imageSource = require('../assets/images/make-your-own-handwritten.png');
    if (isDarkMode) imageSource = require('../assets/images/make-your-own-handwritten-white.png');

    let editorButtonEl = (
      <View style={{ margin: 20, alignSelf: 'center' }}>
        <Button
          style={{ width: 250 }}
          titleStyle={{ color: themeStyle.BG, fontSize: 18, fontFamily: 'Nunito-SemiBold' }}
          color={themeStyle.TEXT_COLOR}
          title="Open Puzzle Maker"
          onPress={() => {
            this.props.navigation.replace('Editor');
          }}
        />
        <Image style={{ width: 225 / 1.5, height: 130 / 1.5 }} source={imageSource}/>
      </View>
    );

    let iosBadgeSource = require('../assets/app-store-badge.png');
    if (isDarkMode) iosBadgeSource = require('../assets/app-store-badge-white.png')
    let storeButtonsEl = (
      <View style={[theme.row, theme.flexCenter, { marginTop: 20 }]}>
        <Button
          style={{ backgroundColor: 'transparent' }}
          onPress={() => {
            Linking.openURL("https://play.google.com/store/apps/details?id=com.spiralburst.honeycomb");
            Analytics.logLinkClicked('android-from-packs-screen');
          }}
        >
          <Image style={{ width: 133, height: 40, marginBottom: 5 }} source={require('../assets/google-play-badge.png')} />
        </Button>
        <Button
          style={{ backgroundColor: 'transparent' }}
          onPress={() => {
            Linking.openURL("https://apps.apple.com/us/app/honeycomb-word-puzzle/id6446267827");
            Analytics.logLinkClicked('ios-from-packs-screen');
          }}
        >
          <Image style={{ width: 120, height: 40, marginBottom: 5 }} source={iosBadgeSource} />
        </Button>
      </View>
    )

    let buyNowModal = null;
    if (showBuyNowModal) {
      buyNowModal = <BuyNowModal close={() => this.setState({ showBuyNowModal: false }) } />;
    }

    return (
      <View style={[styles.container, { backgroundColor: themeStyle.BG }]}>
        <Header navigate={this.props.navigation.replace} isPacks={true} />
        { buyNowModal }
        <FlatList
          contentContainerStyle={{ marginTop: 20, width: theme.SCREEN_WIDTH, paddingBottom: 80 }}
          data={packData}
          showsVerticalScrollIndicator={false}
          renderItem={({item}) => {
            return this.renderPack(item, totalCompleted);
          }}
          keyExtractor={(item, index) => `${item}-${index}`}
          initialNumToRender={5}
          ListFooterComponent={() => (
            <View>
              { editorButtonEl }
              { storeButtonsEl }
            </View>
          )}
        />
        <Promotional/>
        <Socials showBuyNow={() => {
          this.setState({ showBuyNowModal: true });
          Analytics.logAppButtonPressed('buy-now-from-packs');
        }} />
      </View>
    );
  }
}

export default withSaveContext(withThemeContext(PacksScreen))

let levelTextStyle = {
  fontFamily: 'Nunito-Regular',
  fontSize: 20,
  color: 'black',
}
if (Platform.OS === 'web') levelTextStyle.userSelect = 'none';

const styles = StyleSheet.create({
  container: {
    height: '100%',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  packContainer: {
    marginBottom: 50,
    alignItems: 'center',
    ...theme.row,
  },
  pack: {
    flexDirection: 'row',
    maxWidth: 600,
    flexWrap: 'wrap'
  },
  completionText: {
    fontFamily: 'Nunito-Regular',
    fontSize: 16,
    color: '#232323'
  },
  packDescriptionText: {
    position: 'absolute',
    left: 15 ,
    bottom: -20,
    fontFamily: 'Nunito-Regular',
    fontSize: 16,
    // color: '#232323',
  },
  levelText: levelTextStyle,
  packTitle: {
    fontFamily: 'Nunito-SemiBold',
    fontSize: 22,
    textTransform: 'capitalize',
    marginBottom: 1,
    marginTop: -1
  },
  tierContainer: {
    marginTop: 30,
    marginBottom: 70,
    ...theme.flexCenter,
  },
  packTitleContainer: {
    flexDirection: 'row',
    alignItems: 'flex-end',
    alignSelf: 'flex-start',
    paddingVertical: 4,
    paddingHorizontal: 8,
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,

    backgroundColor: 'black',
    marginBottom: 20,
  }
});