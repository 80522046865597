import { Audio, InterruptionModeAndroid, InterruptionModeIOS } from 'expo-av'
import { Platform } from 'react-native';
import utils from './utils.js';

let musicDict = {

}

export let soundsDict = {
  // Sound Effects
  note1: { audio: new Audio.Sound(), isLoaded: false },
  note2: { audio: new Audio.Sound(), isLoaded: false },
  note3: { audio: new Audio.Sound(), isLoaded: false },
  note4: { audio: new Audio.Sound(), isLoaded: false },
  note5: { audio: new Audio.Sound(), isLoaded: false },
  note6: { audio: new Audio.Sound(), isLoaded: false },
  note7: { audio: new Audio.Sound(), isLoaded: false },
  note8: { audio: new Audio.Sound(), isLoaded: false },
  note8alt: { audio: new Audio.Sound(), isLoaded: false },
  success: { audio: new Audio.Sound(), isLoaded: false },
  click: { audio: new Audio.Sound(), isLoaded: false },
  unlock: { audio: new Audio.Sound(), isLoaded: false },

  // Music
  menuMusic: { audio: new Audio.Sound(), isLoaded: false },
  gameMusic: { audio: new Audio.Sound(), isLoaded: false },
}

export let soundsDisabled = false;
export let musicDisabled = false;

export function initSounds() {
  // console.log("INIT SOUNDS")
  utils.getLocalItem('@honeycomb:soundsDisabled').then((bool) => {
    soundsDisabled = !!bool;
  });

  utils.getLocalItem('@honeycomb:musicDisabled').then((bool) => {
    musicDisabled = !!bool;
  });

  try {
    let soundOpts = {
      volume: .5,
      rate: 1
    }

    // Setup Audio - Sound Effects
    soundsDict['note1'].audio.loadAsync(require('../assets/sounds/note1.wav'), soundOpts)
      .then((item) => { soundsDict['note1'].isLoaded = true; });
    soundsDict['note2'].audio.loadAsync(require('../assets/sounds/note2.wav'), soundOpts)
      .then((item) => { soundsDict['note2'].isLoaded = true; });
    soundsDict['note3'].audio.loadAsync(require('../assets/sounds/note3.wav'), soundOpts)
      .then((item) => { soundsDict['note3'].isLoaded = true; });
    soundsDict['note4'].audio.loadAsync(require('../assets/sounds/note4.wav'), soundOpts)
      .then((item) => { soundsDict['note4'].isLoaded = true; });
    soundsDict['note5'].audio.loadAsync(require('../assets/sounds/note5.wav'), soundOpts)
      .then((item) => { soundsDict['note5'].isLoaded = true; });
    soundsDict['note6'].audio.loadAsync(require('../assets/sounds/note6.wav'), soundOpts)
      .then((item) => { soundsDict['note6'].isLoaded = true; });
    soundsDict['note7'].audio.loadAsync(require('../assets/sounds/note7.wav'), soundOpts)
      .then((item) => { soundsDict['note7'].isLoaded = true; });
    soundsDict['note8'].audio.loadAsync(require('../assets/sounds/note8.wav'), soundOpts)
      .then((item) => { soundsDict['note8'].isLoaded = true; });
    soundsDict['note8alt'].audio.loadAsync(require('../assets/sounds/note8-alt.wav'), soundOpts)
      .then((item) => { soundsDict['note8alt'].isLoaded = true; });
    soundsDict['success'].audio.loadAsync(require('../assets/sounds/success.mp3'), soundOpts)
      .then((item) => { soundsDict['success'].isLoaded = true; });
    soundsDict['click'].audio.loadAsync(require('../assets/sounds/click.mp3'), soundOpts)
      .then((item) => { soundsDict['click'].isLoaded = true; });
    soundsDict['unlock'].audio.loadAsync(require('../assets/sounds/unlock.wav'), soundOpts)
      .then((item) => { soundsDict['unlock'].isLoaded = true; });

    // Setup Audio - Music
    // soundsDict['menuMusic'].audio.loadAsync(require('../assets/music/main.mp3'), soundOpts)
    //   .then((item) => { soundsDict['menuMusic'].isLoaded = true; });
    // soundsDict['gameMusic'].audio.loadAsync(require('../assets/music/game.mp3'), soundOpts)
    //   .then((item) => { soundsDict['gameMusic'].isLoaded = true; });


    // Your sound is playing!
  } catch (error) {
    // An error occurred!
  }
}

async function _playSound(soundName, isLooping) {
  try {
    if (isLooping) {
      await soundsDict[soundName].audio.setIsLoopingAsync(true);
    }
    await soundsDict[soundName].audio.replayAsync();
  }
  catch(err) {
    console.log("Play Sound Error:", err);
  }
}

export async function playSound(soundName, isLooping) {
  // Check for music
  if (soundName === 'menuMusic' || soundName === 'gameMusic') {
    if (musicDisabled) return;
    _playSound(soundName, isLooping);
  }

  if (!soundsDisabled &&
      soundsDict.hasOwnProperty(soundName) &&
      soundsDict[soundName].isLoaded) {
    _playSound(soundName, isLooping);
  }
}

export function stopSound(soundName) {
  if (soundsDict.hasOwnProperty(soundName) &&
      soundsDict[soundName].isLoaded) {
    soundsDict[soundName].audio.stopAsync();
  }
}

export function toggleMusic() {
  //TODO finish this, needs more stuff like toggleSounds?
  musicDisabled = !musicDisabled;
  utils.setLocalItem('@honeycomb:musicDisabled', musicDisabled);
  // console.log("music is now", musicDisabled ? 'disabled' : 'on');
}

export function toggleSounds() {
  // console.log("TOGGLE SOUNDS?", soundsDisabled);
  let audioMode = {
    playsInSilentModeIOS: false,
    allowsRecordingIOS: false,
    staysActiveInBackground: false,
    shouldDuckAndroid: soundsDisabled,
    playThroughEarpieceAndroid: true
  }

  if (Platform.OS === 'ios') {
    audioMode.interruptionModeIOS = soundsDisabled ? InterruptionModeIOS.DoNotMix : InterruptionModeIOS.MixWithOthers;
  }
  if (Platform.OS === 'android') {
    audioMode.interruptionModeAndroid = InterruptionModeAndroid.DoNotMix;
  }
  Audio.setAudioModeAsync(audioMode);
  soundsDisabled = !soundsDisabled;
  utils.setLocalItem('@honeycomb:soundsDisabled', soundsDisabled);
  // console.log("sound is now", soundsDisabled ? 'disabled' : 'on');
  //final setting
}
