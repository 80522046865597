import { StyleSheet, Text, Linking, View, TextInput, Image, TouchableOpacity, ScrollView, Switch, Platform, Pressable } from 'react-native';
import { useEffect, useState } from 'react';
import theme from '../styles/theme';
import Analytics from '../analytics/analytics';
import Utils from '../utils/utils';
import Button from './Button';
import { withThemeContext } from '../contexts/ThemeContext';

export const Tag = (props) => {
  const { text, color } = props;
  const { themeStyle } = props.theme;

  let finalTagStyle = [styles.statusTag];
  let finalTagTextStyle = [styles.statusTagText];
  finalTagStyle.push({ backgroundColor: themeStyle.TEXT_COLOR });
  finalTagTextStyle.push({ color: themeStyle.CARD_BG });

  if (!theme.isSmallScreen) {
    finalTagStyle.push({
      height: 32,
      borderRadius: 8,
      paddingHorizontal: 12,
      marginHorizontal: 8,
    });
    finalTagTextStyle.push({
      fontSize: 16,
    });
  }

  return (
    <View style={finalTagStyle}>
      <Text style={finalTagTextStyle}>{ text }</Text>
    </View>
  );
}

export default withThemeContext(Tag);

const styles = StyleSheet.create({
  statusTag: {
    height: 24,
    borderRadius: 6,
    paddingHorizontal: 6,
    marginHorizontal: 6,
    ...theme.flexCenter
  },
  statusTagText: {
    fontSize: 14,
    fontFamily: 'Nunito-Regular'
  }
});