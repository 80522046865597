import { Linking, Platform } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import * as Clipboard from 'expo-clipboard';

export default class Utils {
  static versionNumber = '0.7.9';
  static _chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';

  static isTouchDevice() {
    return (('ontouchstart' in window) ||
           (navigator.maxTouchPoints > 0) ||
           (navigator.msMaxTouchPoints > 0));
  }

  static isSafari() {
    if (Platform.OS !== 'web') return false;
    let isSafari = navigator.vendor.match(/apple/i) &&
             !navigator.userAgent.match(/crios/i) &&
             !navigator.userAgent.match(/fxios/i) &&
             !navigator.userAgent.match(/Opera|OPT\//);

    return isSafari;
  }

  static toObject(instance) {
    let obj = {};
    for (let key in instance) {
      if (instance.hasOwnProperty(key)) {
        let value = instance[key];
        if (typeof value.toObject === 'function') {
          value = value.toObject();
        }
        obj[key] = value;
      }
    }
    return obj;
  }

  static btoa(input = '') {
    let str = input;
    let output = '';

    for (let block = 0, charCode, i = 0, map = Utils._chars;
    str.charAt(i | 0) || (map = '=', i % 1);
    output += map.charAt(63 & block >> 8 - i % 1 * 8)) {

      charCode = str.charCodeAt(i += 3/4);

      if (charCode > 0xFF) {
        throw new Error("'btoa' failed: The string to be encoded contains characters outside of the Latin1 range.");
      }

      block = block << 8 | charCode;
    }

    return output;
  }

  static atob(input = '') {
    let str = input.replace(/=+$/, '');
    let output = '';

    if (str.length % 4 == 1) {
      throw new Error("'atob' failed: The string to be decoded is not correctly encoded.");
    }
    for (let bc = 0, bs = 0, buffer, i = 0;
      buffer = str.charAt(i++);

      ~buffer && (bs = bc % 4 ? bs * 64 + buffer : buffer,
        bc++ % 4) ? output += String.fromCharCode(255 & bs >> (-2 * bc & 6)) : 0
    ) {
      buffer = Utils._chars.indexOf(buffer);
    }

    return output;
  }

  static randomIntFromInterval(min, max) { // min and max included
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  static validateEmail(email) {
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  // Check if current user.
  //   Also add backwards compatibility to guest accounts and tutorial
  static isSelf(test, uid) {
    return test === uid || test === 'USER_ID_PLACEHOLDER';
  }

  static timestampEqual(time1, time2) {
    return time1 && time2 && time1.seconds === time2.seconds;
  }

  static timestampToDaysLeft(timestamp) {
    let date = Utils.timestampToDate(timestamp);
    if (!date) {
      return null;
    }
    let diff = (5*24*60*60*1000 - (new Date() - Utils.timestampToDate(timestamp))) / (24*60*60*1000);
    if (diff >= 0) {
      return Math.round(diff);
    } else {
      // If negative return negative number regardless of rounding
      return Math.floor(diff);
    }
  }

  static timestampToHoursLeft(timestamp) {
    if (!timestamp) {
      return null;
    }
    return Math.round((5*24*60*60*1000 - (new Date() - Utils.timestampToDate(timestamp))) / (60*60*1000));
  }

  static getStreak(userData) {
    if (!userData) return null;
    let lastStreakUpdated = Utils.timestampToDate(Utils.getPropertyByKeys(userData, ['lastStreakUpdated'])) || 0;
    let daysAgo = Math.floor((new Date() - lastStreakUpdated) / (24*60*60*1000));
    let streak = Utils.getPropertyByKeys(userData, ['streak']) || 0;
    // Show streak as broken if broken but don't update until spin wheel
    if (daysAgo > 1) streak = 0;
    return streak;
  }

  static partTotalToPercent(part, total) {
    if (!part || !total) {
      return 0;
    }
    return Math.round((part / total) * 1000) / 10;
  }

  static monogram(name) {
    return name.charAt(0).toUpperCase();
  }

  static hexToRgbA(hex) {
    // Handle fill none
    if (hex === 'none') {
      return 'rgba(0,0,0,0)';
    }

    var c;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)){
      c= hex.substring(1).split('');
      if (c.length== 3){
        c= [c[0], c[0], c[1], c[1], c[2], c[2]];
      }
      c= '0x'+c.join('');
      return 'rgba('+[(c>>16)&255, (c>>8)&255, c&255].join(',')+',1)';
    } else {
      return 'rgba(0,0,0,.2)';
    }
    // throw new Error('Bad Hex');
  }

  static compareDisplayName(a, b) {
    let nameA = (a.displayName || a.email || '').toLowerCase();
    let nameB = (b.displayName || b.email || '').toLowerCase();
    if (nameA < nameB) { return -1; }
    if (nameA > nameB) { return 1; }
    return 0;
  }

  static compareGameKeyLastUpdated(userData, AIGameMetaData) {
    return (key1, key2) => {
      let d1 = key1 === 'AI_GAME' ?
                 Utils.getPropertyByKeys(AIGameMetaData, ['lastUpdated']) :
                 Utils.getPropertyByKeys(userData, ['gameKeys', key1, 'lastUpdated']);
      let d2 = key2 === 'AI_GAME' ?
                 Utils.getPropertyByKeys(AIGameMetaData, ['lastUpdated']) :
                 Utils.getPropertyByKeys(userData, ['gameKeys', key2, 'lastUpdated']);
      if (d1 && d2) {
        d1 = Utils.timestampToDate(d1);
        d2 = Utils.timestampToDate(d2);

        if (d1 > d2) {
          return -1;
        }
        return 1;
      }
      return 0;
    }
  }

  static getPropertyByKeys(object, keys) {
    if (object === null) {
      return null;
    }

    for (var i = 0; i < keys.length; i++) {
      let key = keys[i];
      if (object && object.hasOwnProperty(key)) {
        object = object[key];
      } else {
        return null;
      }
    }
    return object;
  }

  static async setLocalItem(key, item) {
    try {
      //we want to wait for the Promise returned by AsyncStorage.setItem()
      //to be resolved to the actual value before returning the value
      let jsonOfItem = await AsyncStorage.setItem(key, JSON.stringify(item));
      return jsonOfItem;
    } catch (error) {
      console.log(error.message);
    }
  }

  static async getLocalItem(key) {
    try {
      const retrievedItem =  await AsyncStorage.getItem(key);
      const item = JSON.parse(retrievedItem);
      return item;
    } catch (error) {
      console.log(error.message);
    }
    return
  }

  static async deleteLocalItem(key) {
    try {
      const error =  await AsyncStorage.removeItem(key);
      return error;
    } catch (error) {
      console.log(error.message);
    }
    return
  }

  static async clearLocalStorage() {
    AsyncStorage
      .getAllKeys()
      .then(keys => {
        // console.log("GET ALL KEYS", keys);
        if (keys && keys.length) {
          AsyncStorage.multiRemove(keys);
        }
      })
  }

  static async setClipboardString(string) {
    if (Platform.OS === 'ios') await Clipboard.setUrlAsync(string);
    else await Clipboard.setStringAsync(string);
  }

  static generateMailToString() {
    let mailToString = 'mailto:support@spiralburst.com?subject=Honeycomb Feedback';

    return mailToString;
  }

  //Returns board formatted as string delimited by ,
  //Format is tileIndex_Letter_Owner_isPerm_Selected
  //ex. -4,0,3_J1TF
  static boardObjToSimpleString(board, playerOne, playerTwo) {
    let simpleString = '';
    for (tile in board) {
      simpleString += tile + '_';
      simpleString += board[tile].letter;
      if (board[tile].tileOwner === playerOne) {
        simpleString += '1';
      }
      else if (board[tile].tileOwner === playerTwo) {
        simpleString += '2';
      } else {
        simpleString += '0';
      }
      if (board[tile].isPerm) {
        simpleString += 'T';
      } else {
        simpleString += 'F';
      }
      if (board[tile].isSelected) {
        simpleString += 'T';
      } else {
        simpleString += 'F';
      }
      simpleString += '/';
    }
    simpleString += playerOne + '/' + playerTwo;
    return simpleString;
  }

  static simpleStringToBoardObj(boardString) {
    let boardArray = boardString.split('/');
    let playerTwo = boardArray.pop();
    let playerOne = boardArray.pop();
    let board = {};
    for (let i = 0; i < boardArray.length; i++) {
      //split tile position from tile info
      let simpleArray = boardArray[i].split('_');

      let tileInfo = simpleArray[1];

      let tileOwner = 'neutral';
      if (tileInfo[1] === '1') {
        tileOwner = playerOne;
      } else if (tileInfo[1] === '2') {
        tileOwner = playerTwo;
      }

      let isPerm = tileInfo[2] === 'T' ? true : false;
      let isSelected = tileInfo[3] === 'T' ? true : false;

      //take tile position and reconvert to coords
      let tilePosition = simpleArray[0];
      let coords = tilePosition.split(',');

      let q = ParseInt(coords[0]);
      let r = ParseInt(coords[1]);
      let s = ParseInt(coords[2]);

      let boardTile = {
        'q': q,
        'r': r,
        's': s,
        'letter': tileInfo[0],
        'isPerm': isPerm,
        'isSelected': isSelected,
        'tileOwner': tileOwner
      }

      board[tilePosition] = boardTile;
    }
    return board;
  }

  // Daily Leaderboard utils
  static leaderboardLength = 10;
  static calcNewDailyLeaderboard(leaderboard, userID, displayName, newScore) {
    // Calculate score leaderboard
    let dailyLeaderboard = leaderboard || [];
    let newLeaderboard = dailyLeaderboard.slice(0);
    let leaderboardEntry = {
      uid: userID,
      displayName: displayName,
      score: newScore
    }

    // Check for duplicates
    let shouldUpdate = true;
    for (let i = 0; i < dailyLeaderboard.length; i++) {
      let uid = dailyLeaderboard[i].uid || null;
      let score = dailyLeaderboard[i].score || 0;
      if (Utils.isSelf(uid, userID)) {
        if (newScore > score) {
          newLeaderboard.splice(i, 1);
        } else {
          shouldUpdate = false;
        }
        break;
      }
    }

    if (shouldUpdate) {
      newLeaderboard.push(leaderboardEntry);
      newLeaderboard.sort((a,b) => {
        return b.score - a.score;
      });
      if (newLeaderboard.length > Utils.leaderboardLength) {
        newLeaderboard = newLeaderboard.slice(0, Utils.leaderboardLength);
      }
    }
    return newLeaderboard;
  }

  static parsePackKey (packKey) {
    let parsedKey = packKey;

    if (packKey.indexOf('_') >= 0) {
      let words = packKey.split('_');
      parsedKey = words[0];
      for (let i = 1; i < words.length; i++) {
        parsedKey += ' ' + words[i];
      }
    }
    return parsedKey;
  }

  static calcNewWordLeaderboard(wordLeaderboard, userID, displayName, myLongestWord) {
    // Calculate longest word leaderboard
    let dailyWordLeaderboard = wordLeaderboard || [];
    let newWordLeaderboard = dailyWordLeaderboard.slice(0);
    let wordLeaderboardEntry = {
      uid: userID,
      displayName: displayName,
      word: myLongestWord
    }

    // Check for duplicates
    let shouldUpdate = true;
    for (let i = 0; i < dailyWordLeaderboard.length; i++) {
      let uid = dailyWordLeaderboard[i].uid || null;
      let word = dailyWordLeaderboard[i].word;
      let wordLength = word ? word.length : 0;
      if (Utils.isSelf(uid, userID)) {
        if (myLongestWord.length > wordLength) {
          newWordLeaderboard.splice(i, 1);
        } else {
          shouldUpdate = false;
        }
        break;
      }
    }

    if (shouldUpdate &&
        myLongestWord.length >= 7) {
      newWordLeaderboard.push(wordLeaderboardEntry);
      newWordLeaderboard.sort((a,b) => {
        return b.word.length - a.word.length;
      });
      if (newWordLeaderboard.length > Utils.leaderboardLength) {
        newWordLeaderboard = newWordLeaderboard.slice(0, Utils.leaderboardLength);
      }
    }
    return newWordLeaderboard;
  }

  static calcPackLevelsCompleted(packProgress) {
    let numCompleted = Object.keys(packProgress).reduce((acc, val) => {
      if (val) return acc + 1;
      else return acc;
    }, 0)
    return numCompleted;
  }

  static calcTotalLevelsCompleted(packs, levelProgress) {
    let totalCompleted = 0;
    for (let key in packs) {
      if (key !== 'daily') {
        let packProgress = Utils.getPropertyByKeys(levelProgress, [key]) || 0;
        let numCompleted = Utils.calcPackLevelsCompleted(packProgress);
        totalCompleted += numCompleted;
      }
    }
    return totalCompleted;
  }

  static rgbToHex(r, g, b) {
    const componentToHex = (c) => {
      var hex = c.toString(16);
      return hex.length == 1 ? "0" + hex : hex;
    }

    return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
  }


  static hexToRgbObj(hex) {
    let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16)
    } : null;
  }

  // https://stackoverflow.com/questions/596216/formula-to-determine-perceived-brightness-of-rgb-color
  static calculateLuminance(hexColor) {
    let rgbObj = Utils.hexToRgbObj(hexColor);

    // Linearize scaled RGB values
    let sRGBtoLin = (colorChannel) => {
      // Send this function a decimal sRGB gamma encoded color value
      // between 0.0 and 1.0, and it returns a linearized value.

      // Piecewise function accounts for higher perceived luminance of a lighter color
      if ( colorChannel <= 0.04045 ) {
        return colorChannel / 12.92;
      } else {
        return (( colorChannel + 0.055)/1.055) ** 2.4;
      }
    }

    // Calculate luminance by scaling each component to a 0 to 1 range. (component / 255)

    // Differing coefficients account for varying perceived brightnesses of each color
    return (0.2126 * sRGBtoLin(rgbObj.r / 255) + 0.7152 * sRGBtoLin(rgbObj.g / 255) + 0.0722 * sRGBtoLin(rgbObj.b / 255))
  }

  static compareHexColorLuminance(hex1, hex2) {
    return (Utils.calculateLuminance(hex1) - Utils.calculateLuminance(hex2))
  }

  // Pass in hex color
  static checkIfBelowLuminanceThreshold(hexColor) {
    const threshold = '#777777';

    return (Utils.compareHexColorLuminance(hexColor, threshold) < 0);
  }
}