import React, { Component } from 'react';
import Svg, { Path } from 'react-native-svg';

export default class Edit extends Component {
  render() {
    return (
      <Svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <Path d="M2.74001 13.1062L11.8821 3.96413L16.1483 8.23042L7.0063 17.3725L2.13314 17.9793L2.74001 13.1062Z" fill="black"/>
        <Path d="M12.8013 3.04956L14.4344 1.41643C15.2155 0.635377 16.4818 0.635377 17.2628 1.41643L18.7007 2.85429C19.4817 3.63534 19.4817 4.90167 18.7007 5.68271L17.0676 7.31585L12.8013 3.04956Z" fill="black"/>
      </Svg>
    );
  }
}