import { init, track, identify, Identify } from '@amplitude/analytics-react-native';

export default class Analytics {

  //// Analytics Backend ////

  static initializeAnalytics() {
    init('41e90c4e917ac47c4ed183fc948e2bb4', undefined, {
      trackingOptions: {
        adid: false,
        // carrier: true,
        // deviceManufacturer: true,
        // deviceModel: true,
        ipAddress: false,
        // language: true,
        // osName: true,
        // osVersion: true,
        // platform: true,
      },
    });
  }

  //earliest event, possibly inefficient for long-term
  static logAnalyticsInitialized() {
    track('Amplitude Initialized');
  }


  //// User Properties ////
  // User properties now tracked via identify object instead of setUserProperty function

  static setUserTierProgress(tierID) {
    const identifyObj = new Identify();
    identifyObj.set('furthestTier', tierID);

    identify(identifyObj);
  }

  //// Gameplay Analytics ////

  static logLevelCompleted(packID, customGameID, level) {
    // Standard level tracking
    let packString = packID ? packID : 'none';
    let levelString = level ? level : 'none';
    let combinedString = (packID && level) ? packString + '-' + levelString : 'none';

    // Custom level tracking
    let customString = customGameID ? customGameID : 'none';

    track('levelCompleted', {
      packID: packString,
      level: levelString,
      combinedString: combinedString,
      customGameID: customString
    });
  }


  //// Social + Promotional Analytics ////

  static logCustomPuzzleShared(numWords, code, url) {
    track('puzzleShared', {
      numWords: numWords,
      puzzleCode: code,
      puzzleURL: url,
    });
  }

  // For external links only, e.g. GGS, Spiralburst, Hexicon, etc.
  static logLinkClicked(destination) {
    track('externalLinkClicked', {
      destination: destination
    });
  }

  //// Navigation ////

  static logAppButtonPressed(destination) {
    track('appButtonPressed', {
      destination: destination
    });
  }
}