import Hex from './models/Hex';
import Point from './models/Point';
import Utils from '../utils/utils';
import theme from '../styles/theme';

class HexUtils {

  static DIRECTIONS = [
    new Hex(1, 0, -1), new Hex(1, -1, 0), new Hex(0, -1, 1),
    new Hex(-1, 0, 1), new Hex(-1, 1, 0), new Hex(0, 1, -1)
  ];

  static equals(a, b) {
    return a.q == b.q && a.r == b.r && a.s == b.s;
  }

  static add(a, b) {
    return new Hex(a.q + b.q, a.r + b.r, a.s + b.s);
  }

  static subtract(a, b) {
    return new Hex(a.q - b.q, a.r - b.r, a.s - b.s);
  }

  static multiply(a, k) {
    return new Hex(a.q * k, a.r * k, a.s * k);
  }

  static lengths(hex) {
    return parseInt((Math.abs(hex.q) + Math.abs(hex.r) + Math.abs(hex.s)) / 2);
  }

  static distance(a, b) {
    return HexUtils.lengths(HexUtils.subtract(a, b));
  }

  static direction(direction) {
    return HexUtils.DIRECTIONS[(6 + (direction % 6)) % 6];
  }

  static neighbour(hex, direction) {
    return HexUtils.add(hex, HexUtils.direction(direction));
  }

  static neighbours(hex) {
    const array = [];
    for (let i = 0; i < HexUtils.DIRECTIONS.length; i += 1) {
      array.push(HexUtils.neighbour(hex, i));
    }

    return array;
  }

  static ring(radius) {
    var results = []
    var hex = HexUtils.multiply(HexUtils.direction(4), radius);
    for (var i = 0; i < 6; i++) {
      for (var j = 0; j < radius; j++) {
        results.push(hex);
        hex = HexUtils.neighbour(hex, i);
      }
    }
    return results
  }

  static spiral(radius) {
    var results = [new Hex(0,0,0)];
    for (var k = 1; k <= radius; k++) {
      results = results.concat(HexUtils.ring(k));
    }
    return results
  }

  static round(hex) {
    let rq = Math.round(hex.q)
    let rr = Math.round(hex.r)
    let rs = Math.round(hex.s)

    const qDiff = Math.abs(rq - hex.q)
    const rDiff = Math.abs(rr - hex.r)
    const sDiff = Math.abs(rs - hex.s)

    if (qDiff > rDiff && qDiff > sDiff)
        rq = -rr-rs
    else if (rDiff > sDiff)
        rr = -rq-rs
    else
        rs = -rq-rr

    return new Hex(rq, rr, rs);
  }

  static hexToPixel(hex, layout) {
    const s = layout.spacing;
    const M = layout.orientation;
    let x = (M.f0 * hex.q + M.f1 * hex.r) * layout.size.x;
    let y = (M.f2 * hex.q + M.f3 * hex.r) * layout.size.y;
    // Apply spacing
    x = x * s;
    y = y * s;
    return new Point(x + layout.origin.x, y + layout.origin.y);
  }

  static pixelToHex(point, layout) {
    const M = layout.orientation;
    const pt = new Point((point.x - layout.origin.x) / layout.size.x, (point.y - layout.origin.y) / layout.size.y);
    const q = M.b0 * pt.x + M.b1 * pt.y;
    const r = M.b2 * pt.x + M.b3 * pt.y;
    const hex = new Hex(q, r, -q - r);
    return HexUtils.round(hex);
  }

  static pixelToPointyHex(point, layout, width) {
    let finalWidth = width || theme.smallScreenWidth;
    let size = layout.size.x;
    let sizeRatio = 4.3 * (finalWidth / 392.72727272727275); // Based on the width of Alan's phone
    size *= sizeRatio; // Magic number scaling
    const q = (Math.sqrt(3)/3 * point.x  -  1./3 * point.y) / size
    const r = (2./3 * point.y) / size
    const hex = new Hex(q, r, -q - r);
    return HexUtils.round(hex);
  }

  static lerp(a, b, t) {
    return a + ((b - a) * t);
  }

  static hexLerp(a, b, t) {
    return new Hex(HexUtils.lerp(a.q, b.q, t), HexUtils.lerp(a.r, b.r, t), HexUtils.lerp(a.s, b.s, t));
  }

  // Check if the line between hexStart and hexEnd intercepts with hexIntercept
  static hexIntercepts(hexStart, hexEnd, hexIntercept) {
    let n = HexUtils.distance(hexStart, hexEnd);
    for (let i = 0; i < n; i++) {
      let intercept = HexUtils.round(HexUtils.hexLerp(hexStart,hexEnd,1.0/n * i));
      if (HexUtils.equals(intercept, hexIntercept)) {
        return true;
      }
    }
    return false;
  }

  static getID(hex) {
    return `${hex.q},${hex.r},${hex.s}`;
  }

  // Returns the distance of the furthest hex from the center, in each direction
  static getPuzzleMinMaxHexes(board, tileKeys) {
    let xMin = 0;
    let xMax = 0;

    let yMin = 0;
    let yMax = 0;

    for (let i = 0; i < tileKeys.length; i++) {
      let currHex = board[tileKeys[i]];

      // We only care about tiles with letters
      if (Utils.getPropertyByKeys(currHex,['letter'])) {
        let q = currHex.q;
        let r = currHex.r;

        // vertical distance from center of hex, in hexes. negative to make it a standard x/y coordinate system
        let verticalDistance = -((q / 2) + r);

        xMin = Math.min(xMin, q);
        xMax = Math.max(xMax, q);

        yMin = Math.min(yMin, verticalDistance);
        yMax = Math.max(yMax, verticalDistance);
      }
    }
    return { xMin, xMax, yMin, yMax };
  }

  // Returns the width and height of a puzzle, in number of hexes
  static getPuzzleDimensionsInHexes(minMaxHexes) {
    let numHexesWide = minMaxHexes.xMax - minMaxHexes.xMin + 1;
    let numHexesTall = minMaxHexes.yMax - minMaxHexes.yMin + 1;
    return { numHexesWide, numHexesTall };
  }

  static getPuzzleUnitaryDistancesToCenter(minMaxHexes) {
    // Unitary distance to centerpoints of max hex
    let xOffset = minMaxHexes.xMax * 0.75;
    let yOffset = minMaxHexes.yMax * Math.sqrt(3) / 2;

    // Add unitary padding to find true distance to edge of puzzle
    xOffset += 0.5;
    yOffset += Math.sqrt(3) / 4;

    return { xOffset, yOffset };
  }

  // Returns the width and height of the puzzle given a hex of width = 1 measured from point to point, and height of sqrt(3)/2 measured from flat to flat.
  static getPuzzleUnitaryDimensions(numHexesWide, numHexesTall) {
    // Unitary width and height of puzzle to centerpoints, based on hex math
    let puzzleUnitWidth = (numHexesWide - 1) * 0.75;
    let puzzleUnitHeight = (numHexesTall - 1) * Math.sqrt(3) / 2;

    // Add unitary padding to find true bounds of puzzle
    puzzleUnitWidth += 1;
    puzzleUnitHeight += Math.sqrt(3) / 2;

    return { puzzleUnitWidth, puzzleUnitHeight };
  }

  // Returns the x, y pixel offsets that must be applied to a puzzle to visually center it
  static getCenterOffsets(board, tileKeys, gameAreaWidth) {
    let minMaxHexes = HexUtils.getPuzzleMinMaxHexes(board, tileKeys);

    let { numHexesWide, numHexesTall } = HexUtils.getPuzzleDimensionsInHexes(minMaxHexes);

    let { puzzleUnitWidth, puzzleUnitHeight } = HexUtils.getPuzzleUnitaryDimensions(numHexesWide, numHexesTall);

    // This is the pixel width of each hex. 0.08 was calculated by measuring the width of a hex of size 1 when the HexGrid width was 450. (36/450 = 0.08)
    let hexWidth = gameAreaWidth * 0.08;
    let hexHeight = hexWidth * Math.sqrt(3) / 2;

    let { xOffset, yOffset } = HexUtils.getPuzzleUnitaryDistancesToCenter(minMaxHexes);

    // Center the puzzle. x and y are the unitary distances to shift the puzzle.
    // TODO - This is slightly off by several pixels for both height and width
    let x = (puzzleUnitWidth / 2) - xOffset;
    let y = (puzzleUnitHeight / 2) - yOffset;

    let centerOffsetX = x * hexWidth;
    let centerOffsetY = -y * hexHeight;

    return { centerOffsetX, centerOffsetY };
  }

  // Convert index to hex (1 indexed)
  static getPackHexFromIndex(i) {
    let row = Math.floor(i / 5);
    let col = i % 5;
    let q = (row % 2 === 0) ? 0 + col - Math.floor(row / 2) : -1 + col - Math.floor(row / 2);
    let r = -5 + row;
    let s = 5 - col;

    return new Hex(q,r,s);
  }

}

export default HexUtils;
