import React, { Component } from 'react';
import Svg, { Path, G, Defs, Rect, ClipPath } from 'react-native-svg';

const BackIcon = (props) => {
  const { size, color } = props;

  let finalSize = size || 32;
  let finalColor = color || '#232323';

  return (
    <Svg width={finalSize} height={finalSize} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <Path d="M19.4 14L14 20M14 20L19.4 26M14 20H26" stroke={finalColor} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <Rect x="5" y="5" width="30" height="30" rx="15" stroke={finalColor} strokeWidth="2"/>
    </Svg>
  );
}

export default BackIcon;