import Trie from './Trie'

class DecompressTrie {

  static makeNode(str, i) {
    // console.log("makeNode\t\t" + " ".repeat(i) + str.substring(i));

    if (str[i] != "(")
      throw "DecompressTrie.makeNode: unexpected character";

    const [j, count, children] =
      DecompressTrie.makeChildren(str, i+1);

    let result = new Trie();
    result.children = children;
    result.count = count;

    if (str[j] == ")") {
      return [j+1, result];
    }

    if (str[j] == "|") {
      if (str[j+1] != ")") {
        throw "DecompressTrie.makeNode: missing close paren for terminal node";
      }
      result.count++;
      result.terminal = true;
      return [j+2, result];
    }

    throw "DecompressTrie.makeNode: unexpected final character";
  }

  static makeChildren(str, i) {
    // console.log("makeChildren\t\t" + " ".repeat(i) + str.substring(i));

    let count = 0;
    let children = {};

    while (true) {
      const [j, c, child] = DecompressTrie.makeChild(str, i);
      // console.log("makeChildren next\t" + " ".repeat(j) + str.substring(j));

      children[c] = child;
      count += child.size();

      if (str[j] !== ")" && str[j] !== "|") {
        // console.log("bad character: " + str[j]);
        throw "DecompressTree.makeChildren: unexpected final character";
      }

      if (str[j] == ")"
          || (str[j] == "|" && str[j+1] == ")") ) {
        return [j, count, children];
      }

      i = j+1;
    }
  }

  static makeChild(str, i) {
    // console.log("makeChild\t\t" + " ".repeat(i) + str.substring(i));

    let c = str[i];
    let j = i+1;

    if (str[j] == "|" || str[j] == ")") {
      let result = new Trie();
      result.terminal = true;
      result.count = 1;
      return [j, c, result];
    }

    if (str[j] == "(") {
      const [k, result] = DecompressTrie.makeNode(str, j);
      return [k, c, result];
    }

    let result = new Trie();
    const [k, d, child] = DecompressTrie.makeChild(str, j);
    result.children[d] = child;
    result.count = child.size();
    return [k, c, result];
  }

  static decompress(str) {
    const [n, t] = DecompressTrie.makeNode(str, 0);
    return t;
  }

}

export default DecompressTrie;
